<template>
  <Card
    class="issue-card"
    :class="type"
    v-bind="$attrs"
  >
    <slot />
  </Card>
</template>

<script>
import Card from "./Card.vue"

export default {
  name: "IssueCard",
  status: "ready",
  components: { Card },
  props: {
    type: {
      validator: type => ["error", "warning", "success", "info", "tidelift", "recommendations", "tasks"].includes(type),
      default: "error",
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.issue-card {
  @include themify {
    /* override themed style */
    border-left: none;
  }

  &::before {
    content: "";
    border-top-left-radius: $border-radius-default;
    border-bottom-left-radius: $border-radius-default;
    height: calc(100% + 2px);
    position: absolute;
    width: 6px;
    top: -1px;
    left: 0;
  }

  &.error::before {
    background-color: $color-negative-red;
  }

  &.warning::before {
    background-color: $color-yellow-500;
  }

  &.success::before {
    background-color: $color-positive-green;
  }

  &.info::before {
    background-color: $color-light-blue;
  }

  &.tidelift::before {
    background-color: $color-lifter-orange;
  }

  &.tasks::before {
    background-color: $color-blue-500;
  }

  &.recommendations::before {
    background-color: $color-tidelift-blue;
  }
}
</style>
