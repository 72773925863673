import { datadogRum } from "@datadog/browser-rum"
import config from "../config"

export function setup() {
  if (config.datadogToken && config.datadogAppId) {
    datadogRum.init({
      applicationId: config.datadogAppId,
      clientToken: config.datadogToken,
      site: "datadoghq.com",
      service: "frontend",
      env: import.meta.env.MODE,
      // @ts-expect-error GITVERSION comes from vite-plugin-git-revision
      version: GITVERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [config.appUrlBase],
    })

    datadogRum.startSessionReplayRecording()
  }
}
