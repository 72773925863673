<template>
  <div class="tl-sortable-table">
    <OTable
      sortIcon="arrow-up"
      iconPack="fas"
      :rowClass="rowClass"
      v-bind="$attrs"
      @sort="(field: string, order: string) => $emit('sort', field, order)"
      @page-change="(page: number) => $emit('page-change', page)"
      v-model:selected="selectedRow"
    >
      <slot />
    </OTable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
/**
 * This is a Tidelift-styled version of the Oruga `<o-table>` component.
 * Refer to the [`<o-table>` documentation](https://oruga-ui.com/components/table.html) for configuration information.
 */
export default defineComponent({
  name: "TlSortableTable",
  props: {
    selected: { type: Object, required: false },
  },
  emits: ["update:selected", "sort", "page-change"],
  methods: {
    rowClass(): string {
      return "tl-sortable-table-row"
    },
  },
  computed: {
    selectedRow: {
      get(): object | undefined {
        return this.selected
      },
      set(value: object | undefined) {
        this.$emit("update:selected", value)
      },
    },
  },
})
</script>

<style lang="scss">
/* unscoped styles to play nicely with the theme provider */
.tl-sortable-table {
  @include themify {
    table th,
    table td {
      border-width: 0 0 1px;
      color: themed("color-text-primary");
      vertical-align: middle;
    }

    table th {
      border-top: none;
      border-color: themed("color-table-head-border");
      font-weight: $font-weight-semi-bold;
    }

    table td {
      border-color: themed("color-table-body-border");
      padding: 0.75em;
    }

    .table {
      background-color: inherit;

      th > span {
        // position the sort direction icon
        position: relative;
      }
    }

    .table th {
      // this is using a "non-heading" font-size, but all current font-size tokens are heading sizes
      font-size: 0.875rem;
      height: 3.25rem;
      border-width: 1px 0;

      .sort-icon {
        color: themed("color-table-head-border");
        font-size: 1em;
        bottom: 48%;
      }
    }

    .table th.is-current-sort {
      border-top-color: themed("color-text-primary");
      border-bottom-color: themed("color-text-primary");
    }

    .tl-sortable-table-row {
      @include tablet {
        height: 5rem;
      }

      &.is-selected,
      &:hover {
        color: inherit;
        background-color: themed("color-background-secondary-highlight");
      }
    }

    .loading-overlay .loading-background {
      background-color: themed("color-background");
      opacity: 0.25;
    }
  }
}
</style>
