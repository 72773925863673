import type { LifterIncomeDetails, LifterIncomeDetailsByStatus, PackageIncomeEstimate } from "@/types/userIncomes"
import type { PaginatedResponse, PaginationParams } from "@/types/general"
import type { MaintainerImpactData } from "@/types/lifting"
import type { PackageFragment } from "@/types/package"
import type { LiftablePlatform } from "@/types/package"
import type { AxiosRequestConfig } from "axios"

import { RequiredData, RequiredParams, t, client } from "./utils"
import { CATALOG_STANDARD, LifterTaskSummary } from "@/types/task"

export function submitLifterViolationFeedback(
  violation_id: string,
  config: RequiredData<{
    is_helpful: boolean
    feedback: string
  }>
) {
  const url = t("lifter_catalog_tasks/feedback/{violation_id}", { violation_id })
  return client.post(url, config.data, config)
}

export function submitLifterViolationOverrideRequest(
  violation_id: string,
  config: RequiredData<{
    reason: string
    dependency_violation_id: string
    dependency_package_platform: string
    dependency_package_name: string
  }>
) {
  const url = t("lifter_catalog_tasks/request_exception/{violation_id}", { violation_id })
  return client.post(url, config.data, config)
}

export function getLifterTasks(
  config: RequiredParams<
    PaginationParams & {
      filter_income?: boolean
      filter_name_search?: string
      filter_name?: string
      filter_platform?: string
      filter_catalog_standards?: CATALOG_STANDARD[]
      filter_status?: "complete" | "incomplete" | ""
    }
  >
) {
  return client.get<
    PaginatedResponse<LifterTaskSummary> & {
      catalog_standards: CATALOG_STANDARD[]
    }
  >("lifter_catalog_tasks/lifter_tasks", config)
}

/* It will currently always return 200; or 400 if the input was bad. */
export function verifyPackagesHaveSecurityPolicies(
  urlParams: PackageFragment,
  config: RequiredData<{
    chosen_plan: "tidelift" | "other"
    email_address?: string | null
    security_policy_url: string | null
  }>
) {
  const url = t("lifting/{platform}/{name}/verify_packages_have_security_policies", urlParams)
  return client.post(url, config.data, config)
}

export function getIncomeDetails(config: RequiredParams<PaginationParams>) {
  return client.get<LifterIncomeDetails>("lifting/income_details/", config)
}

export function getIncomeDetailsByStatus() {
  return client.get<LifterIncomeDetailsByStatus>("lifting/income_details_by_status/")
}

/**
 * Retrieve a list of possible package manager usernames based on existing liftings.
 */
export function fetchLifterLikelyUsernameForPlatform(urlParams: { platform: string }) {
  const url = t("lifting/likely_username_for_platform/{platform}", urlParams)
  return client.get<string[]>(url)
}

export function fetchIncomeEstimatesForPackages(
  config: RequiredData<{ packages: { platform: LiftablePlatform; name: string }[] }>
) {
  return client.post<PackageIncomeEstimate[]>("estimate/bulk_estimates", config.data, config)
}

export function fetchNextPayday(config: AxiosRequestConfig | undefined = undefined) {
  return client.get<string>("estimate/payday", config)
}

/**
 * Retrieve maintainer impact data for subscribers.
 */

export function fetchMaintainerImpactData() {
  return client.get<MaintainerImpactData>("lifting/maintainer-impact-data/")
}
