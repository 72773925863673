<template>
  <div class="container pt-5">
    <section v-if="isDev">
      <Heading>You are in dev mode</Heading>
      <p>This route is served by the CMS. This page will not appear in production.</p>
      <hr />
      <template v-if="auth.user.value">
        <Heading
          :level="2"
          class="my-4"
          >Your user information</Heading
        >
        <pre>{{ JSON.stringify(auth.user.value, null, 2) }}</pre>
      </template>
      <template v-else>
        <div class="mb-3"><RouterLink :to="{ name: 'Login' }">Log In</RouterLink> to an existing account</div>
        <div>
          <RouterLink :to="{ name: 'SignUp' }">📈 Subscriber Sign Up</RouterLink> |
          <RouterLink :to="{ name: 'LifterSignUp', params: { platform: 'npm', name: 'vue', status: 'needs_lifters' } }"
            >💪 Lifter Sign Up</RouterLink
          >
        </div>
      </template>
    </section>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { useAuth } from "@/services/auth"
import { ref } from "vue"
const isDev = ref(import.meta.env.DEV)
const auth = useAuth()
</script>
