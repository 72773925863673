<template>
  <div
    v-if="showSkeleton"
    class="catalog-task"
  >
    <Skeleton
      type="h1"
      style="width: 50%; margin-bottom: 1rem"
    />
    <div class="task-wrapper">
      <div class="task-main">
        <Skeleton
          type="h2"
          style="width: 50%"
        />
        <Skeleton type="hr" />
        <section class="columns is-desktop task-content">
          <div class="column is-half-desktop">
            <div class="task-section">
              <Skeleton
                type="h3"
                style="width: 60%"
              />
              <Skeleton type="span" />
              <Skeleton type="span" />
            </div>
            <div class="task-section">
              <Skeleton
                type="h3"
                style="width: 60%"
              />
              <Skeleton type="span" />
              <Skeleton type="span" />
            </div>
          </div>
          <div class="column is-offset-1">
            <div class="task-section">
              <Skeleton
                type="h3"
                style="width: 60%"
              />
              <Skeleton type="span" />
              <Skeleton type="span" />
            </div>
          </div>
        </section>
      </div>
      <section
        class="task-actions content"
        style="height: 12rem"
      >
        <Skeleton
          type="h2"
          style="width: 50%"
        />
        <Skeleton
          type="span"
          style="width: 30%"
        />
        <Skeleton
          type="span"
          style="width: 30%"
        />
      </section>
    </div>
  </div>
  <div v-else>
    <div class="catalog-task">
      <!-- @slot Page-level header (usually Heading level 1, can be omitted) -->
      <slot name="pageHeader" />
      <div class="task-wrapper">
        <div class="task-main">
          <!-- @slot Content-level header (usually Heading level 2) -->
          <slot name="contentHeader" />
          <hr v-if="hasSlot('contentHeader')" />

          <section
            v-if="hasSlot('contentLeft') || hasSlot('contentRight')"
            class="columns is-desktop task-content"
          >
            <div class="column is-half-desktop">
              <!-- @slot Main task content left column (for a 2-column layout) -->
              <slot name="contentLeft" />
            </div>
            <div class="column is-offset-1">
              <!-- @slot Main task content right column (for a 2-column layout) -->
              <slot name="contentRight" />
            </div>
          </section>
          <section
            v-else
            class="task-content"
          >
            <!-- @slot Main task content area with no pre-set structure -->
            <slot name="content" />
          </section>
        </div>
        <section class="task-footer content">
          <!-- @slot Footer header (usually Heading level 2) -->
          <slot name="footerHeader" />
          <div
            v-if="hasSlot('footerLeft') || hasSlot('footerRight')"
            class="columns"
          >
            <div class="column is-half-desktop action">
              <!-- @slot Footer left column (for a 2-column layout) -->
              <slot name="footerLeft" />
            </div>
            <div class="column is-half-desktop action">
              <!-- @slot Footer right column (for a 2-column layout) -->
              <slot name="footerRight" />
            </div>
          </div>
          <div v-else>
            <!-- @slot Footer with no pre-set structure -->
            <slot name="footer" />
          </div>
        </section>
      </div>
    </div>
    <div v-if="hasSlot('modals')">
      <!-- @slot Where you can include modals if CatalogTask is going to be the root element -->
      <slot name="modals" />
    </div>
  </div>
</template>

<script>
/**
 * Basic template for a catalog task. Both the main content and footer areas can optionally use a 2-column layout (using the contentLeft/footerLeft and contentRight/footerRight slots), or you can build your own structure within the space (using the basic content/footer slot).
 */
export default {
  name: "CatalogTask",
  status: "ready",
  metaInfo: {
    htmlAttrs: {
      lang: "en",
    },
  },
  props: {
    /**
     * Whether to show skeleton loading components
     */
    showSkeleton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hasSlot(slot) {
      return !!this.$slots[slot]
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
$border-color: "color-border";
$background-color: "color-modal-footer";

.task-main,
.task-actions {
  padding: $space-large;
  border-width: 1px;
  border-style: solid;

  @include themify {
    border-color: themed("color-hr");
  }
}

.catalog-task :deep(h3) {
  margin-bottom: $space-x-small;

  @include themify {
    color: themed("color-heading-secondary");
  }
}

.task-actions {
  border-top-width: 0;
  border-radius: 0 0 $border-radius-default $border-radius-default;
  padding-bottom: 2.5rem;

  @include themify {
    background-color: themed("color-modal-footer");

    h2,
    h3 {
      color: themed("color-heading-secondary");
    }
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .column.is-half-desktop {
    $padding: 3.5rem;

    &:first-of-type {
      padding-right: $padding;
      @include themify {
        border-right: 1px solid themed("color-hr");
      }
    }

    &:last-of-type {
      padding-left: $padding;
    }
  }

  .action,
  .action > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .button {
      margin-top: $space-small;
      width: fit-content;
    }
  }
}

.task-main {
  border-radius: $border-radius-default $border-radius-default 0 0;
}

.task-section {
  margin-bottom: $space-large;
}

.task-footer {
  @include themify {
    background-color: themed($background-color);
    border: 1px solid themed($border-color);
  }

  border-top: 0;
  border-radius: 0 0 $border-radius-default $border-radius-default;
  padding: $space-large;

  .dropdown {
    margin-top: $space-small;
    width: 100%;

    :deep(.control) {
      min-width: 24rem;
      max-width: 100%;
    }

    :deep(.multi-select) {
      min-width: 24rem;
    }

    :deep(.options-wrapper) {
      min-width: 24rem;
    }
  }
}
</style>
