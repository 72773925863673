<template>
  <OIcon v-bind="$attrs" />
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "TlIcon",
})
</script>
