import { computed } from "vue"
import { useAuthStore } from "@/store/pinia/auth"
import { storeToRefs } from "pinia"

/**
 * Information about the curerntly logged in user.
 */
export function useUserIdentity() {
  const authStore = useAuthStore()
  const { user, auth0Profile, authenticated } = storeToRefs(authStore)

  const identityProviders = computed(() => {
    if (auth0Profile.value === null) return []
    return auth0Profile.value.identities.map(({ provider }) => provider)
  })

  const hasGitHubIdentityProvider = computed(() => {
    return identityProviders.value.includes("github")
  })

  const firstName = computed(() => user.value?.name?.split(" ")[0] || "")

  const profilePicture = computed(() => {
    if (authenticated.value) {
      return auth0Profile.value?.picture || user.value?.picture || null
    }
    return null
  })

  return {
    hasGitHubIdentityProvider,
    firstName,
    profilePicture,
    identityProviders,
  }
}
