import depci from "@/services/depci"
import { Scan } from "@/types/scan"
import { defineStore } from "pinia"

const NUM_CACHED_BUILDS = 10
const cachedScans = new Set<string>()

function cycleCachedScan(scanId: string) {
  // If we access a scan, we want to cycle it so
  // it doesn't get ejected prematurely
  if (!scanId) return

  cachedScans.delete(scanId)
  cachedScans.add(scanId)
}

interface State {
  scans: Record<string, Scan>
  scansLoading: Record<string, boolean>
}

export const useScansStore = defineStore("scans", {
  state: (): State => ({
    scans: {},
    scansLoading: {},
  }),
  getters: {
    isLoadingScan: (state: State) => (scanId: string) => state.scansLoading[scanId],
    getScan: (state: State) => (scanId: string) => {
      cycleCachedScan(scanId)
      return state.scans[scanId] || null
    },
  },
  actions: {
    async fetchScan({ scanId }: { scanId: string }) {
      if (this.scans[scanId] || this.scansLoading[scanId]) return

      this.scansLoading[scanId] = true

      const scan = await depci.fetchCatalogAlignment({ scanId })

      // Only keep up to ten scans in the store since they use a lot of memory.
      this.scans[scanId] = scan

      cachedScans.add(scan.id)
      if (cachedScans.size > NUM_CACHED_BUILDS) {
        const { value: scanToEject, done } = cachedScans.values().next()
        if (!done) {
          cachedScans.delete(scanToEject)
          delete this.scans[scanToEject]
          delete this.scansLoading[scanToEject]
        }
      }

      this.scansLoading[scanId] = false
    },
  },
})
