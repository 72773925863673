import apiClient from "@/services/api"
import { Repository } from "@/types/repository"
import { defineStore } from "pinia"
import { markRaw } from "vue"
import axios from "axios"

// TODO: This interface has redundant properties that aren't needed
// prefer to use the Repository interface from types/repository.ts
interface StateRepository extends Repository {
  /** @deprecated use "organization" instead */
  org: string
  /** @deprecated use "catalog.organization.type" instead */
  org_type: string
}

interface State {
  loadCount: number
  repos: StateRepository[]
  syncing: number
  isLoadedFor: string | null
  packageManagers: string[]
  groups: string[]
  orgProjectCatalogs: [string, string][]
  abortController: AbortController
}

interface FetchReposParams {
  org: string
  repoType: string
  page?: number
  perPage?: number
  sortBy?: string
  sortDir?: string
  filter?: Record<string, unknown>
}

export const useRepositoryStore = defineStore("repos", {
  state: (): State => ({
    loadCount: 0,
    repos: [],
    syncing: 0,
    isLoadedFor: null,
    packageManagers: [],
    groups: [],
    orgProjectCatalogs: [],
    // Unreactive abortController cancels previous requests
    // And makes sure that the data displayed is for the latest request only
    // if the user changes the page or filters before the previous request completes
    abortController: markRaw(new AbortController()),
  }),
  getters: {
    isLoadingRepos(state) {
      return state.loadCount > 0
    },
    isSyncingRepos(state) {
      return state.syncing > 0
    },
  },
  actions: {
    async fetchProjectFilters(params: { org: string; repoType: string }) {
      const filterOptionsResponse = apiClient.fetchProjectFilters({
        org: params.org,
        repoType: params.repoType,
      })
      const filterOptions = (await filterOptionsResponse).data

      this.orgProjectCatalogs = filterOptions.catalogs
      this.packageManagers = filterOptions.platforms
      this.groups = filterOptions.groups
    },
    async fetchRepos(params: FetchReposParams) {
      if (this.abortController) this.abortController.abort()
      // Every request requires a fresh abortController
      this.abortController = new AbortController()
      this.loadCount += 1
      try {
        const response = await apiClient.fetchRepositoriesPage(
          {
            name: params.org,
            type: params.repoType,
          },
          {
            params: {
              page: params.page,
              per_page: params.perPage,
              sort_by: params.sortBy,
              sort_dir: params.sortDir,
              filter: params.filter,
            },
            signal: this.abortController.signal,
          }
        )
        if (response.data !== null) {
          this.repos = response.data.results.map(repo => ({
            // TODO remove this.  Set redundant deprecated properties
            ...repo,
            org: repo.organization,
            org_type: repo.catalog.organization.type,
          }))
          this.isLoadedFor = `${params.repoType}/${params.org}`
        }
        return response
      } catch (error) {
        if (axios.isCancel(error)) {
          // Suppress errors thrown by manual cancelation
          return null
        }
        throw error
      } finally {
        this.loadCount -= 1
      }
    },
  },
})
