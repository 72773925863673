<template>
  <div class="tl-drawer">
    <OModal
      ref="drawerModal"
      :active="isActive"
      class="tl-drawer-body"
      :class="{ fullscreen, focused: finishedOpenAnimation && focused }"
      :width="fullscreen ? '100%' : '54rem'"
      :animation="fullscreen ? 'zoom-out' : 'slide-left'"
      :canCancel="['escape', 'outside']"
      :onCancel="closeDrawer"
      @close="closeDrawer"
    >
      <div class="drawer-card">
        <div class="drawer-card-head">
          <slot name="header" />
          <div class="drawer-actions">
            <slot name="drawer-actions" />

            <SvgIcon
              name="expand"
              size="24px"
              fill="currentColor"
              class="drawer-action"
              role="button"
              @click="fullscreen = !fullscreen"
            />
            <!--
              when upgrading Font Awesome,
              "times" will need to be updated to xmark

              https://fontawesome.com/docs/web/setup/upgrade/whats-changed#icons-renamed-in-version-6
            -->
            <span @click="closeDrawer">
              <TlIcon
                pack="fas"
                icon="times"
                class="drawer-action"
                role="button"
              />
            </span>
          </div>
        </div>
        <slot name="banner" />
        <div class="drawer-card-body">
          <slot name="content" />
        </div>
        <div
          class="drawer-card-foot"
          v-if="$slots.footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </OModal>
  </div>
</template>
<script>
import { defineComponent } from "vue"
import TlIcon from "./TlIcon.vue"
import SvgIcon from "./SvgIcon.vue"

export default defineComponent({
  name: "Drawer",

  components: {
    TlIcon,
    SvgIcon,
  },

  props: {
    /**
     * Sets the active class for the drawer.
     */
    isActive: { type: Boolean, default: true },
    /**
     * Function called when the drawer close event is fired.
     */
    closeDrawer: { type: Function, required: false },
    /**
     * A "Focused" Drawer is the primary content of the screen when
     * active, and should be completed before it is closed.
     *
     * When this is enabled, "esc" / "click-out" closing are disabled
     * and the top bar close button is hidden.
     *
     * This also applies a shaded background to the screen behind
     * the drawer to indicate it as the content to be interacted with.
     */
    focused: { type: Boolean, default: false },
  },

  data() {
    return {
      fullscreen: false,
      finishedOpenAnimation: false,
    }
  },

  watch: {
    /**
     * This is a hacky way to apply the "focused" modal background
     * *after* the slide in animation.
     *
     * I tried hooking into the .slide-left-x classes, but they do not
     * apply to child classes (i.e.: .slide-left-x { .modal-background { <does not apply> })
     *
     * OModal also does not expose a modal-background slot / animation prop,
     * so this cannot be controlled with a separate transition
     */
    isActive(active) {
      if (active) {
        // on drawer open, wait 400ms to apply transitions
        // 400ms is the length of the "slide open" animation, AKA .slide-left-enter-active
        setTimeout(() => (this.finishedOpenAnimation = true), 400)
      } else {
        // on drawer close, start transitions immediately
        this.finishedOpenAnimation = false
      }
    },
  },

  mounted() {
    // Override OModal's cancel method so closeDrawer can programmatically control cancelling
    this.$refs.drawerModal.cancel = function (method) {
      if (this.cancelOptions.indexOf(method) < 0) return

      // Allow the drawer consumer to provide it's own close method.
      if (this.onCancel) {
        this.onCancel()
      } else {
        this.close({ action: "cancel", method })
      }
    }
  },
})
</script>

<style lang="scss">
.tl-drawer {
  .tl-drawer-body {
    outline: none;
  }

  .modal {
    align-items: flex-end;
    justify-content: flex-end;

    .animation-content {
      margin: 0;
    }

    &.fullscreen {
      align-items: center;
      justify-content: center;
    }

    .modal-content {
      filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%)) drop-shadow(0 8px 22px rgb(75 81 104 / 10%));
    }
  }

  .modal-background {
    background-color: transparent;
    transition: background-color $duration-semi-quick ease-in;
  }

  .focused {
    .modal-background {
      @include themify {
        background-color: themed("color-modal-background");
      }
    }
  }

  .slide-left-enter-active {
    transition: transform 400ms ease-out;
  }

  .slide-left-leave-active {
    transition: transform $duration-slowly ease-in;

    /**
     * modal becomes display: none as it exits when using oruga.
     * ensure it stays visible while it slides out.
     */
    display: flex;
  }

  .slide-left-enter,
  .slide-left-enter-from,
  .slide-left-leave-to {
    transform: translate3d(100%, 0, 0);
  }

  .slide-left-enter-to,
  .slide-left-leave {
    transform: translate3d(0, 0, 0);
  }

  .drawer-card {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-default;
    max-width: 54rem;
    height: calc(100vh - 3rem);
    @include themify {
      background-color: themed("color-drawer-background");
    }
  }

  .fullscreen .drawer-card {
    width: calc(100% - 4rem);
    max-width: inherit;
    margin: 0 2rem;
  }

  .fullscreen .animation-content {
    /*
     * Oruga applies max-width as an inline style, so !important is necessary
     * to override it :(
     */
    max-width: 100% !important;
    width: 100%;
  }

  .drawer-card-head,
  .drawer-card-body {
    border-bottom: 0;
    border-top: 0;
    padding: 0 2rem;
    @include themify {
      background-color: themed("color-drawer-background");
    }
  }

  .drawer-card-head {
    height: 4rem;
    min-height: 4rem;
    border-radius: $border-radius-default $border-radius-default 0 0;
    display: flex;
    align-items: center;
    @include themify {
      border-bottom: 1px solid themed("color-hr");
    }
  }

  .drawer-card-body {
    overflow-y: scroll;
    flex-grow: 1;
    padding-top: $space-large;
    padding-bottom: $space-large;
  }

  .drawer-card-foot {
    height: 4.5rem;
    min-height: 4.5rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include themify {
      background-color: themed("color-drawer-background-secondary");
    }
  }

  .drawer-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
    font-size: 22px;
    @include themify {
      color: themed("color-heading-primary");
    }
  }

  .drawer-action {
    cursor: pointer;
    fill: currentcolor;
  }
}
</style>
