<template>
  <nav class="bottom-nav has-themed-text">
    <p class="step-indicator">Step {{ currentStep }} of {{ totalSteps }}</p>
    <div class="button-group">
      <slot />
    </div>
  </nav>
</template>

<script>
export default {
  name: "StepNav",
  status: "ready for a11y test",
  inheritAttrs: false,
  props: {
    currentStep: { type: Number, required: true },
    totalSteps: { type: Number, required: true },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.bottom-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-radius: 0;
  box-shadow: 0 -4px 8px rgb(75 81 104 / 10%), 0 -8px 22px rgb(75 81 104 / 10%);

  @include themify {
    background-color: themed("color-card-background-secondary");
  }
}
</style>
