import * as amplitude from "@amplitude/analytics-browser"
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser"
import config from "../config"
import { Directive } from "vue"
import { packageUniqueKey } from "@/store/pinia/packages"
import { useRoute, useRouter } from "vue-router"

/**
 * An enumeration of our custom events.
 * Use "Object Created" (title case, past tense)
 * Use an [object] [verb] format like "project created" and not "created project"
 * https://www.notion.so/Subscriber-Task-Behavior-e8251a993cc142678a1c6d29b6a7aef1
 *
 * KEEP THIS LIST ALPHABETIZED BY GROUP
 */
export const METRICS_EVENT = {
  /** Generic Events */
  LOGIN_STARTED: "Login Started",
  LOGIN_SUCCESSFUL: "Login Successful",
  LOGIN_FAILED: "Login Failed",
  LOGOUT_SUCCESSFUL: "Logout Successful",
  PAGE_VIEWED: "Page Viewed",
  /** Subscriber events */
  ALIGNMENT_COMPLETED: "Alignment Completed",
  API_KEY_GENERATED: "API Key Generated",
  API_KEY_REVOKED: "API Key Revoked",
  ATTESTATION_DATA_EXPORTED: "Attestation Data Exported",
  CATALOG_IMPORT_PROJECT_CREATED: "Catalog Import Project Created",
  CATALOG_STATUS_DRAWER_VIEWED: "Catalog Status Drawer Viewed",
  CATALOG_TASK_LIST_PAGE_FILTERED: "Catalog Task List Page Filtered", // TODO fix this is firing too often.
  FREE_TRIAL_SIGNUP: "Free Trial Signup",
  GROUP_CREATED: "Group Created",
  GROUP_REMOVED: "Group Removed",
  MANIFEST_FILE_UPLOADED: "Manifest File Uploaded",
  MOST_RECENT_REPORT_DOWNLOADED: "Most Recent Report Downloaded",
  NEW_CATALOG_CREATED: "New Catalog Created",
  NEW_REPORT_REQUESTED: "New Report Requested",
  PACKAGE_CONTRIBUTORS_CLICKED: "Package Contributors Clicked",
  PACKAGE_MAINTAINER_CLICKED: "Package Maintainer Clicked",
  PACKAGE_HOMEPAGE_CLICKED: "Package Homepage Clicked",
  PACKAGE_INCOME_STREAM_CLICKED: "Package Income Stream Clicked",
  PACKAGE_SECURITY_POLICY_CLICKED: "Package Security Policy Clicked",
  PACKAGE_SOURCE_URL_CLICKED: "Package Source URL Clicked",
  PROJECT_ADDED_TO_GROUP: "Project Added to Group",
  PROJECT_REMOVED_FROM_GROUP: "Project Removed from Group",
  PROJECT_DELETED: "Project Deleted",
  PROJECT_DEPENDENCY_CHAIN_CLICKED: "Project Dependency Chain Clicked",
  PROJECTS_CHART_VIEWED: "Projects Chart Viewed",
  RELEASE_REQUESTED: "Release Requested",
  RELEASE_BLOCKED: "Release Blocked",
  SBOM_EXPORTED: "SBOM Exported",
  SEMVER_LINK_CLICKED: "Semver Link Clicked",
  STANDARD_CONFIG_APPLIED: "Standard Config Applied",
  STANDARD_CONFIG_TOGGLED: "Standard Config Toggled",
  VERSION_CHECK_NEEDS_UPDATE: "Version Check Needs Update",
  VIOLATION_STATUS_OVERRIDE: "Violation Status Override Created",
  USER_ADDED_TO_GROUP: "User Added to Group",
  USER_ROLE_EDITED: "User Role Edited",
  WEB_HOOK_CREATED: "Webhook Created",
  /** Subscriber Page Views */
  ACTIVITY_FEED_PAGE_VIEWED: "Activity Feed Page Viewed",
  API_KEYS_PAGE_VIEWED: "API Keys Page Viewed",
  BLOCKED_ITEMS_PAGE_VIEWED: "Blocked Items Page Viewed",
  CATALOG_IMPORT_PROJECT_PAGE_VIEWED: "Catalog Import Project Page Viewed",
  CATALOG_IMPORT_PACKAGE_PAGE_VIEWED: "Catalog Import Package Page Viewed",
  CATALOG_DASHBOARD_PAGE_VIEWED: "Catalog Dashboard Page Viewed",
  CATALOG_OVERVIEW_PAGE_VIEWED: "Catalog Overview Page Viewed",
  CATALOG_PACKAGES_PAGE_VIEWED: "Packages Page Viewed (Catalog)",
  CATALOG_PROJECT_LIST_PAGE_VIEWED: "Catalog Project List Page Viewed",
  CATALOG_TASK_LIST_PAGE_VIEWED: "Catalog Task List Page Viewed",
  CATALOG_TASK_SUMMARY_VIEWED: "Catalog Task Summary Viewed", // drawer
  CATALOG_TASK_PAGE_VIEWED: "Catalog Task Page Viewed", // page
  CATALOG_VIOLATION_OVERRIDE_PAGE_VIEWED: "Catalog Violation Override Page Viewed",
  CATALOG_STANDARDS_LIST_PAGE_VIEWED: "Catalog Standards List Page Viewed",
  CATALOG_STANDARD_PAGE_VIEWED: "Catalog Standard Page Viewed",
  CATALOG_STANDARD_OVERRIDE_PAGE_VIEWED: "Catalog Standard Override Page Viewed",
  CONTINUOUS_INTEGRATION_PAGE_VIEWED: "Continuous Integration Page Viewed",
  CREATE_ROLE_PAGE_VIEWED: "Create Role Page Viewed",
  DEPENDENCY_ISSUES_PAGE_VIEWED: "Dependency Issues Page Viewed",
  GROUP_LIST_PAGE_VIEWED: "Group List Page Viewed",
  GROUP_PAGE_VIEWED: "Group Page Viewed",
  INTEGRATIONS_PAGE_VIEWED: "Integrations Page Viewed",
  MAINTAINER_IMPACT_PAGE_VIEWED: "Maintainer Impact Page Viewed",
  PACKAGES_PAGE_VIEWED: "Packages Page Viewed",
  PACKAGE_ATTESTATION_PAGE_VIEWED: "Package Attestation Page Viewed",
  PACKAGE_CVE_PAGE_VIEWED: "Package CVE Page Viewed",
  PACKAGE_DEPENDENCIES_PAGE_VIEWED: "Package Dependencies Page Viewed",
  PACKAGE_OVERVIEW_PAGE_VIEWED: "Package Overview Page Viewed",
  PACKAGE_QUALITY_CHECKS_PAGE_VIEWED: "Package Quality Checks Page Viewed",
  PACKAGE_RELEASES_PAGE_VIEWED: "Package Releases Page Viewed",
  PACKAGE_PROJECT_USAGE_PAGE_VIEWED: "Package Project Usage Page Viewed",
  PACKAGE_VULNERABILITIES_PAGE_VIEWED: "Package Vulnerabilities Page Viewed",
  PACKAGE_VULNERABILITIES_BY_RELEASE_STREAM_VIEWED: "Package Vulnerabilities By Release Stream Viewed", // release_stream view of vulnerabilities page
  PROJECT_OVERVIEW_PAGE_VIEWED: "Project Overview Page Viewed",
  PROJECT_SETTINGS_PAGE_VIEWED: "Project Settings Page Viewed",
  PROJECT_KEYS_PAGE_VIEWED: "Project Keys Page Viewed",
  PROJECT_ALIGNMENT_PAGE_VIEWED: "Project Alignment Page Viewed",
  PROJECT_DEPENDENCIES_PAGE_VIEWED: "Project Dependencies Page Viewed",
  REPORTS_PAGE_VIEWED: "Reports Page Viewed",
  ROLES_PAGE_VIEWED: "Roles Page Viewed",
  SLACK_INTEGRATION_PAGE_VIEWED: "Slack Integration Page Viewed",
  USER_INVITED: "User Invited",
  USER_ROLE_DELETED: "User Role Deleted",
  USER_SETTINGS_PAGE_VIEWED: "User Settings Page Viewed",
  WEBHOOK_INTEGRATION_PAGE_VIEWED: "Webhook Integration Page Viewed",
  /** Lifter events */
  LIFTER_PACKAGE_ACTIVATED: "Lifter Package Activated",
  LIFTER_PACKAGE_APPLIED: "Lifter Package Applied",
  LIFTER_TASK_CATEGORY_VIEWED: "Lifter Task Category Viewed",
  LIFTER_TASK_OPENED: "Lifter Task Opened",
  LIFTER_TASK_ENGAGED: "Lifter Task Engaged",
  LIFTER_TASK_COMPLETED: "Lifter Task Completed",
  LIFTER_TASK_COMPLETION_CANCELED: "Lifter Task Completion Canceled",
  /** Lifter Page Views */
  LIFTER_PACKAGE_TASK_LIST_PAGE_VIEWED: "Lifter Package Task List Page Viewed",
  LIFTER_PACKAGE_OVERVIEW_VIEWED: "Lifter Package Overview Viewed",
  LIFTER_PACKAGE_TASK_VIEWED: "Lifter Package Task Viewed",
  LIFTER_TASK_DASHBOARD_VIEWED: "Lifter Task Dashboard Viewed",
}

export interface RouterMetrics {
  event: string
  data?: Record<string, any> | ((params: any) => Record<string, any>)
}

type Properties = Record<string, any>

export function useAmplitude() {
  if (config.amplitudeToken) {
    return amplitude
  }
}

/** Keep track of the most recently emitted event */
let lockEvent = ""

export function useMetrics() {
  const amp = useAmplitude()

  function resetLockEvent() {
    lockEvent = ""
  }

  /**
   * Currently this logs events to Amplitude only, if Amplitude's token is configured.
   * Will also log events to the console in the development environment.
   *
   * @param name Value from METRICS_EVENT
   * @param throttled If true and an event with the same name+data was the last event sent, do not send this event
   * @param route If provided, will inject known route parameters into the event data
   */
  function event(name: string, data: Properties, throttled = false, route = useRoute()) {
    /** If this event has already been emitted */
    const serialized = name + JSON.stringify(data)
    const lockEventsMatch = lockEvent === serialized
    if (throttled && lockEventsMatch) {
      return
    }

    /** Always capture certain router properties */
    const defaults: Record<string, string> = {
      referring_url: window.location.href,
    }

    if (route?.params) {
      defaults["catalog_name"] = route.params["catalogName"] as string
      defaults["organization"] = route.params["organization"] as string
      defaults["platform"] = route.params["platform"] as string
      defaults["package_name"] = route.params["name"] as string
      defaults["catalog_standard"] = (route.params["catalogStandard"] || route.params["standardSlug"]) as string
    }
    const props = Object.assign({}, defaults, data || {})
    /** Add any properties we want for all events */
    amp?.track(name, props)
    lockEvent = serialized
    if (import.meta.env.MODE === "development") {
      console.info("Metrics event:", name, props)
    }
  }

  function setUserProperties(set: Properties, setOnce: Properties = {}) {
    /** Set amplitude properties */
    const identifyEvent = new amplitude.Identify()
    Object.keys(set).forEach(key => {
      identifyEvent.set(key, set[key])
    })
    amplitude.identify(identifyEvent)

    /** Set amplitude properties ONCE */
    const identifyEventOnce = new amplitude.Identify()
    Object.keys(setOnce).forEach(key => {
      identifyEventOnce.setOnce(key, setOnce[key])
    })
    amplitude.identify(identifyEventOnce)

    if (import.meta.env.MODE === "development") {
      console.info("Metrics setUserProperties:", set, setOnce)
    }
  }

  function identify(distinctId: string, properties: Properties) {
    // https://community.amplitude.com/data-instrumentation-57/invalid-id-length-for-user-id-or-device-id-763
    // have to pad the user id to 6 characters because amplitude doesn't understand what a number is
    amp?.setUserId(distinctId.toString().padStart(6, "0"))
    /** Set amplitude properties */
    const identifyEvent = new amplitude.Identify()
    Object.keys(properties).forEach(key => {
      identifyEvent.set(key, properties[key])
    })
    amplitude.identify(identifyEvent)

    if (import.meta.env.MODE === "development") {
      console.info("Metrics identify:", distinctId, properties)
    }
  }

  return { event, setUserProperties, identify, resetLockEvent }
}

export function metricsSetup() {
  const amp = useAmplitude()
  if (config.amplitudeToken) {
    amp?.init(config.amplitudeToken, undefined, {
      defaultTracking: {
        attribution: true,
        pageViews: false,
        sessions: true,
        formInteractions: true,
      },
    })
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1,
    })
    amp?.add(sessionReplayTracking)
  }
}

/**
 * v-metrics custom directive adds instrumentation for metrics.ts
 * For DOM events 'click' and 'change`, elements with this directive will fire a
 * custom event to metrics defined by the binding value.
 */
export const vMetricsLifterTaskEngaged = <
  Directive<
    HTMLElement,
    {
      catalog_standard: string
      platform: string
      name: string
    }
  >
>{
  mounted(el, binding, vnode) {
    const metrics = useMetrics()
    const emit = () => {
      metrics.event(
        METRICS_EVENT.LIFTER_TASK_ENGAGED,
        {
          catalog_standard: binding.value.catalog_standard,
          associated_package: packageUniqueKey(binding.value),
        },
        true
      )
    }
    el.addEventListener("click", emit)
    el.addEventListener("focus", emit)
    el.addEventListener("change", emit)
    // @ts-expect-error need to keep a reference to this somewhere
    el.emit = emit
  },
  unmounted(el, binding) {
    // @ts-expect-error need to keep a reference to this somewhere
    el.removeEventListener("click", el.emit)
    // @ts-expect-error need to keep a reference to this somewhere
    el.removeEventListener("focus", el.emit)
    // @ts-expect-error need to keep a reference to this somewhere
    el.removeEventListener("change", el.emit)
  },
}
