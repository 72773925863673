<template>
  <div>
    <template v-if="label">
      <label
        class="progress-label"
        :for="id"
        >{{ label }}</label
      >
    </template>
    <progress
      v-else-if="validParameters(value, max)"
      :id="id"
      class="progress is-tidelift-blue"
      :value="value"
      :max="max"
    >
      <!-- Note: Text placed between the element’s tags is not an accessible label, it is only recommended as a fallback for old browsers that do not support this element. -->
      <template v-if="value !== undefined && max !== undefined"> {{ (value / max) * 100 }}% </template>
    </progress>
    <p
      v-else
      class="is-danger"
    >
      Invalid parameters
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export function validParameters(value: number | undefined, max: number | undefined): boolean {
  if (value === undefined || max === undefined) {
    return true
  } else {
    return value >= 0 && max > 0 && value <= max
  }
}

export default defineComponent({
  name: "TlProgress",
  props: {
    value: {
      type: Number,
      validator: (value: number) => value >= 0,
    },
    max: {
      type: Number,
      validator: (value: number) => value > 0,
    },
    label: String,
    id: String,
  },
  methods: {
    validParameters,
  },
})
</script>
