<template>
  <div
    id="root"
    class="theme-light"
  >
    <GlobalTopBar v-bind="{ mode }" />
    <GlobalErrorPage
      v-if="errorStore.errorType"
      :errorType="errorStore.errorType"
    />
    <RouterView v-show="!errorStore.errorType" />
    <span
      v-if="showDevChatWithUs"
      class="dev-chat-with-us"
      >Chat with us!</span
    >
    <MobileNotice class="mobile-notice" />
  </div>
</template>

<script lang="ts">
import MobileNotice from "@/components/scaffold/MobileNotice.vue"
import { computed, defineComponent, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import GlobalErrorPage from "./components/pages/GlobalErrorPage.vue"
import GlobalTopBar from "./components/scaffold/GlobalTopBar.vue"
import { useTitle } from "./services/title"
import { useGlobalErrorStore } from "./store/pinia/globalErrors"
import { useVersionCheck } from "./services/checkFrontendVersion"

interface ShowChatWithUsWindow extends Window {
  showChatWithUs: () => void
}

declare let window: ShowChatWithUsWindow

export default defineComponent({
  components: {
    MobileNotice,
    GlobalErrorPage,
    GlobalTopBar,
  },
  setup() {
    useTitle("Tidelift")
    const showDevChatWithUs = ref(false)
    const route = useRoute()
    const router = useRouter()
    const errorStore = useGlobalErrorStore()
    const mode = computed(() => (route.meta.userRole as "Lifter" | "Subscriber" | undefined) || "Global")

    /** Reset the global error when a user moves to a different page */
    router.beforeEach(v => {
      if (errorStore.errorType) {
        errorStore.clearError()
      }
    })

    if (import.meta.env.DEV) {
      // Run showChatWithUs() to show a fake 'Chat With Us!' button.
      window.showChatWithUs = () => {
        showDevChatWithUs.value = true
      }
    }

    if (import.meta.env.PROD) {
      useVersionCheck()
    }

    return {
      mode,
      route,
      showDevChatWithUs,
      errorStore,
    }
  },
})
</script>

<style lang="scss">
#root {
  font-family: $family-sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: 22px;
}

::selection {
  @include themify {
    color: themed("color-background-secondary");
  }

  @include themify {
    background: themed("color-text-secondary");
  }

  text-shadow: none;
}

.tabs li a {
  /* This removes an ugly focus outline around notebook tabs;
   * it would be better to preventDefault on mousedown perhaps,
   * so there's still a focus marker when keyboard navigating,
   * but ought to restyle the outline first and then make
   * a component to use for notebook tabs including the mousedown
   * handler.
   */
  outline-width: 0;
}

.columns {
  @include mobile {
    margin-left: 0;
    margin-right: 0;
  }
}

.dev-chat-with-us {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 150px;
  height: 50px;
  background-color: orange;
}

.mobile-notice {
  max-height: 100vh;
  max-width: 100vw;
}
</style>
