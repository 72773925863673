import RouterViewWrapper from "@/components/scaffold/RouterViewWrapper.vue"
import RouterViewWrapperWithBreadcrumb from "@/components/scaffold/RouterViewWrapperWithBreadcrumb.vue"
import { RouteRecordRaw } from "vue-router"
import { STANDARD_RENAMES, useCatalogsStore } from "@/store/pinia/catalogs"
import { Policies } from "@/store/pinia/policy"
import { METRICS_EVENT, RouterMetrics } from "@/services/metrics"
import { CATALOG_STANDARD } from "@/types/task"

const BlockedItems = () => import("@/components/pages/catalogs/BlockedItemsPage.vue")
const Catalogs = () => import("@/components/pages/catalogs/Catalogs.vue")
const CatalogRouterView = () => import("@/components/pages/catalogs/CatalogRouterView.vue")
const CatalogOverview = () => import("@/components/pages/catalogs/CatalogOverview.vue")
const CatalogSetup = () => import("@/components/pages/catalogs/CatalogSetup.vue")
const CatalogImportNew = () => import("@/components/pages/catalogs/CatalogImportNew.vue")
const CatalogImportScan = () => import("@/components/pages/catalogs/CatalogImportScan.vue")
const CatalogPackages = () => import("@/components/pages/catalogs/CatalogPackages.vue")
const CatalogActivityFeed = () => import("@/components/pages/catalogs/CatalogActivityFeed.vue")
const CatalogStandardsAllowedLicensesRouter = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/AllowedLicensesStandardConfiguration.vue")
const CatalogStandardsAllowedLicensesEditRouter = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/AllowedLicenseEdit.vue")
const CatalogStandardsIdentifiedLicenses = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/IdentifiedLicensesStandardConfiguration.vue")
const CatalogStandardOverrides = () => import("@/components/pages/catalogs/CatalogStandardOverrides.vue")
const CatalogStandards = () => import("@/components/pages/catalogs/CatalogStandards.vue")
const CatalogStandardsUpToDate = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/UpToDateStandardConfiguration.vue")
const CatalogStandardsDeprecation = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/DeprecationStandardConfiguration.vue")
const CatalogStandardsEOLPackages = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/EOLPackagesStandardConfiguration.vue")
const CatalogStandardsKnownPackages = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/KnownPackagesStandardConfiguration.vue")
const CatalogStandardsKnownReleases = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/KnownReleasesStandardConfiguration.vue")
const CatalogStandardsPrereleases = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/PrereleasesStandardConfiguration.vue")
const CatalogStandardsVulnerabilities = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/VulnerabilitiesStandardConfiguration.vue")
const CatalogStandardsRemovedReleases = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/RemovedReleasesStandardConfiguration.vue")
const CatalogStandardsAppearMaintained = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/AppearMaintainedStandardConfiguration.vue")
const CatalogStandardsCleanDependencies = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/CleanDependenciesStandardConfiguration.vue")
const CatalogStandardsCleanReleases = () =>
  import("@/components/pages/catalogs/catalog_standards_configurations/CleanReleasesStandardConfiguration.vue")
const CatalogTasks = () => import("@/components/pages/catalogs/CatalogTasks.vue")
const SubscriberCatalogTaskReview = () => import("@/components/pages/catalogs/catalog_tasks/TaskReview.vue")
// TODO: Move once we get rid of the Packages LHN
const CatalogUserRoles = () => import("@/components/pages/catalogs/CatalogUserRoles.vue")
const PackageSearch = () => import("@/components/pages/packages/PackageSearch.vue")
const CreateNewProject = () => import("@/components/pages/team/CreateNewProject.vue")
const TaskLifterStandardsMultiplexer = () =>
  import("@/components/pages/lifter/lifter_dashboard/task_lists/TaskLifterStandardsMultiplexer.vue")

const taskPolicy = {
  scope: "catalog",
  policy: Policies.list_tasks,
}

const Tasks: RouteRecordRaw[] = [
  /* these task routes render in the catalog tasks view */
  {
    path: "tasks",
    component: CatalogTasks,
    name: "SubscriberCatalogTasks",
    meta: {
      breadcrumb: { title: "Tasks" },
      requirePolicy: taskPolicy,
      metrics: {
        event: METRICS_EVENT.CATALOG_TASK_LIST_PAGE_VIEWED,
      },
    },
    props: true,
  },
  /* these task sub-routes each render on their own unique page */
  {
    path: "tasks",
    component: RouterViewWrapperWithBreadcrumb,
    meta: {
      breadcrumb: { title: "Tasks" },
      meta: {
        requirePolicy: taskPolicy,
      },
    },
    children: [
      {
        path: "standard/:catalogStandard/:platform/:name/page",
        redirect: {
          name: "SubscriberCatalogLifterTask",
        },
      },
      {
        name: "SubscriberCatalogLifterTask",
        path: "standard/:catalogStandard/:platform/:name",
        props: true,
        meta: {
          breadcrumb: { title: (params: { catalogStandard: string }) => `Review ${params.catalogStandard} task` },
          metrics: {
            event: METRICS_EVENT.CATALOG_TASK_PAGE_VIEWED,
          },
        },
        component: TaskLifterStandardsMultiplexer,
      },
      {
        path: ":taskId",
        name: "SubscriberCatalogTaskReview",
        component: SubscriberCatalogTaskReview,
        props: true,
        meta: {
          breadcrumb: {
            title: (params: { taskId: string }) => {
              const catalogsStore = useCatalogsStore()
              const standard = catalogsStore.catalogReleaseWithTask?.catalog_standard
              if (standard) return `Review ${catalogsStore.standardForSlug(standard)?.name.toLowerCase() || ""} task`
              return "Review task"
            },
          },
          // Disable default metrics for this route, it will be emitted within the task component.
          metrics: false,
        },
      },
      {
        /** Unused!  This route is for backward compatibility with old reports */
        path: ":taskId/:taskType",
        redirect: to => ({ name: "SubscriberCatalogTaskReview", params: to.params }),
      },
      {
        /** Unused!  This route is for backward compatibility with old reports */
        path: ":platform/:packageName/:version/:taskId",
        redirect: to => ({ name: "SubscriberCatalogTaskReview", params: to.params }),
      },
    ],
  },
]

function getTitleForStandard(standardSlug: string) {
  return () => {
    const oldStandardSlug = STANDARD_RENAMES[standardSlug]
    const standard =
      useCatalogsStore().standardForSlug(standardSlug) || useCatalogsStore().standardForSlug(oldStandardSlug)
    return `Configure the ${standard ? standard.name : "..."} Standard`
  }
}

export default [
  {
    path: "",
    name: "SubscriberCatalogs",
    component: Catalogs,
    props: true,
    meta: {
      metrics: {
        event: METRICS_EVENT.CATALOG_DASHBOARD_PAGE_VIEWED,
      },
    },
  },
  {
    path: ":catalogName",
    component: CatalogRouterView,
    name: "SubscriberCatalogBaseRoute",
    meta: {
      breadcrumb: { title: "Catalogs" },
    },
    children: [
      ...Tasks,
      {
        path: "",
        name: "SubscriberCatalogOverview",
        component: CatalogOverview,
        props: true,
        meta: {
          metrics: {
            event: METRICS_EVENT.CATALOG_OVERVIEW_PAGE_VIEWED,
          },
        },
      },
      {
        path: "import",
        component: RouterViewWrapperWithBreadcrumb,
        meta: {
          breadcrumb: { title: "Add package releases" },
        },
        children: [
          {
            path: "",
            name: "SubscriberCatalogImportNew",
            component: CatalogImportNew,
            props: true,
          },
          {
            path: "setup",
            name: "SubscriberCatalogSetup",
            component: CatalogSetup,
            props: true,
          },
          {
            path: "importScan",
            component: RouterViewWrapper,
            children: [
              {
                path: "",
                name: "SubscriberCatalogImportScan",
                component: CatalogImportScan,
                props: true,
                meta: {
                  breadcrumb: { title: "from a project" },
                  requirePolicy: {
                    scope: "team",
                    policy: Policies.create_projects,
                  },
                },
              },
              {
                path: "fromNewProject",
                name: "SubscriberCatalogImportScanFromNewProject",
                component: CreateNewProject,
                props: ({ params }: { params: any }) => ({ catalogImportFlow: true, ...params }),
                meta: {
                  breadcrumb: { title: "from a project" },
                  requirePolicy: {
                    scope: "team",
                    policy: Policies.create_projects,
                  },
                  metrics: {
                    event: METRICS_EVENT.CATALOG_IMPORT_PROJECT_PAGE_VIEWED,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: "releases",
        component: RouterViewWrapper,
        meta: {
          breadcrumb: { title: "Packages" },
        },
        children: [
          {
            path: "",
            name: "SubscriberCatalogPackages",
            component: CatalogPackages,
            props: true,
            meta: {
              metrics: {
                event: METRICS_EVENT.CATALOG_PACKAGES_PAGE_VIEWED,
              },
            },
          },
          {
            path: "search",
            name: "CatalogPackageSearch",
            component: PackageSearch,
            props: ({ params }: { params: any }) => ({
              ...params,
              getRecommendedPackages: false,
            }),
            meta: {
              breadcrumb: { title: "Search for new packages" },
              metrics: {
                event: METRICS_EVENT.CATALOG_IMPORT_PACKAGE_PAGE_VIEWED,
              },
            },
          },
          {
            path: "blocked_items",
            name: "BlockedItems",
            component: BlockedItems,
            props: true,
            meta: {
              breadcrumb: { title: "Blocked items" },
              metrics: {
                event: METRICS_EVENT.BLOCKED_ITEMS_PAGE_VIEWED,
              },
            },
          },
        ],
      },
      {
        path: "standards",
        component: RouterViewWrapperWithBreadcrumb,
        meta: {
          breadcrumb: { title: "Standards" },
        },
        children: [
          {
            path: "",
            name: "SubscriberCatalogStandards",
            component: CatalogStandards,
            props: true,
            meta: {
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARDS_LIST_PAGE_VIEWED,
              },
            },
          },
          {
            path: "rules",
            redirect: {
              name: "SubscriberAllowedLicensesConfiguration",
            },
          },
          {
            path: "allowed_licenses",
            name: "SubscriberAllowedLicensesConfiguration",
            component: CatalogStandardsAllowedLicensesRouter,
            meta: {
              breadcrumb: { title: getTitleForStandard("allowed_licenses") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.ALLOWED_LICENSES },
              },
            },
            props: true,
            children: [
              {
                path: ":licenseName/edit",
                name: "SubscriberAllowedLicensesConfigurationEdit",
                component: CatalogStandardsAllowedLicensesEditRouter,
                props: true,
                meta: {
                  breadcrumb: { title: (params: any) => `Set policy for ${params.licenseName || "license"}` },
                },
              },
            ],
          },
          {
            path: "identified_licenses",
            name: "SubscriberIdentifiedLicensesConfiguration",
            component: CatalogStandardsIdentifiedLicenses,
            meta: {
              breadcrumb: { title: getTitleForStandard("identified_licenses") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.IDENTIFIED_LICENSES },
              },
            },
            props: true,
          },
          {
            path: "vulnerabilities",
            name: "SubscriberVulnerabilitiesConfiguration",
            component: CatalogStandardsVulnerabilities,
            props: true,
            meta: {
              breadcrumb: { title: getTitleForStandard("vulnerabilities") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.VULNERABILITIES },
              },
            },
          },
          {
            path: "up_to_date",
            name: "SubscriberUpToDateConfiguration",
            component: CatalogStandardsUpToDate,
            meta: {
              breadcrumb: { title: getTitleForStandard("up_to_date") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.UP_TO_DATE },
              },
            },
            props: true,
          },
          {
            path: "deprecation",
            name: "SubscriberDeprecationConfiguration",
            component: CatalogStandardsDeprecation,
            meta: {
              breadcrumb: { title: getTitleForStandard("deprecation") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.DEPRECATION },
              },
            },
            props: true,
          },
          {
            path: "eol_packages",
            name: "SubscriberEOLPackagesConfiguration",
            component: CatalogStandardsEOLPackages,
            meta: {
              breadcrumb: { title: getTitleForStandard("eol_packages") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.EOL_PACKAGES },
              },
            },
            props: true,
          },
          {
            path: "known_packages",
            name: "SubscriberKnownPackagesConfiguration",
            component: CatalogStandardsKnownPackages,
            meta: {
              breadcrumb: {
                title: getTitleForStandard("known_packages"),
              },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.KNOWN_PACKAGES },
              },
            },
            props: true,
          },
          {
            path: "known_releases",
            name: "SubscriberKnownReleasesConfiguration",
            component: CatalogStandardsKnownReleases,
            meta: {
              breadcrumb: {
                title: getTitleForStandard("known_releases"),
              },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.KNOWN_RELEASES },
              },
            },
            props: true,
          },
          {
            path: "prereleases",
            name: "SubscriberPrereleasesConfiguration",
            component: CatalogStandardsPrereleases,
            meta: {
              breadcrumb: { title: getTitleForStandard("prereleases") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.PRERELEASES },
              },
            },
            props: true,
          },
          {
            path: "removed_releases",
            name: "SubscriberRemovedReleasesConfiguration",
            component: CatalogStandardsRemovedReleases,
            meta: {
              breadcrumb: { title: getTitleForStandard("removed_releases") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.REMOVED_RELEASES },
              },
            },
            props: true,
          },
          {
            path: "packages_appear_maintained",
            name: "SubscriberAppearMaintainedConfiguration",
            component: CatalogStandardsAppearMaintained,
            meta: {
              breadcrumb: { title: getTitleForStandard("packages_appear_maintained") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.PACKAGES_APPEAR_MAINTAINED },
              },
            },
            props: true,
          },
          {
            path: "releases_have_clean_dependencies",
            name: "SubscriberCleanDependenciesConfiguration",
            component: CatalogStandardsCleanDependencies,
            meta: {
              breadcrumb: { title: getTitleForStandard("releases_have_clean_dependencies") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.RELEASES_HAVE_CLEAN_DEPENDENCIES },
              },
            },
            props: true,
          },
          {
            path: "packages_have_clean_releases",
            name: "SubscriberCleanReleasesConfiguration",
            component: CatalogStandardsCleanReleases,
            meta: {
              breadcrumb: { title: getTitleForStandard("packages_have_clean_releases") },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_PAGE_VIEWED,
                data: { catalog_standard: CATALOG_STANDARD.PACKAGES_HAVE_CLEAN_RELEASES },
              },
            },
            props: true,
          },
          {
            path: ":standardSlug/overrides",
            name: "SubscriberCatalogStandardOverrides",
            component: CatalogStandardOverrides,
            meta: {
              breadcrumb: { title: "Standard overrides" },
              metrics: {
                event: METRICS_EVENT.CATALOG_STANDARD_OVERRIDE_PAGE_VIEWED,
              },
            },
            props: true,
          },
        ],
      },
      {
        path: "activity",
        name: "SubscriberCatalogActivityFeed",
        component: CatalogActivityFeed,
        props: true,
        meta: {
          breadcrumb: { title: "Activity feed" },
          metrics: {
            event: METRICS_EVENT.ACTIVITY_FEED_PAGE_VIEWED,
          },
        },
      },
      {
        path: "roles",
        component: RouterViewWrapper,
        meta: {
          breadcrumb: { title: "Roles" },
        },
        children: [
          {
            path: "",
            name: "SubscriberCatalogUserRoles",
            component: CatalogUserRoles,
            props: true,
            meta: {
              requirePolicy: {
                scope: "catalog",
                policy: Policies.view_users,
              },
              metrics: {
                event: METRICS_EVENT.ROLES_PAGE_VIEWED,
              },
            },
          },
        ],
      },
      {
        path: "reports",
        redirect: {
          name: "Reports",
        },
      },
    ],
  },
]
