import { CATALOG_STANDARD } from "@/types/task"
import { PackageQualityChecks } from "@/types/package"

// These indicate the action one will be taking when they click into this task.
const CARD_TITLES: Record<CATALOG_STANDARD, string> = {
  active_release_stream: "Set active release stream",
  allowed_licenses: "",
  package_is_recommended_replacement: "Identify alternative packages",
  blocked_list: "",
  deprecation: "",
  eol_packages: "End-of-life packages",
  github_two_factor_auth: "Enable 2FA on GitHub",
  historical_security_review: "Requires historical security review",
  identified_licenses: "Identified licenses",
  known_packages: "Known packages",
  known_releases: "Known releases",
  openssf_branch_protection: "",
  openssf_security_policy: "",
  package_manager_two_factor_auth: "Enable 2FA in Package Manager",
  packages_appear_maintained: "Review package which appears unmaintained",
  packages_have_clean_releases: "Review issues that subscribers are seeing",
  packages_have_codes_of_conduct: "Set code of conduct URL",
  packages_have_contributing_guidelines: "Set contributing guidelines URL",
  packages_have_income_streams: "Packages have income streams",
  packages_have_lifecycle_evidence: "Set lifecycle evidence URL",
  packages_have_lifecycle_policies: "Set lifecycle policy URL",
  packages_have_maintenance_plans: "Create a security maintenance plan",
  packages_have_repositories: "Set source repository URL",
  packages_have_security_policies: "Create a discoverable security policy",
  packages_have_verified_licenses: "Verify license",
  packages_have_versioning_schemes: "Set versioning scheme",
  prereleases: "prereleases",
  release_managers_reviewed: "Review release managers",
  releases_have_clean_dependencies: "",
  removed_releases: "",
  up_to_date: "",
  vulnerabilities: "",
  vulnerability_recommendations: "Security vulnerabilities need review",
}
export function titleForTaskName(taskName: CATALOG_STANDARD) {
  return CARD_TITLES[taskName]
}

export function getNewLiftingCopy(name: string, email: string, isLifter: boolean) {
  const newLifterMessage = `You'll receive an email shortly at ${email} with next steps for becoming a lifter.`
  const existingLifterMessage = `We will review and get back to you at ${email} in 3-5 business days.`
  return `We have received your application to lift ${name}. ${isLifter ? existingLifterMessage : newLifterMessage}`
}

export const IDENTITY_PROVIDER_DATA = {
  auth0: {
    name: "Email",
    settingsUrl: "https://tidelift.com/",
  },
  github: {
    name: "GitHub",
    settingsUrl: "https://github.com/settings/security",
  },
  "google-oauth2": {
    name: "Google",
    settingsUrl: "https://myaccount.google.com/security",
  },
}

export const REPO_TYPE = {
  GcsRepository: "team",
  GithubRepository: "github",
}

export const VERSIONING_SCHEMES = {
  semver: "Semantic versioning",
  pep440: "PEP 440",
  calver: "Calendar versioning",
  other: "Other",
}

export const DECISION_TYPES = {
  pending: "Needs review",
  approved: "Create an override for affected release(s)",
  denied: "Replace with unaffected release(s)",
  denied_no_upgrade: "Change the affected release(s) status to denied",
}

export const DEFAULT_FILTER_PLACEHOLDER = "<=4.0.0, ^0.5.0, 1.0.0 - 1.2.0"

export const VULNERABILITY_STATUS_MESSAGES = {
  approved: "Create an override for affected releases",
  denied: "Do not use these releases",
}

export const GIT_SPECIFIC_INSTRUCTIONS_HTML = `Here are instructions for several popular git hosts:<br />
  <ul>
    <li>GitHub: <a target="_blank" rel="noopener" href="https://help.github.com/en/articles/configuring-two-factor-authentication">help.github.com/en/articles/configuring-two-factor-authentication</a></li>
    <li>GitLab: <a target="_blank" rel="noopener" href="https://docs.gitlab.com/ee/user/profile/account/two_factor_authentication.html#enabling-2fa">docs.gitlab.com/ee/user/profile/account/two_factor_authentication.html#enabling-2fa</a></li>
    <li>Bitbucket: <a target="_blank" rel="noopener" href="https://confluence.atlassian.com/bitbucket/two-step-verification-777023203.html">confluence.atlassian.com/bitbucket/two-step-verification-777023203.html</a></li>
  </ul>`

export const GITHUB_SPECIFIC_INSTRUCTIONS_HTML = `
  1) Login to <a target="_blank" rel="noopener" href="https://github.com">github.com</a>.<br />
  2) Click on <strong>Settings</strong> in the upper-right, and then <strong>Password and authentication</strong>.<br />
  3) Enable Two-Factor Authentication by clicking <strong>Enable Two-Factor Authentication</strong>. (<a target="_blank" rel="noopener" href="https://help.github.com/en/articles/configuring-two-factor-authentication">full documentation</a>)<br />
`

export const PACKAGE_MANAGER_INSTRUCTIONS_HTML = {
  // Package managers with MFA support:
  npm: `
    1) Login to <a target="_blank" rel="noopener" href="https://www.npmjs.com/">npmjs.com</a>.<br />
    2) Click on <strong>Profile Settings</strong> in the upper-right.<br />
    3) Enable Two-Factor Authentication for <strong>Authorization and Publishing</strong>. (<a target="_blank" rel="noopener" href="https://docs.npmjs.com/configuring-two-factor-authentication">full documentation</a>)<br />
    <br />
    <strong>Bonus</strong>: NPM also allows you to set package-wide <a target="_blank" rel="noopener" href="https://docs.npmjs.com/requiring-2fa-for-package-publishing-and-settings-modification">2FA requirements</a>.<br />
  `,
  homebrew: `
    Homebrew formulas are stored in GitHub. To enable Two-Factor Authentication on GitHub:<br />
    <br />
    1) Login to <a target="_blank" rel="noopener" href="https://github.com">github.com</a>.<br />
    2) Click on <strong>Settings</strong> in the upper-right, and then <strong>Password and authentication</strong>.<br />
    3) Enable Two-Factor Authentication by clicking <strong>Enable Two-Factor Authentication</strong>.(<a target="_blank" rel="noopener" href="https://help.github.com/en/articles/configuring-two-factor-authentication">full documentation</a>)<br />
  `,
  rubygems: `
    1) Login to <a target="_blank" rel="noopener" href="https://rubygems.org">rubygems.org</a><br />
    2) Visit the <strong>Edit Profile</strong> page: <a target="_blank" rel="noopener" href="https://rubygems.org/profile/edit#mfa-edit">rubygems.org/profile/edit</a><br />
    3) Enable Two-Factor Authentication for <strong>UI and API</strong>. (<a target="_blank" rel="noopener" href="https://guides.rubygems.org/setting-up-multifactor-authentication/">full documentation</a>)<br />
  `,
  julia: `Julia packages are stored in git. ${GIT_SPECIFIC_INSTRUCTIONS_HTML}`,
  go: `Go packages are stored in git, mercurial, svn or bzr. ${GIT_SPECIFIC_INSTRUCTIONS_HTML}`,
  atom: `Atom (atom.io/packages) uses GitHub for authentication. <br /><br />${GITHUB_SPECIFIC_INSTRUCTIONS_HTML}`,
  cargo: `Cargo uses GitHub for authentication. <br /><br />${GITHUB_SPECIFIC_INSTRUCTIONS_HTML}`,
  carthage: `Carthage packages are stored in git. ${GIT_SPECIFIC_INSTRUCTIONS_HTML}`,
  pub: `
    Dart packages are published using a Google email address:<br />

    1) Login to Google at <a target="_blank" rel="noopener" href="https://accounts.google.com/">accounts.google.com/</a>.<br />
    2) Visit <a target="_blank" rel="noopener" href="https://www.google.com/landing/2step/">www.google.com/landing/2step/</a>.<br />
    3) Click on <strong>Get Started</strong> and enable Two-Factor Authentication for your Google account.<br />
    <br />
  `,
  cocoapods: `CocoaPods packages are stored in git, mercurial, svn, bzr or http. ${GIT_SPECIFIC_INSTRUCTIONS_HTML}`,
  bower: `Bower packages are stored in git. ${GIT_SPECIFIC_INSTRUCTIONS_HTML}`,
  nuget: `
    Nuget uses Microsoft accounts for login:<br />
    <br />
    1) Login to the Microsoft account you've linked with Nuget at <a target="_blank" rel="noopener" href="https://accounts.google.com/">accounts.google.com/</a>.<br />
    2) Visit <a target="_blank" rel="noopener" href="https://account.live.com/proofs/manage/additional">account.live.com/proofs/manage/additional</a>.<br />
    3) If 2FA is not enabled, click <strong>Turn on two-step verification</strong> and enable it for your Microsoft account.<br />
    4) Finally, login to Nuget and ensure that your account is linked to your Microsoft account and has 2FA enabled: <a target="_blank" rel="noopener" href="https://www.nuget.org/account">www.nuget.org/account</a>.
  `,
  puppet: `
    1) Login to <a target="_blank" rel="noopener" href="https://forge.puppet.com/">forge.puppet.com</a><br />
    2) Edit your profile by clicking on your name in the upper-right corner.<br />
    3) Enable <strong>Use Multifactor Authentication</strong>, which will send an MFA code to your email whenever you login.<br />
  `,
  pypi: `
    1) Login to <a target="_blank" rel="noopener" href="https://pypi.org/">pypi.org</a><br />
    2) Visit the <strong>Account Settings</strong> page: <a target="_blank" rel="noopener" href="https://pypi.org/manage/account/">pypi.org/manage/account/</a><br />
    3) Select <strong>Add 2FA by TOTP Application</strong> or <strong>Add 2FA with security key</strong>. (<a target="_blank" rel="noopener" href="https://pypi.org/help/#2fa">full documentation</a>)<br />
  `,
  packagist: `
    1) Login to <a target="_blank" rel="noopener" href="https://packagist.org/">packagist.org</a><br />
    2) Visit the <strong>Settings > Two-factor auth</strong> page: <a target="_blank" rel="noopener" href="https://packagist.org/profile/edit">packagist.org/profile/edit</a><br />
    3) Click <strong>Enable two-factor authentication</strong> and scan the QR code using an app that supports TOTP<br />
  `,
  // Package managers without MFA support:
  cpan: "CPAN (www.cpan.org) doesn't currently support Two-Factor Authentication.<br /> <br />  Please urge them to consider adding it!",
  cran: "Cran (cran.r-project.org) doesn't have a login system, so go ahead and click Done!",
  hackage:
    "Hackage (hackage.haskell.org) doesn't currently support Two-Factor Authentication.<br /> <br /> Please urge them to consider adding it!",
  hex: "Hex (hex.pm) doesn't currently support Two-Factor Authentication.<br /> <br />  Please urge them to consider adding it!",
  maven:
    "Please confirm that the repository where this package is hosted supports Two-Factor Authentication and enable it.",
  meteor:
    "Meteor (atmospherejs.com) doesn't currently support Two-Factor Authentication.<br /> <br />  Please urge them to consider adding it!",
  wordpress:
    "WordPress (wordpress.org/plugins) doesn't currently support Two-Factor Authentication.<br /> <br />  Please urge them to consider adding it!",
  conda:
    "Please confirm that the repository where this package is hosted supports Two-Factor Authentication and enable it.",
}

export const QUALITY_CHECK_COPY: Record<
  keyof PackageQualityChecks,
  { title: string; title_failed: string; description: string; whyItMatters: string }
> = {
  no_known_vulnerabilities_on_latest_release: {
    title: "No known vulnerabilities on latest release",
    title_failed: "Known vulnerabilities on latest release",
    description:
      "This check looks for any security vulnerabilities on the latest release of the package. This indicates that the maintainers of this package are resolving vulnerabilities as they are identified.",
    whyItMatters:
      "When the latest release is free from security vulnerabilities, this is an indicator that the package maintainers are working to resolve vulnerabilities that arise. This also means that a release is available to upgrade to that is free of vulnerabilities.",
  },
  no_known_issues_in_dependencies_for_latest_release: {
    title: "No known issues in dependencies for latest release",
    title_failed: "Known issues in dependencies for latest release",
    description:
      "This check indicates if the dependencies of the latest release are maintained and have no known vulnerabilities.",
    whyItMatters:
      "A maintainer managing their dependencies and using packages that are free of vulnerabilities increases the likelihood that transitive issues will be taken care of for this package.",
  },
  discoverable_security_policy: {
    title: "Discoverable security policy",
    title_failed: "No discoverable security policy",
    description:
      "This check looks for a publicly available security policy for the package. A security policy should define the process for how maintainers will handle security issues without exposing said issues publicly before a fix is available.",
    whyItMatters:
      "A security policy means that a process is in place to address and fix security issues as they are discovered.",
  },
  two_factor_authentication_at_source_repository: {
    title: "2FA enabled at source repository",
    title_failed: "2FA not enabled at source repository",
    description:
      "This check indicates if Tidelift has first-party attestation that two-factor authentication practices are being used for source repository access.",
    whyItMatters: "Multi-factor authentication provides extra protection from malicious code being added to a package.",
  },
  two_factor_authentication_for_package_manager: {
    title: "2FA enabled for package manager",
    title_failed: "2FA not enabled for package manager",
    description:
      "This check indicates that Tidelift has first-party attestation that two-factor authentication practices are being used for the package manager release process.",
    whyItMatters:
      "Multi-factor authentication provides extra protection from a malicious release being published for a package.",
  },
  release_managers_are_reviewed: {
    title: "Release managers are reviewed",
    title_failed: "Release managers are not reviewed",
    description:
      "This check indicates if Tidelift has first-party confirmation that the allowed release managers for a package have been reviewed and verified.",
    whyItMatters:
      "Reviewing the list of users who are allowed to create releases for a package ensures that releases are coming from a trusted user.",
  },
  package_has_a_stable_release_greater_than_two_years_old: {
    title: "Package has a stable release greater than two years old",
    title_failed: "Package does not have a stable release greater than two years old",
    description: "This check indicates if the package has a stable release that's more than two years old.",
    whyItMatters:
      "A package with an older stable release is more likely to be stable and have continued support than a brand new package that was just released.",
  },
  package_appears_maintained: {
    title: "Package appears maintained",
    title_failed: "Package does not appear maintained",
    description:
      "This check indicates if the package appears maintained based on pull request rates, issue close rates, the lifting status of the package, and Tidelift's research.",
    whyItMatters:
      "A package with activity (responsiveness to PRs and issues) is more likely to have someone available when a vulnerability arises or when dependency management is required.",
  },
  package_has_multiple_active_maintainers: {
    title: "Package has multiple active maintainers",
    title_failed: "Package does not have multiple active maintainers",
    description: "This check indicates if the package has multiple active maintainers.",
    whyItMatters:
      "A package with multiple maintainers and active contributions is at a lower risk of becoming unmaintained.",
  },
  package_has_organizational_backing: {
    title: "Package has organizational backing",
    title_failed: "Package does not have organizational backing",
    description:
      "This check determines whether this package has the backing of an organization. This could be that the package is owned and published by a corporation, that the package is associated with a prominent software foundation, or that the package has a reliable income stream from a source such as Tidelift.",
    whyItMatters:
      "Packages that have organizational backing are more likely to remain maintained, more likely to have a large maintainer and contributor community, and more likely to follow security best practices. All of these are indicators of packages that are better bets to build on top of for your organization.",
  },
  package_is_not_deprecated: {
    title: "Package is not deprecated",
    title_failed: "Package is deprecated",
    description: "This check indicates if the package has been marked as deprecated.",
    whyItMatters:
      "Deprecated packages are unlikely to receive updates if a vulnerability or other issue is identified.",
  },
  package_is_not_eol: {
    title: "Package is not end-of-life",
    title_failed: "Package is end-of-life",
    description: "This check indicates if the package has been declared end-of-life.",
    whyItMatters:
      "End-of-life packages are not maintained and will not receive updates if a vulnerability or other issue is identified.",
  },
  releases_are_discoverable_upstream: {
    title: "Releases are discoverable upstream",
    title_failed: "Releases are not discoverable upstream",
    description: "This check indicates if there are releases available upstream for a given package.",
    whyItMatters:
      "When releases can be downloaded from a public package manager, the risk of getting a release from a malicious source is lowered.",
  },
}

export const UNKNOWN_PACKAGE_DISCLAIMER =
  "This package is not known to Tidelift. This may mean that it is internal to your company, from an unusual source, or not from a public open source repository."

export const UNKNOWN_RELEASE_DISCLAIMER =
  "This release is not known to Tidelift. This may mean that it is internal to your company, from an unusual source, or not from a public open source repository."
