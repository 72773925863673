/* eslint-disable no-console */
import api from "./api"
import { useFeatures } from "@/services/features"
import { computed, onUnmounted, ref, Ref, watch } from "vue"
import { METRICS_EVENT, useMetrics } from "./metrics"

const CHECK_VERSION_INTERVAL_DEBUG_MS = 1000 * 10 // 10 seconds
const CHECK_VERSION_INTERVAL_PROD_MS = 1000 * 60 * 60 * 12 // 12 hours

const CHECK_VERSION_WAKEUP_INTERVAL_DEBUG_MS = 1000 * 3 // 3 seconds
const CHECK_VERSION_WAKEUP_INTERVAL_PROD_MS = 1000 * 60 * 5 // 5 minutes

export const versionCheckTimeoutId: Ref<number | null> = ref(null)
export const needsUpdate = ref(false)
const VERSION_CHECK_KEY = "lastVersionCheckTime"

/**
 * This function is used to check if the current version of frontend is up to
 * date with the latest release. It should poll the server every 12 hours,
 * and keep the last poll time in session storage.
 */
export async function versionCheck(debug = false) {
  const INTERVAL = debug ? CHECK_VERSION_INTERVAL_DEBUG_MS : CHECK_VERSION_INTERVAL_PROD_MS
  const lastVersionCheckTime = Number.parseInt((window.sessionStorage.getItem(VERSION_CHECK_KEY) as string) || "0", 10)
  const now = Date.now()

  if (lastVersionCheckTime + INTERVAL <= now) {
    /** It's time to run an update */
    window.sessionStorage.setItem(VERSION_CHECK_KEY, now.toString())
    try {
      const latestVersion = (await api.fetchLatestVersion()).data
      // @ts-expect-error GITVERSION comes from vite-plugin-git-revision
      needsUpdate.value = latestVersion !== GITVERSION
      console.debug(`Version check results: latest=${latestVersion} needs_update=${needsUpdate.value}`)
      if (needsUpdate.value) {
        useMetrics().event(METRICS_EVENT.VERSION_CHECK_NEEDS_UPDATE, { latestVersion })
        stopVersionCheck()
      }
    } catch (e) {
      console.error("Failed to check for new version, we will try again later", e)
    }
  } else {
    const nextCheckTime = Math.min(INTERVAL, Math.abs(INTERVAL - (now - lastVersionCheckTime)))
    console.debug(`Next version check in ${(nextCheckTime / 1000 / 60).toFixed(3)} minutes.`)
  }
}

export function startVersionCheck(debug = false) {
  stopVersionCheck()
  const INTERVAL = debug ? CHECK_VERSION_WAKEUP_INTERVAL_DEBUG_MS : CHECK_VERSION_WAKEUP_INTERVAL_PROD_MS
  console.debug(`Starting version check in ${INTERVAL / 1000} seconds.`)
  versionCheckTimeoutId.value = window.setInterval(versionCheck, INTERVAL, debug)
}

export function stopVersionCheck() {
  if (versionCheckTimeoutId.value) {
    console.debug("Stopping version check")
    window.clearInterval(versionCheckTimeoutId.value)
  }
}

// @ts-expect-error function is not defined
window.startVersionCheck = startVersionCheck
// @ts-expect-error function is not defined
window.stopVersionCheck = stopVersionCheck

export function useVersionCheck() {
  const enabled = computed(() => useFeatures().isEnabled("frontend:auto_reload"))
  watch(
    enabled,
    enabled => {
      if (enabled) {
        startVersionCheck()
      } else {
        stopVersionCheck()
      }
    },
    { immediate: true }
  )
  onUnmounted(stopVersionCheck)
  return { startVersionCheck, stopVersionCheck, needsUpdate, enabled }
}
