import type { RouteRecordRaw } from "vue-router"

import teamRoutes from "@/components/pages/team/routes"
import repoRoutes from "@/components/pages/repos/routes"
import catalogRoutes from "@/components/pages/catalogs/routes"
import getStartedRoutes from "@/components/pages/getstarted/routes"
import settingRoutes from "./settings/routes"
import Dependencies from "../lifter/Dependencies.vue"
import { METRICS_EVENT } from "@/services/metrics"
import RouterViewWrapperWithBreadcrumb from "@/components/scaffold/RouterViewWrapperWithBreadcrumb.vue"

const RouterViewWrapper = () => import("@/components/scaffold/RouterViewWrapper.vue")
const PackageOpenSSFScorecard = () => import("./PackageOpenSSFScorecard.vue")
const PackageQualityAssuranceReportPage = () =>
  import("@/components/pages/subscriber/PackageQualityAssuranceReportPage.vue")
const SubscriberWrapper = () => import("@/components/pages/SubscriberWrapper.vue")
const DependencyIssues = () => import("@/components/pages/subscriber/DependencyIssues.vue")
const SubscriberRedirect = () => import("@/components/pages/SubscriberRedirect.vue")
const SubscriberSelectTeam = () => import("@/components/pages/SubscriberSelectTeam.vue")
const SlackConnect = () => import("@/components/pages/SlackConnect.vue")
const SubscriberPackages = () => import("./SubscriberPackagesPage.vue")
const SubscriberPackageOverview = () => import("./SubscriberPackagePage.vue")
const SubscriberPackageWrapper = () => import("./PackageWrapper.vue")
const SubscriberPackageReleases = () => import("./SubscriberPackageReleasesPage.vue")
const SubscriberPackageUsage = () => import("./PackageUsageTable.vue")
const SubscriberPackageSettings = () => import("./SubscriberPackageSettings.vue")
const SubscriberPackageVulnerabilitiesPage = () => import("./SubscriberPackageVulnerabilitiesPage.vue")
const SubscriberPackageVulnerabilityPage = () => import("./SubscriberPackageVulnerabilityPage.vue")
const PackageAttestation = () => import("./PackageAttestation.vue")
const TrialEnded = () => import("@/components/scaffold/TrialEnded.vue")
const SubscriptionEnded = () => import("@/components/scaffold/SubscriptionEnded.vue")
const Reports = () => import("./Reports.vue")
const MaintainerImpact = () => import("./MaintainerImpact.vue")

const ReleaseRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: "SubscriberPackages",
    component: SubscriberPackages,
    props: true,
    meta: {
      metrics: {
        event: METRICS_EVENT.PACKAGES_PAGE_VIEWED,
      },
    },
  },
  {
    path: ":platform/:name",
    props: true,
    component: SubscriberPackageWrapper,
    meta: {
      breadcrumb: {
        title: (params: { name: string }) => params.name,
      },
    },
    children: [
      {
        path: "overview",
        alias: "",
        name: "SubscriberPackage",
        component: SubscriberPackageOverview,
        props: true,
        meta: {
          metrics: { event: METRICS_EVENT.PACKAGE_OVERVIEW_PAGE_VIEWED },
        },
      },
      {
        path: "releases",
        name: "SubscriberPackageReleases",
        component: SubscriberPackageReleases,
        props: true,
        meta: {
          metrics: {
            event: METRICS_EVENT.PACKAGE_RELEASES_PAGE_VIEWED,
          },
        },
      },
      {
        path: "usage",
        name: "SubscriberPackageUsage",
        component: SubscriberPackageUsage,
        props: true,
        meta: {
          metrics: {
            event: METRICS_EVENT.PACKAGE_PROJECT_USAGE_PAGE_VIEWED,
          },
        },
      },
      {
        path: "vulnerabilities",
        component: RouterViewWrapper,
        meta: {
          breadcrumb: { title: "Vulnerabilities" },
        },
        children: [
          {
            path: "",
            name: "SubscriberPackageVulnerabilities",
            component: SubscriberPackageVulnerabilitiesPage,
            props: true,
            meta: {
              metrics: {
                event: METRICS_EVENT.PACKAGE_VULNERABILITIES_PAGE_VIEWED,
              },
            },
          },
          {
            path: ":vulnerabilityId",
            name: "SubscriberPackageVulnerability",
            component: SubscriberPackageVulnerabilityPage,
            props: true,
            meta: {
              breadcrumb: { title: (params: { vulnerabilityId: string }) => params.vulnerabilityId },
              metrics: {
                event: METRICS_EVENT.PACKAGE_CVE_PAGE_VIEWED,
              },
            },
          },
        ],
      },
      {
        path: "quality-assurance-report",
        component: RouterViewWrapperWithBreadcrumb,
        children: [
          {
            path: "",
            name: "SubscriberPackageQualityAssuranceReport",
            component: PackageQualityAssuranceReportPage,
            props: true,
          },
          {
            path: "openssf",
            name: "PackageOpenSSFScorecard",
            component: PackageOpenSSFScorecard,
            props: true,
            meta: {
              breadcrumb: {
                title: "OpenSSF Scorecard",
                beforeRender(breadcrumbs: unknown[]) {
                  breadcrumbs.push({ title: "Quality assurance report", path: `../quality-assurance-report` })
                },
              },
            },
          },
          {
            path: "dependency-issues",
            name: "DependencyIssues",
            component: DependencyIssues,
            props: true,
            meta: {
              breadcrumb: {
                title: "Dependency issues",
                beforeRender(breadcrumbs: unknown[]) {
                  breadcrumbs.push({ title: "Quality assurance report", path: `../quality-assurance-report` })
                },
              },
              metrics: {
                event: METRICS_EVENT.DEPENDENCY_ISSUES_PAGE_VIEWED,
              },
            },
          },
        ],
      },
      {
        path: "dependencies",
        component: Dependencies,
        name: "SubscriberPackageDependencies",
        props: true,
        meta: {
          metrics: {
            event: METRICS_EVENT.PACKAGE_DEPENDENCIES_PAGE_VIEWED,
          },
        },
      },
      {
        path: "attestation",
        component: PackageAttestation,
        name: "SubscriberPackageAttestation",
        props: true,
        meta: {
          metrics: {
            event: METRICS_EVENT.PACKAGE_ATTESTATION_PAGE_VIEWED,
          },
        },
      },
      {
        path: "settings",
        component: SubscriberPackageSettings,
        name: "SubscriberPackageSettings",
        meta: {
          breadcrumb: { title: "Settings" },
        },
        props: true,
      },
    ],
  },
]

const reportsRoute: RouteRecordRaw[] = [
  {
    path: "",
    name: "Reports",
    component: Reports,
    props: true,
    meta: {
      metrics: {
        event: METRICS_EVENT.REPORTS_PAGE_VIEWED,
      },
    },
  },
]

const maintainerImpactRoute: RouteRecordRaw[] = [
  {
    path: "",
    name: "MaintainerImpact",
    component: MaintainerImpact,
    props: true,
    meta: {
      metrics: {
        event: METRICS_EVENT.MAINTAINER_IMPACT_PAGE_VIEWED,
      },
    },
  },
]

const routes: RouteRecordRaw[] = [
  {
    path: "/subscriber",
    component: SubscriberWrapper,
    meta: {
      // Set this rounting prefix for all subscriber routes.
      routingPrefix: "SubscriberCatalogLifter",
      userRole: "Subscriber",
    },
    children: [
      {
        path: "",
        name: "SubscriberRedirect",
        component: SubscriberRedirect,
        props: ({ query }) => query,
      },
      {
        path: "extension-setup",
        redirect: to => {
          return { name: "SubscriberRedirect", query: { next: "ExtensionSetup" } }
        },
      },
      {
        path: "trial-ended",
        name: "TrialEnded",
        component: TrialEnded,
      },
      {
        path: "subscription-ended",
        name: "SubscriptionEnded",
        component: SubscriptionEnded,
      },
      {
        path: "select",
        name: "SubscriberSelectTeam",
        component: SubscriberSelectTeam,
        props: true,
      },
      {
        path: "userkey",
        name: "UserKey",
        redirect: to => {
          return { name: "SubscriberSelectTeam", query: { next: "ApiKeys" } }
        },
      },
      {
        path: "slack_user_link",
        name: "SlackConnect",
        component: SlackConnect,
      },
      {
        path: "getstarted",
        component: RouterViewWrapper,
        children: getStartedRoutes,
      },
      {
        path: ":repoType",
        component: RouterViewWrapper,
        children: [
          {
            path: "",
            component: RouterViewWrapper,
            children: teamRoutes,
            // Only allow navigation to these routes when the repoType is `team`.
            beforeEnter(to, from, next) {
              if (to.params.repoType !== "team") {
                next(false)
              } else {
                next()
              }
            },
          },
          {
            path: ":organization",
            component: RouterViewWrapper,
            children: [
              {
                path: "",
                redirect: { name: "SubscriberCatalogs" },
              },
              {
                path: "code",
                redirect: { name: "Repositories" },
              },
              {
                path: "repositories",
                redirect: { name: "Repositories" },
              },
              {
                path: "projects",
                component: RouterViewWrapper,
                children: repoRoutes,
              },
              {
                path: "repositories/*",
                redirect: to => {
                  const { pathMatch } = to.params
                  return "projects/" + pathMatch
                },
              },
              {
                path: "settings",
                component: RouterViewWrapper,
                children: settingRoutes,
              },
              {
                path: "catalogs",
                component: RouterViewWrapper,
                children: catalogRoutes,
              },
              {
                path: "packages",
                component: RouterViewWrapper,
                children: ReleaseRoutes,
              },
              {
                path: "reports",
                component: RouterViewWrapper,
                children: reportsRoute,
              },
              {
                path: "maintainer-impact",
                component: RouterViewWrapper,
                children: maintainerImpactRoute,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
