<template>
  <svg
    viewBox="-10 -10 440 440"
    class="sl"
    :class="size"
  >
    <g fill="transparent">
      <rect
        y="80"
        width="200"
        height="24"
        rx="5"
      >
        <animate
          id="sl_line1"
          v-bind="blueLineAnimValues"
          begin="0s;sl_lift3.end+200ms"
        />
      </rect>
      <rect
        y="128"
        width="200"
        height="24"
        rx="5"
      >
        <animate
          id="sl_line2"
          v-bind="blueLineAnimValues"
          begin="sl_line1.begin+200ms"
        />
      </rect>
      <rect
        y="176"
        width="200"
        height="24"
        rx="5"
      >
        <animate
          id="sl_line3"
          v-bind="blueLineAnimValues"
          begin="sl_line2.begin+200ms"
        />
      </rect>
    </g>
    <g>
      <path
        fill="#8292ac"
        d="M 0 220 A 200 200, 0 0 0, 58.5786 361.4214 L 200 220 Z"
      />
      <path
        fill="#626980"
        d="M 58.5786 361.4214 A 200 200, 0 0 0, 200 420 L 200 220 Z"
      />
      <animateTransform
        id="sl_sub"
        v-bind="shakeAnimValues"
        values="0 0; -7 0; 1 1; -7 -6; -8 -8; -4 6; -7 -3; 10 9; -9 8; -4 0; 0 0;"
        begin="sl_line3.begin+100ms"
      />
    </g>
    <path
      fill="#ffab4d"
      d="M 220 420 A 200 200, 0 0 0, 420 220 L 220 220 Z"
    >
      <animateTransform
        id="sl_lift"
        v-bind="shakeAnimValues"
        values="0 0; -4 0; -7 4; -7 2; 10 -9; 9 -1; -6 -5; -2 8; -3 5; 4 1; 0 0;"
        begin="sl_sub.begin+500ms"
      />
    </path>
    <path
      class="sl-orange-line"
      d="M 220 10 A 190 190, 0 0 1, 410 200"
    >
      <animate
        id="sl_lift3"
        v-bind="orangeLineAnimValues"
        begin="sl_lift2.begin+200ms"
      />
    </path>
    <path
      class="sl-orange-line"
      d="M 220 45 A 155 155, 0 0 1, 375 200"
    >
      <animate
        id="sl_lift2"
        v-bind="orangeLineAnimValues"
        begin="sl_lift1.begin+200ms"
      />
    </path>
    <path
      class="sl-orange-line"
      d="M 220 80 A 120 120, 0 0 1, 340 200"
    >
      <animate
        id="sl_lift1"
        v-bind="orangeLineAnimValues"
        begin="sl_lift.begin+500ms"
      />
    </path>
  </svg>
</template>

<style
  lang="scss"
  scoped
>
.sl-orange-line {
  fill: none;
  stroke: transparent;
  stroke-width: 20;
  stroke-linecap: butt;
}

.sl {
  &.xs {
    width: 3rem;
  }

  &.sm {
    width: 5rem;
  }

  &.md {
    width: 8rem;
  }

  &.lg {
    width: 13rem;
  }
}
</style>

<script>
export default {
  name: "Spinner",
  status: "ready",
  props: {
    size: {
      type: String,
      validator(size) {
        return ["xs", "sm", "md", "lg"].includes(size)
      },
      default: "md",
    },
  },
  computed: {
    blueLineAnimValues() {
      return {
        attributeName: "fill",
        keyTimes: "0; .3; .7; 1",
        values: "transparent; #4b5168; #4b5168; transparent",
        dur: "1s",
      }
    },
    orangeLineAnimValues() {
      return {
        attributeName: "stroke",
        keyTimes: "0; .3; .7; 1",
        values: "transparent; #f6914d; #f6914d; transparent",
        dur: "1s",
      }
    },
    shakeAnimValues() {
      return {
        attributeName: "transform",
        type: "translate",
        keyTimes: "0; .1; .2; .3; .4; .5; .6; .7; .8; .9; 1",
        dur: "1s",
        repeatCount: "0",
      }
    },
  },
}
</script>
