import { useAuthStore } from "@/store/pinia/auth"
import { toRef, watch } from "vue"

/**
 * Namespaces are only value for authed users.
 * Returns a promise that will resolve when the user authenticates
 */
function getNamespace() {
  const authStore = useAuthStore()
  return new Promise((resolve, reject) => {
    let stopwatching = () => {}
    stopwatching = watch(
      toRef(authStore, "user"),
      user => {
        if (user) {
          resolve(`tl/${user.user_id}`)
          stopwatching()
        }
      },
      { immediate: true }
    )
  })
}

export default {
  async getItem(key: string) {
    const ns = await getNamespace()
    return localStorage.getItem(`${ns}/${key}`)
  },
  async setItem(key: string, value: string) {
    const ns = await getNamespace()
    return localStorage.setItem(`${ns}/${key}`, value)
  },
  async removeItem(key: string) {
    const ns = await getNamespace()
    return localStorage.removeItem(`${ns}/${key}`)
  },
}
