<template>
  <div
    :class="classes"
    class="tl-page-header container is-fluid px-6 py-5"
  >
    <div class="is-flex is-flex-direction-row is-align-items-center is-flex-grow-1">
      <slot name="title">
        <div>
          <h2
            v-if="subtitle"
            :class="['tl-page-header__subtitle', props.type]"
          >
            {{ subtitle }}
          </h2>
          <h1 class="tl-page-header__title">
            {{ title || "&nbsp;" }}
          </h1>
        </div>
      </slot>
      <slot name="actions" />
    </div>
    <slot name="subtitle" />
  </div>
</template>

<script
  lang="ts"
  setup
>
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    title?: string
    type?: "catalog" | "project" | "package" | "basic"
    platform?: string
    parent?: boolean
  }>(),
  {
    title: "",
    type: "basic",
    platform: "",
    parent: false,
  }
)

const classes = computed(() => {
  const classes: string[] = [props.type]
  if (props.type !== "basic" && !props.parent) {
    classes.push("primary")
  } else {
    classes.push("parent")
  }
  return classes
})

const subtitle = computed(() => {
  if (props.parent) return ""
  switch (props.type) {
    case "catalog":
      return "Catalog"
    case "project":
      return "Project"
    case "package":
      return props.platform
    default:
      return ""
  }
})
</script>

<style lang="scss">
@import "./TlPageHeader";
</style>
