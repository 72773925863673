<template>
  <div class="bar-container">
    <div class="bar is-flex is-align-items-center py-3">
      <RouterLink :to="homeLink">
        <img
          :src="logo"
          class="logo my-2"
          width="131"
        />
      </RouterLink>

      <template v-if="mode === 'Lifter' && user">
        <RouterLink
          :to="{ name: 'LifterTaskPage' }"
          class="nav-link package"
          >Tasks</RouterLink
        >
        <RouterLink
          :to="{ name: 'LifterPackages' }"
          class="nav-link package"
          >Packages</RouterLink
        >
        <RouterLink
          :to="{ name: 'LifterIncome' }"
          class="nav-link package"
          >Income</RouterLink
        >
      </template>
      <template v-if="mode === 'Subscriber' && user">
        <template v-if="!isIntelligenceMode">
          <RouterLink
            :to="{ name: 'SubscriberCatalogs', params: ensureOrgRoute }"
            class="nav-link catalog"
            :class="{ disabled: isOrgRoutingDisabled }"
            >Catalogs</RouterLink
          >
          <RouterLink
            :to="{ name: 'Repositories', params: ensureOrgRoute }"
            class="nav-link project"
            :class="{ disabled: isOrgRoutingDisabled }"
            >Projects</RouterLink
          >
          <RouterLink
            :to="{ name: 'SubscriberPackages', params: ensureOrgRoute }"
            class="nav-link package"
            :class="{ disabled: isOrgRoutingDisabled }"
            >Packages</RouterLink
          >
          <RouterLink
            v-if="catalogReportsForUser.length"
            :to="{
              name: 'Reports',
              params: ensureOrgRoute,
              query: { catalog: catalog?.name || currentTeam?.default_catalog },
            }"
            class="nav-link report"
            :class="{ disabled: isOrgRoutingDisabled }"
            >Reports</RouterLink
          >
        </template>
        <RouterLink
          v-if="features.isEnabled('show_maintainer_impact')"
          :to="{
            name: 'MaintainerImpact',
            params: ensureOrgRoute,
          }"
          class="nav-link maintainer-impact"
          :class="{ disabled: isOrgRoutingDisabled }"
          >Maintainer impact</RouterLink
        >
      </template>
      <template v-if="mode === 'Global'">
        <RouterLink
          v-if="isLifter"
          :to="{ name: 'LifterTaskPage' }"
          class="nav-link package"
          >Lifter App</RouterLink
        >
        <RouterLink
          v-if="isSubscriber"
          :to="{ name: 'SubscriberRedirect' }"
          class="nav-link package"
          >Subscriber App
        </RouterLink>
      </template>

      <div class="is-flex-grow-1" />

      <div
        class="is-flex is-align-items-center"
        v-if="user"
      >
        <ODropdown
          position="bottom-left"
          class="nav-dropdown mr-2"
          :triggers="['click']"
        >
          <template #trigger>
            <i class="fas fa-question-circle fa-lg is-clickable" />
          </template>
          <div class="dropdown-header p-3 is-flex is-align-items-center has-text-weight-semibold">
            Help
            <div class="is-flex-grow-1" />
            <i class="fas fa-question-circle fa-sm" />
          </div>
          <div class="px-4 py-2">
            <ODropdownItem
              tag="div"
              value="docs"
            >
              <a :href="mode === 'Lifter' ? urls.lifterDocs : urls.subscriberDocs"> Documentation </a>
            </ODropdownItem>

            <ODropdownItem
              tag="div"
              value="contact"
            >
              <a :href="mode === 'Lifter' ? urls.createSupportTicketLifter : urls.createSupportTicketSubscriber">
                Contact support
              </a>
            </ODropdownItem>

            <ODropdownItem
              v-if="mode === 'Lifter'"
              tag="div"
              value="slack"
            >
              <RouterLink :to="{ name: 'LifterSlack' }"> Lifter Slack </RouterLink>
            </ODropdownItem>

            <ODropdownItem
              tag="div"
              value="statis"
            >
              <a
                :href="urls.statusPage"
                target="_blank"
                rel="noopener"
              >
                System status
              </a>
            </ODropdownItem>

            <ODropdownItem
              v-if="mode === 'Subscriber' && isAdmin"
              tag="div"
              value="statis"
              @click="showDebugModal = true"
            >
              <a>Debug info</a>
            </ODropdownItem>
          </div>
        </ODropdown>

        <ODropdown
          position="bottom-left"
          class="nav-dropdown"
          closeOnClick
          :triggers="['click']"
        >
          <template #trigger>
            <div class="is-flex is-clickable is-align-items-center">
              <img
                v-if="profilePicture"
                :src="profilePicture"
                class="user-image"
              />
              <img
                v-else
                :src="tideliftAvatarUrl"
                class="user-image"
              />
              <div class="px-2">
                <div>
                  {{ mode === "Subscriber" ? currentTeam?.display_name || ensureOrgRoute.organization : user?.name }}
                </div>
                <div
                  v-if="mode === 'Subscriber'"
                  class="has-text-weight-light is-size-6 profile-name"
                >
                  {{ user?.name }}
                </div>
              </div>
            </div>
          </template>
          <div>
            <div class="is-flex is-align-items-center px-4 py-2 dropdown-header">
              <img
                v-if="profilePicture"
                :src="profilePicture"
                class="user-image"
              />
              <img
                v-else
                :src="tideliftAvatarUrl"
                class="user-image"
              />
              <div class="px-2">
                <div>{{ mode === "Subscriber" ? currentTeam?.display_name : user?.name }}</div>
                <div
                  v-if="mode === 'Subscriber'"
                  class="has-text-weight-light is-size-6 profile-name"
                >
                  {{ user?.name }}
                </div>
              </div>
            </div>
            <div class="p-4">
              <p class="has-text-weight-normal is-uppercase is-size-7 has-text-grey-dark mb-2 ml-1">Your settings</p>

              <ODropdownItem
                v-if="mode === 'Lifter' && isSubscriber"
                tag="div"
                value="lifter"
              >
                <RouterLink :to="{ name: 'SubscriberRedirect' }"> Switch to subscriber view </RouterLink>
              </ODropdownItem>

              <ODropdownItem
                v-if="mode === 'Subscriber' && isLifter"
                tag="div"
                value="subscriber"
              >
                <RouterLink :to="{ name: 'LifterTaskPage' }"> Switch to lifter view </RouterLink>
              </ODropdownItem>

              <ODropdownItem
                v-if="(teams?.length || 0) > 1 && mode === 'Subscriber'"
                tag="div"
                value="switchteam"
              >
                <RouterLink :to="{ name: 'SubscriberSelectTeam' }"> Switch organization </RouterLink>
              </ODropdownItem>

              <ODropdownItem
                v-if="identityProviders.includes('auth0')"
                class="change-password"
                tag="div"
              >
                <RouterLink :to="{ name: 'ForgotPassword' }"> Change your password </RouterLink>
              </ODropdownItem>

              <template
                v-for="provider of identityProviders"
                :key="provider"
              >
                <ODropdownItem
                  v-if="provider !== 'auth0'"
                  class="has-text-weight-normal"
                  tag="div"
                >
                  <NewTabLink
                    anchor
                    v-if="IDENTITY_PROVIDER_DATA[provider]"
                    :link="IDENTITY_PROVIDER_DATA[provider].settingsUrl"
                    >Signed in with {{ IDENTITY_PROVIDER_DATA[provider].name }}</NewTabLink
                  >
                  <a
                    class="disabled"
                    v-else
                    >Signed in with SSO</a
                  >
                </ODropdownItem>
              </template>

              <hr class="my-3" />

              <template v-if="mode === 'Subscriber'">
                <p class="has-text-weight-normal is-uppercase is-size-7 has-text-grey-dark mb-2 ml-1">
                  Organization settings
                </p>
                <RequirePolicy
                  scope="team"
                  policy="users.view"
                >
                  <ODropdownItem
                    tag="div"
                    value="users"
                  >
                    <RouterLink
                      :class="{ disabled: isOrgRoutingDisabled }"
                      :to="{ name: 'Users', params: ensureOrgRoute }"
                    >
                      Users
                    </RouterLink>
                  </ODropdownItem>
                </RequirePolicy>

                <ODropdownItem
                  v-if="policy.check('team', policy.all.view_groups) && !isIntelligenceMode"
                  tag="div"
                  value="groups"
                >
                  <RouterLink
                    :class="{ disabled: isOrgRoutingDisabled }"
                    :to="{ name: 'Groups', params: ensureOrgRoute }"
                  >
                    Groups
                  </RouterLink>
                </ODropdownItem>

                <ODropdownItem
                  tag="div"
                  value="apikeys"
                >
                  <RouterLink
                    :class="{ disabled: isOrgRoutingDisabled }"
                    :to="{ name: 'ApiKeys', params: ensureOrgRoute }"
                  >
                    API keys
                  </RouterLink>
                </ODropdownItem>

                <ODropdownItem
                  v-if="!isIntelligenceMode"
                  tag="div"
                  value="integration"
                >
                  <RouterLink
                    :class="{ disabled: isOrgRoutingDisabled }"
                    :to="{ name: 'Integrations', params: ensureOrgRoute }"
                  >
                    Integrations
                  </RouterLink>
                </ODropdownItem>

                <hr class="my-3" />
              </template>

              <button
                class="button secondary is-block my-1"
                style="width: 100%"
                @click="logout"
              >
                Log Out
              </button>
            </div>
          </div>
        </ODropdown>
      </div>
      <div v-else-if="loaded">
        <RouterLink
          :to="{ name: 'Login' }"
          class="button secondary"
        >
          Log In
        </RouterLink>
      </div>
    </div>
    <Modal
      :closeModal="
        () => {
          showDebugModal = false
        }
      "
      title="Debug Info"
      :isActive="showDebugModal"
      :cancelOptions="['escape', 'outside', 'button']"
    >
      <template #content>
        <div v-if="currentOrg">
          <Heading :level="2">Organization</Heading>
          <div class="columns is-multiline is-gapless">
            <div class="column is-one-quarter">Name:</div>
            <div class="column is-three-quarters">
              <span class="mr-4">{{ currentOrg.name }}</span>
              <a :href="`/admin/teams/${currentOrg.id}`">jump to admin</a>
            </div>
            <div class="column is-one-quarter">Display Name:</div>
            <div class="column is-three-quarters">
              {{ currentOrg.display_name }}
            </div>
            <div class="column is-one-quarter">Type:</div>
            <div class="column is-three-quarters">
              {{ currentOrg.type === "GcsRepository" ? "Team" : currentOrg.type }}
            </div>
            <div class="column is-one-quarter">Id:</div>
            <div class="column is-three-quarters">
              {{ currentOrg.id }}
              <i
                @click="copyCurrentOrgID"
                title="Copy the current org ID to your clipboard"
                class="fas fa-clipboard is-clickable"
              />
            </div>
            <div class="column is-one-quarter">Roles:</div>
            <div class="column is-three-quarters">
              {{ currentOrg.roles }}
            </div>
            <div class="column is-one-quarter">Policies:</div>
            <div class="column is-three-quarters">
              <ul>
                <li
                  v-for="policy in formatPolicies(currentOrg.policies)"
                  :key="policy"
                >
                  {{ policy }}
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div v-if="catalog">
          <Heading :level="2">Catalog</Heading>
          <div class="columns is-multiline is-gapless">
            <div class="column is-one-quarter">Name:</div>
            <div class="column is-three-quarters">
              <span class="mr-4">{{ catalog.name }}</span>
              <a :href="`/admin/catalogs/${catalog.id}`">jump to admin</a>
            </div>
            <div class="column is-one-quarter">Display Name:</div>
            <div class="column is-three-quarters">
              {{ catalog.display_name }}
            </div>
            <div class="column is-one-quarter">Id:</div>
            <div class="column is-three-quarters">
              {{ catalog.id }}
              <i
                @click="copyCurrentCatalogID"
                title="Copy the current catalog ID to your clipboard"
                class="fas fa-clipboard is-clickable"
              />
            </div>
            <div class="column is-one-quarter">Roles:</div>
            <div class="column is-three-quarters">
              {{ catalog.roles }}
            </div>
            <div class="column is-one-quarter">Policies:</div>
            <div class="column is-three-quarters">
              <ul>
                <li
                  v-for="policy in formatPolicies(catalog.policies)"
                  :key="policy"
                >
                  {{ policy }}
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div v-if="currentRepo">
          <Heading :level="2">Project</Heading>
          <div class="columns is-multiline is-gapless">
            <div class="column is-one-quarter">Name:</div>
            <div class="column is-three-quarters">
              {{ currentRepo.name }}
            </div>
            <div class="column is-one-quarter">Id:</div>
            <div class="column is-three-quarters">
              {{ currentRepo.id }}
              <i
                @click="copyCurrentRepoID"
                title="Copy the current repository ID to your clipboard"
                class="fas fa-clipboard is-clickable"
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import tideliftLogoUrl from "@/assets/logos/Tidelift-logo-on-dark.svg"
import { useAuth, useAuthLinks } from "@/services/auth"
import { useUserIdentity } from "@/services/userIdentity"
import config from "@/config"
import urls from "@/configs/urls"
import { IDENTITY_PROVIDER_DATA } from "@/components/scaffold/copy"
import { useTeamStore } from "@/store/pinia/teams"
import { useCatalogsStore } from "@/store/pinia/catalogs"
import { storeToRefs } from "pinia"
import teamsHandler from "@/mixins/FetchTeamsMixin"
import NewTabLink from "../pages/catalogs/catalog_tasks/NewTabLink.vue"
import { useClipboard } from "@vueuse/core"
import { common } from "@/services/notification"
import { useRepositoryStore } from "@/store/pinia/repository"
import { usePolicyStore } from "@/store/pinia/policy"
import { useReports } from "@/components/pages/subscriber/reports"
import { useFeatures } from "@/services/features"

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<"Lifter" | "Subscriber" | "Global">,
      default: "lifter",
    },
  },
  setup() {
    const { user, isLifter, isSubscriber, loaded, logout, isAdmin } = useAuth()
    const { profilePicture, identityProviders } = useUserIdentity()
    const { homeLink } = useAuthLinks()
    const { currentTeam, teams } = storeToRefs(useTeamStore())
    const { repoRoute, catalog } = storeToRefs(useCatalogsStore())
    const ensureOrgRoute = computed(() => ({
      repoType: repoRoute.value.repoType || "team",
      organization: repoRoute.value.organization || "loading...",
    }))
    const isOrgRoutingDisabled = computed(() => !(repoRoute.value.repoType && repoRoute.value.organization))
    const { isTeamAdministrator } = teamsHandler()
    const policy = usePolicyStore()
    const isIntelligenceMode = computed(() => policy.check("team", policy.all.intelligence_mode) || false)

    /** Debug toolbar */
    const showDebugModal = ref(false)
    const { currentOrg } = teamsHandler()
    const { copy: _copyCurrentOrgID, copied: currentOrgIDCopied } = useClipboard({
      source: computed(() => currentOrg.value?.id || ""),
    })
    const copyCurrentOrgID = async () => {
      await _copyCurrentOrgID()

      if (currentOrgIDCopied.value) {
        common.clipboard.success()
      } else {
        common.clipboard.failure()
      }
    }
    const { repos } = storeToRefs(useRepositoryStore())
    const currentRepo = computed(() => Object.values(repos.value).find(r => r?.name === repoRoute.value))
    const currentRepoID = computed(() => currentRepo.value?.id.toString() || "")
    const { copy: _copyCurrentRepoID, copied: currentRepoIDCopied } = useClipboard({ source: currentRepoID })
    const copyCurrentRepoID = async () => {
      await _copyCurrentRepoID()
      if (currentRepoIDCopied.value) {
        common.clipboard.success()
      } else {
        common.clipboard.failure()
      }
    }
    const currentCatalogID = computed(() => catalog.value?.id || "")
    const { copy: _copyCurrentCatalogID, copied: currentCatalogIDCopied } = useClipboard({ source: currentCatalogID })
    const copyCurrentCatalogID = async () => {
      await _copyCurrentCatalogID()
      if (currentCatalogIDCopied.value) {
        common.clipboard.success()
      } else {
        common.clipboard.failure()
      }
    }

    function formatPolicies(policies: Record<string, Record<string, boolean>>) {
      return Object.entries(policies)
        .map(([scope, rules]) => {
          return Object.entries(rules).map(([rule, value]) => `${scope}.${rule}: ${value}`)
        })
        .flat()
    }

    const { catalogReportsForUser } = useReports(ensureOrgRoute.value)

    return {
      catalog,
      currentOrg,
      currentRepo,
      copyCurrentOrgID,
      copyCurrentRepoID,
      copyCurrentCatalogID,
      formatPolicies,
      identityProviders,
      IDENTITY_PROVIDER_DATA,
      isAdmin,
      isIntelligenceMode,
      isTeamAdministrator,
      isLifter,
      isOrgRoutingDisabled,
      isSubscriber,
      homeLink,
      urls,
      user,
      loaded,
      teams,
      currentTeam,
      profilePicture,
      ensureOrgRoute,
      logo: tideliftLogoUrl,
      tideliftAvatarUrl: config.tideliftAvatarUrl,
      showDebugModal,
      logout,
      policy,
      catalogReportsForUser,
      features: useFeatures(),
    }
  },
  components: { NewTabLink },
})
</script>

<style
  lang="scss"
  scoped
>
$bar-height: 65px;
$bar-padding: 30px;
$image-size: 34px;

.bar-container {
  height: $bar-height;

  @include mobile {
    height: initial;
  }
}

.logo {
  margin-right: 64px;
}

/** Navbar CSS */
.bar {
  background-color: $color-tidelift_blue_700;
  color: $color-white;
  font-weight: bold;
  height: $bar-height;
  justify-content: space-between;
  padding: 0 $bar-padding;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;

  /* above table dimming planes and app dialogs, below fullscreen modals */
  z-index: 31;

  /* position: fixed; */

  a.nav-link {
    border-bottom: 2px solid transparent;
    color: $color-white !important;
    margin-right: 3rem;
    padding: 4px 0;

    @include mobile {
      margin-bottom: $space-x-small;
    }

    /* highlight colors */
    &.catalog {
      &:hover,
      &.is-active,
      &.router-link-active {
        border-bottom: 2px solid $color-concept-catalog;
      }
    }

    &.project {
      &:hover,
      &.is-active,
      &.router-link-active {
        border-bottom: 2px solid $color-concept-project;
      }
    }

    &.package {
      &:hover,
      &.is-active,
      &.router-link-active {
        border-bottom: 2px solid $color-concept-package;
      }
    }

    &.report {
      &:hover,
      &.is-active,
      &.router-link-active {
        border-bottom: 2px solid $color-concept-report;
      }
    }

    &.maintainer-impact {
      &:hover,
      &.is-active,
      &.router-link-active {
        border-bottom: 2px solid $color-concept-maintainer-impact;
      }
    }
  }

  .user-image {
    border-radius: $image-size;
    height: $image-size;
    width: $image-size;
  }

  .profile-name {
    margin-top: -2px;
  }

  .profile-dividing-border {
    border-left: 1px $color-tidelift_blue_400;
  }

  @include mobile {
    height: initial;
    position: relative;
    top: initial;
    right: initial;
    left: initial;
    flex-direction: column;
    align-items: flex-start !important;
  }
}
</style>

<style lang="scss">
$bar-height: 65px;

/** Unscoped styles to hack into ODropdown internals */
.nav-dropdown {
  height: $bar-height;
  display: flex;
  align-items: center;

  &:last-of-type .dropdown-trigger {
    padding-left: 16px;
    border-left: 0.5px solid $color-tidelift_blue;
  }

  /** These class names are given by ODropdown */
  .dropdown-menu.dropdown-content {
    border: 1px solid $color-tidelift-blue-300;
    top: calc($bar-height - 8px);
    color: $color-tidelift-blue-700;
    background-color: $color-tidelift-blue-50;
    padding: 0;

    .dropdown-header {
      background-color: $color-tidelift-blue-100;
      white-space: nowrap;
      margin-right: 40px;
      width: 100%;
    }

    .dropdown-item {
      padding: 0;
      margin: 4px 0;

      a {
        font-size: 1rem;
        border-radius: 4px;
        white-space: nowrap;
        padding: 3px 6px;
        color: $color-tidelift-blue-700;
        font-weight: normal;
        display: block;

        &.is-active {
          background-color: $color-tidelift-blue-100;
        }

        &.disabled {
          cursor: initial;
          color: $color-tidelift-blue-500;

          &:hover {
            background-color: initial;
          }
        }

        &:hover {
          background-color: $color-tidelift-blue-100;
        }
      }
    }
  }
}
</style>
