<template>
  <Transition name="fade">
    <div v-if="showNotification">
      <slot
        name="default"
        v-bind="{ hide }"
      />
    </div>
  </Transition>
</template>

<script
  setup
  lang="ts"
>
import { computed, ref } from "vue"

const props = defineProps<{
  /**
   * The localStorage item key to use to store the current revision number.
   */
  notificationKey: string
  /**
   * The revision of the notification. Increase it to force showing
   * this notification to users again. Best used after content updates.
   */
  revision: number
}>()

const storageKey = computed(() => `notification/${props.notificationKey}`)
const showNotification = ref(Number(window.localStorage.getItem(storageKey.value) || 0) < props.revision)

function hide() {
  window.localStorage.setItem(storageKey.value, props.revision.toString())
  showNotification.value = false
}
</script>

<style
  lang="scss"
  scoped
>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.fade-leave-to,
.fade-enter {
  opacity: 0;
}

.reopener {
  float: right;

  &.fade-leave-active,
  &.fade-enter-active {
    transition: opacity 0s;
  }

  &.fade-enter-to,
  &.fade-leave-to {
    opacity: 0;
  }
}

.dismiss-icon {
  position: absolute;
  right: 0.75rem;
  top: 0.55rem;
}
</style>
