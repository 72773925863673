// Plugin to expose some information about feature flags so that we can
// make functionality conditionally available.
import { useFeatureStore } from "@/store/pinia/features"

export function useFeatures() {
  return {
    isEnabled(...featureNames: string[]) {
      return useFeatureStore().isFeatureEnabled(...featureNames)
    },
    isEnabledForTeam(team_name: string, ...featureNames: string[]) {
      return useFeatureStore().isFeatureEnabledForTeam(team_name, ...featureNames)
    },
  }
}

export default {
  install: function (app: any) {
    app.config.globalProperties.$features = useFeatures()
  },
}
