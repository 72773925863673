<template>
  <a
    v-if="anchor && typeof link === 'string'"
    class="break-word"
    :class="extraClasses"
    :href="link"
    target="_blank"
    rel="noopener noreferrer"
    :aria-label="ariaLabel"
  >
    <slot />{{ " " }}
    <i
      v-if="!noIcon"
      class="fas fa-external-link-alt"
    />
  </a>
  <RouterLink
    v-else-if="routerLink"
    :to="link"
    :class="extraClasses"
    target="_blank"
  >
    <slot />{{ " " }}
    <i class="fas fa-external-link-alt" />
  </RouterLink>
</template>

<script lang="ts">
// The forced spaces in the template are to ensure that
// there is space in between the slotted element and the
// FontAwesome tag. It seems that Vue will deliver text
// content differently to slots depending on if the slot
// uses template tags in it or not. If there are template
// tags, extra space at the end of the slot content will be
// correctly collapsed. Plaintext slot content will retain
// any surrounding space around the text.
//
// By always adding a space, the browser will collapse
// all extra spaces to one space, and the icon will
// be positioned away correctly. Otherwise, we'd have to
// write every plaintext NewTabLink so that the text
// content is directly against the NewTabLink tags:
//
// <NewTabLink
//    ...>plaintext</NewTabLink>
import { defineComponent, onMounted, PropType } from "vue"

export const newTabLinkHandler = (props: any) => {
  const checkLinkType = () => {
    if (!props.anchor && !props.routerLink) {
      throw new Error('NewTabLink must include either an "anchor" or a "routerLink" property.')
    }
  }

  onMounted(() => {
    checkLinkType()
  })

  return {
    checkLinkType,
  }
}

export default defineComponent({
  name: "NewTabLink",
  props: {
    // must include either 'anchor' or 'routerLink' property
    anchor: Boolean,
    routerLink: Boolean,
    /** Either a string IF anchor or an object IF routerLink */
    link: { type: [String, Object] as PropType<string | object>, required: true },
    ariaLabel: String,
    extraClasses: { type: [Array, Object] as PropType<string[] | Record<string, boolean>>, default: () => [] },
    noIcon: { type: Boolean, default: false },
  },
  setup(props: any) {
    return {
      ...newTabLinkHandler(props),
    }
  },
})
</script>

<style
  lang="scss"
  scoped
>
.break-word {
  word-break: break-word;
}
</style>
