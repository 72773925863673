<template>
  <div class="content empty-state">
    <section class="empty-content">
      <img
        v-if="type !== 'error'"
        :src="image"
        alt="Empty state"
      />
      <i
        v-else
        class="fas fa-times-circle fa-8x is-red"
      />
      <h1 class="is-size-4"><slot name="headline" /></h1>
      <p class="is-size-7">
        <slot name="description" />
      </p>
      <div class="cta">
        <slot name="cta" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import defaultSvg from "@/dragonforce/assets/images/empty-state.svg"
import successSvg from "@/dragonforce/assets/images/ta-da.svg"
import searchSvg from "@/dragonforce/assets/images/empty-search.svg"
/**
 * This is a structured pattern to use for a full page message when there is no content to show and give the user a call to action to create some content.
 */
export default defineComponent({
  data() {
    return {
      images: {
        default: defaultSvg,
        success: successSvg,
        search: searchSvg,
        error: "",
      },
    }
  },
  props: {
    type: {
      type: String as PropType<"success" | "search" | "error" | "default">,
      default: "default",
    },
  },
  computed: {
    image() {
      return this.images[this.type]
    },
  },
})
</script>

<style
  scoped
  lang="scss"
>
.empty-state {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: $space-x-large;
}

.empty-content {
  margin: 0 auto;
  text-align: center;
  max-width: 34rem;

  &:nth-of-type(1) {
    margin-bottom: auto;
  }
}

.cta {
  margin-top: $space-large;
}

.is-red {
  color: $color-red-500;
}
</style>
