<template>
  <span class="datepicker-container">
    <template v-if="label">
      <label
        class="datepicker-label"
        :for="id"
        >{{ label }}</label
      >
    </template>
    <OField>
      <ODatepicker
        :id="id"
        ref="datepicker"
        v-bind="$attrs"
        :firstDayOfWeek="0"
        :yearsRange="yearsRange"
        :disabled="disabled"
        editable
        iconPack="far"
        icon="calendar"
        iconPrev="angle-left fas"
        iconNext="angle-right fas"
        placeholder="Select date"
        aria-next-label="Next month"
        aria-previous-label="Previous Month"
        trapFocus
      >
        <template #footer>
          <slot />
        </template>
      </ODatepicker>
    </OField>
  </span>
</template>

<script
  setup
  lang="ts"
>
import { computed } from "vue"

const props = defineProps<{
  label?: string
  id?: string
  disabled?: boolean
}>()

const yearsRange = computed(() => [0, 5])
</script>

<style lang="scss">
// This whole unscoped block is a dark-mode styling workaround for v-deep & themify not working together

.theme-dark .datepicker {
  // calendar background color
  .dropdown-content,
  .dropdown-item {
    background-color: $color-tidelift-blue-600 !important;
  }

  // next / previous month icon & "day of the week" cells
  .datepicker-header {
    .has-text-primary,
    .datepicker-cell {
      color: $color-white !important;
    }
  }

  // date cells
  .datepicker-table .datepicker-body .datepicker-cell {
    &.is-selectable {
      color: $color-white;

      &:hover {
        background-color: $color-tidelift-blue-200;
        color: $color-dark-text;
      }
    }

    &.is-selected {
      background-color: $color-white;
      color: $color-dark-text;
    }
  }
}
</style>

<style
  lang="scss"
  scoped
>
:deep(.datepicker .dropdown) {
  /** Override global dragonforce dropdown style */
  .dropdown-content .dropdown-item {
    background-color: $color-white;
  }

  .datepicker-header .select {
    &::after {
      border-color: $color-dark-text;
    }
  }
}

.datepicker-container {
  display: flex;
  flex-direction: column;
}

.datepicker-label {
  padding-bottom: $space-x-small;
  font-size: $font-size-base;
}
</style>
