const urls = {
  apiKeys: "api-keys",
  artifactoryInstance: ":repoType/:organization/artifactory_instance/",
  artifactoryInstanceStatus: ":repoType/:organization/artifactory_instance/:id/status",
  artifactoryInstanceRefreshStatus: ":repoType/:organization/artifactory_instance/:id/refresh_status",
  artifactoryInstanceConfig: ":repoType/:organization/artifactory_instance/:id/webhook.config.json",
  artifactoryRepository: ":repoType/:organization/artifactory_repositories/",
  awsMarketplaceSetup: ":orgType/:team/aws_marketplace_setup/:subId",
  bulkLiftPackages: "lifting/bulk",
  catalog: ":repoType/:organization/catalogs/:catalogName",
  catalogOverview: ":repoType/:organization/catalogs/:catalogName/overview",
  catalogTasksByGroup: ":repoType/:organization/catalogs/:catalogName/task_count_by_group",
  catalogAlignment: "catalog_alignment/:scanId",
  catalogAlignmentReleases: "catalog_alignment/:scanId/status/:status",
  catalogImportFromNewProject: "projects/:organization/:name/create_scan_and_import",
  catalogImportScansToCatalog: ":repoType/:organization/catalogs/:catalogName/import_scans",
  lifterTaskCountsByStandard: "lifter_catalog_tasks/package_counts_by_standard",
  lifterPackagesForStandard: "lifter_catalog_tasks/packages_for_standard/:catalogStandard",
  catalogPackageUsageV2: ":repoType/:organization/catalogs/:catalogName/packages/:platform/:name/package_usage",
  catalogReleasePackage: ":repoType/:organization/catalogs/:catalogName/packages/:platform/:packageName",
  catalogReleasesWithLicense: ":repoType/:organization/catalogs/:catalogName/releases/with_license/:license",
  catalogReports: ":repoType/:organization/catalogs/:catalogName/reports",
  teamReports: ":repoType/:organization/reports",
  catalogReportsGenerate: ":repoType/:organization/catalogs/:catalogName/reports/generate/:reportType",
  catalogLicenseCounts: ":repoType/:organization/catalogs/:catalogName/license_counts",
  catalogRules: ":repoType/:organization/catalogs/:catalogName/rules/:catalogStandard",
  catalogRulesTemplates: "catalog_rules_templates/:catalogStandard",
  catalogsPackages: ":repoType/:organization/catalogs/packages",
  catalogStandardOverrides: ":repoType/:organization/catalogs/:catalogName/standards/:standardSlug/overrides",
  catalogStandards: ":repoType/:organization/catalogs/:catalogName/standards",
  catalogTaskById: ":repoType/:organization/catalogs/:catalogName/tasks/:taskId",
  catalogTaskDecisionPreview: ":repoType/:organization/catalogs/:catalogName/tasks/:taskId/block_preview",
  catalogUserPermissions: ":repoType/:organization/catalogs/:catalogName/users/:userUuid/permissions",
  ciApiKeys: "api-keys/organization/:repoType/:organization/ci",
  conditionalOverrides: ":repoType/:organization/catalogs/:catalogName/standards/:standardSlug/conditional_exceptions",
  configuration: "configuration/:model/:field",
  deleteArtifactoryInstance: ":repoType/:organization/artifactory_instance/:id",
  deleteArtifactoryRepository: ":repoType/:organization/artifactory_repositories/:id",
  deleteCatalogSubscription: ":repoType/:organization/catalogs/:catalogName/subscriptions/:subscribedCatalogId",
  deleteCatalogStandardOverrides:
    ":repoType/:organization/catalogs/:catalogName/standards/:standardSlug/overrides/destroy_batch",
  deleteTeamMembers: "teams/:team/delete_memberships",
  enableMonitorBuilds: "github/enable_monitor_builds",
  googleMarketplaceSetup: ":orgType/:team/google_marketplace_setup/:subId",
  importableArtifactoryRepos: ":repoType/:organization/artifactory_instance/repositories",
  importableScans: ":repoType/:organization/catalog/importable_scans",
  importArtifactoryReposToCatalog: ":repoType/:organization/artifactory_instance/import_repositories",
  joinTeam: "user/join_team/:token",
  legacyBuilds: "builds/:buildId",
  licenseCompliance: ":repoType/:organization/catalogs/:catalogName/license_compliance",
  licenses: "spdx_list",
  licenseValidation: "spdx_list/validate",
  lifterViolationsForStandard: "lifter_catalog_tasks/violations/:catalogStandard/:platform/:name",
  submitLifterViolation: "lifter_catalog_tasks/update_violation/:violation_id",
  lifterViolation: "lifter_catalog_tasks/violations/:violation_id",
  lifterOtherPackagesToLift: "lifting/other_packages_to_lift/:platform",
  liftingActivate: "lifting/:platform/:name/activate",
  liftingPackageCount: "lifting/packages-count",
  liftingPackages: "lifting",
  liftingVerifyGithubTwoFactorAuth: "lifting/:platform/:name/verify_github_two_factor_auth",
  liftPackage: "lifting/:platform/:name",
  linkSlackUser: "slack/user_link",
  notifyLoginSuccess: "user/login",
  organizationGroup: ":repoType/:organization/groups/:id",
  organizationGroups: ":repoType/:organization/groups",
  organizationGroupRepository: ":repoType/:organization/groups/:id/projects/:repoName",
  organizationGroupUser: ":repoType/:organization/groups/:id/users/:userUuid",
  organizationLicense: ":repoType/:organization/licenses/:platform/:name",
  package: "package/:platform/:name",
  packageOverride: ":repoType/:organization/catalogs/:catalogName/packages/:platform/:name/package_override",
  packageDeprecationSettings: "package/:platform/:name/deprecation_settings",
  packageReleaseStreams: "package/:platform/:name/release_streams",
  packageSearchByMaintainer: "package/search_by_maintainer/:platform/:maintainer",
  packageVersionIssues: "package/:platform/:name/version_issues",
  projectLabels: ":repoType/:organization/project_labels",
  repository: "projects/:owner/:repo",
  repositoriesAutocomplete: "projects/autocomplete/:type/:org",
  repositoryApiKeys: "api-keys/repository/:repoType/:organization/:repo",
  repositoryScanDependencies: "projects/:owner/:repo/scans/:scanId/dependencies",
  repositoryScanExistingReleaseStatus: "projects/:owner/:repo/scans/:scanId/existing_release_status",
  repositoryScans: "projects/:owner/:repo/scans",
  repositorySetCatalog: ":repoType/:organization/:name/set_catalog",
  scanRerun: "scans/:scanId/rerun",
  settings: "user/settings",
  slackSettings: "slack/settings/:repoType/:organization",
  ssoCheck: "sso_check",
  subscriptionsByOrg: "subscription/orgs",
  syncArtifactoryRepository: ":repoType/:organization/artifactory_repositories/:id/sync",
  teamIntegrations: "teams/:team/integrations",
  teamInvite: "teams/:team/invites/:inviteId",
  teamInvites: "teams/:team/invites",
  teamMembers: "teams/:team/members",
  teams: "teams",
  triggerGcsBuild: "projects/:owner/:repo/scan",
  updateTeamMembers: "teams/:team/update_memberships",
  userGithubOrgs: "user/github_orgs",
  userPreferences: "user_preferences",
  userProfile: "user/profile",
  validateProjectName: "projects/:owner/validate_project_name",
}

export default urls
