/**
 * If you're looking for information on the currently logged in user,
 * check out src/store/pinia/auth.ts.
 */
import { computed } from "vue"
import Cookies from "js-cookie"
import _ from "lodash"
import api from "@/services/api"
import { api as oldApi } from "@/services/depci"
import depci from "@/services/depci"

import config from "@/config"
import { Router } from "vue-router"
import { UserSettings } from "@/types/authentication"
import { useAuthStore } from "@/store/pinia/auth"
import { useMetrics } from "@/services/metrics"
import { storeToRefs } from "pinia"
import axios, { AxiosError } from "axios"
import Bugsnag from "@bugsnag/js"
import qs from "qs"
import { setPriorUrl } from "@/router"
import { datadogRum } from "@datadog/browser-rum"

const LINKS = {
  login: "/login",
  Lifter: "/lifter",
  Subscriber: "/subscriber",
}

const COOKIE_NAME = "tl_main"

export const OTHER_DOMAIN_CONNECT_EVENT = "evtConnectWithOther"

interface AuthConfig {
  // OAuth redirect path
  authPath: string
  // Password authentication post path
  passwordPath: string
  // Log out redirect path
  logoutPath: string
  // cookie for authentication.
  authCookieName: string

  /**
   * Some customers want to force logout of their federated idp when
   * logging out of tidelift but we don't necessarily always want that.
   * Also we have to compute this before we remove the user info.
   */
  federatedLogoutEnabled: boolean

  router: Router
  session: Storage
}

let authConfig: AuthConfig

/**
 * Authentication requires some global setup to work.
 * Configure this as early in the app startup as possible.
 */
export function configureAuth({
  router,
  federatedLogoutEnabled,
}: Pick<AuthConfig, "router" | "federatedLogoutEnabled">) {
  authConfig = {
    ...config.auth,
    federatedLogoutEnabled,
    router,
    session: window.sessionStorage,
  }
}

/**
 * Link generation for various authentication activities.
 */
export function useAuthLinks() {
  const options = authConfig
  const { router } = authConfig

  const authStore = useAuthStore()
  const { authenticated, isLifter, isSubscriber } = storeToRefs(authStore)

  /**
   * The place the current user should go when they click the Tidelift logo
   * in the top left of the screen.
   */
  const homeLink = computed(() => {
    const routerContext = router.currentRoute.value.meta.userRole as keyof typeof LINKS | undefined

    if (!authenticated.value) {
      return LINKS.login
    } else if (isLifter.value && isSubscriber.value && !!routerContext) {
      return LINKS[routerContext]
    } else if (isLifter.value) {
      return LINKS.Lifter
    }
    return LINKS.Subscriber
  })
  /**
   * The location where authentication occurs.
   */
  function authLink(path: string, authType: string, intent?: string) {
    const url = new URL(options.authPath, window.location.origin)
    url.searchParams.append("to", window.location.origin + redirectRoute(path))
    url.searchParams.append("connection", authType)
    if (intent) {
      url.searchParams.append("tl_intent", intent)
    }
    return url.pathname + url.search
  }

  function ssoLink({ strategy, emailToRemember }: { strategy: string; emailToRemember?: string }) {
    let link = useAuthLinks().authLink("", strategy, "login")
    if (emailToRemember) {
      link = `${link}&email=${emailToRemember}&remember_email=true`
    }

    return link
  }

  /**
   * When returning to the app from an authentication provider,
   * return to this location.
   */
  function redirectRoute(path: string) {
    const defaultPath = "/account/logged_in"

    if (!(path && path.match(/^\/\w+/))) {
      return defaultPath
    }

    try {
      const result = router.resolve(path)
      if (result.matched.length === 0) {
        return defaultPath
      }
    } catch (e) {
      if ((e as Error).message.includes("next.name")) {
        // error in resolving route
        return defaultPath
      } else {
        throw e
      }
    }

    return path
  }

  return {
    homeLink,
    authLink,
    redirectRoute,
    ssoLink,
  }
}

export class ServerError extends Error {
  originalError: AxiosError

  constructor(msg: string, originalError: AxiosError) {
    super(msg)

    this.originalError = originalError

    Object.setPrototypeOf(this, ServerError.prototype)
  }

  errorCode() {
    return this.originalError.response?.status
  }
}

export class ForbiddenError extends Error {
  originalError: AxiosError

  constructor(msg: string, originalError: AxiosError) {
    super(msg)

    this.originalError = originalError

    Object.setPrototypeOf(this, ForbiddenError.prototype)
  }

  errorCode() {
    return this.originalError.response?.status
  }
}

export class NoUserReturnedByEmailError extends Error {
  constructor() {
    super("No user returned by email")

    Object.setPrototypeOf(this, NoUserReturnedByEmailError.prototype)
  }
}

interface SsoCheckResponse {
  isSso: boolean
  strategy: string
}

/**
 * Work with user authentication and identification, as well as browser
 * storage related to authentication.
 */
export function useAuth() {
  const options = authConfig
  const { router, session } = authConfig

  const authStore = useAuthStore()
  const { user, auth0Profile, loaded, authenticated, isLifter, isSubscriber, isAdmin } = storeToRefs(authStore)

  /**
   * Side effects: Redirects the user to the LoggedIn route.
   */
  function login(user: UserSettings) {
    // Record that the user setup is complete
    authStore.setLoaded(true)

    if (router.currentRoute.value.name === "Login") {
      router.replace({ name: "LoggedIn", query: router.currentRoute.value.query })
    }

    // Set up the user with analytics
    identify(user)
  }

  async function ssoCheck(email: string) {
    const response = await depci.ssoCheck({ email }, {})

    const isSso = response.enrolled && response.strategy.startsWith("saml-")
    const strategy: string = response.strategy

    return {
      isSso,
      strategy,
    } as SsoCheckResponse
  }

  /**
   * Call this within a <RouterView>.
   */
  function identify(user: UserSettings) {
    const params = {
      name: user.name,
      email: user.email,
      avatar: user.picture,
      isInternal: user.app_metadata?.is_internal,
      isLifter: user.app_metadata?.is_lifter,
      isSubscriber: user.app_metadata?.is_subscriber,
    }

    const metrics = useMetrics()

    if (_.result(window, "analytics.user.anonymousId")) {
      metrics.identify("anonymous", params)
    }

    // TODO how do we do this now.
    metrics.identify(user.user_uuid, params)

    Bugsnag.setUser(user.user_id, user.email, user.name)
    datadogRum.setUser({
      id: user.user_id,
      name: user.name,
      email: user.email,
      is_lifter: user.app_metadata?.is_lifter,
    })
  }

  const useGithubLoginStrategy = (backLink: string, intent: string) => {
    window.location.assign(useAuthLinks().authLink(backLink, "github", intent))
  }

  const useGoogleLoginStrategy = (backLink: string, intent: string) => {
    window.location.assign(useAuthLinks().authLink(backLink, "google-oauth2", intent))
  }

  const useSsoLoginStrategy = (strategy: string, emailToRemember?: string) => {
    window.location.assign(useAuthLinks().ssoLink({ strategy, emailToRemember }))
  }

  async function logout() {
    const queryString: qs.ParsedQs = {}

    if (options.federatedLogoutEnabled) {
      queryString.federated = "1"
    }

    // Without this, dev users would get redirected to tidelift.com after logging out
    if (import.meta.env.DEV) {
      queryString.to = config.appUrlBase
    }

    const fullLogoutPath: string = options.logoutPath + qs.stringify(queryString, { addQueryPrefix: true })
    authStore.logout()
    window.location.assign(fullLogoutPath)
  }

  async function retrieveUserByEmail({
    email,
    password,
    rememberEmail,
  }: {
    email: string
    password: string
    rememberEmail: boolean
  }) {
    const url = `${options.passwordPath}/login`
    let response

    try {
      response = await axios.post(url, { email, password, remember_email: rememberEmail })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        switch (e.response?.status) {
          case 403:
            throw new ForbiddenError("Wrong email or password", e)
          case 500:
            throw new ServerError("There was an error -- try again in a few minutes", e)
        }
      }
      throw e
    }

    const user = await authStore.handlePasswordLogInResponse(response)

    if (!user) {
      throw new NoUserReturnedByEmailError()
    }

    useAuth().login(user)
  }

  function clearUserAndRedirectToLogin() {
    setPriorUrl(router.currentRoute.value)
    authStore.logout()
    router.push({ name: "Login" })
  }

  function handleSessionExpired() {
    function handle401(error: AxiosError) {
      if (error.response?.status === 401 && !error.config?.url?.endsWith("user/settings")) {
        /** Do not kick users to login if the settings end point throws 401, we may not be on an authenticated route */
        clearUserAndRedirectToLogin()
      }
      return Promise.reject(error)
    }
    api.client.interceptors.response.use(c => c, handle401)
    oldApi.interceptors.response.use(c => c, handle401)
  }

  function setCookieFromQuery() {
    const params = new URLSearchParams(window.location.search)
    const tlMainCookie = params.get(COOKIE_NAME)
    if (tlMainCookie) {
      Cookies.set(options.authCookieName, tlMainCookie)
      params.delete(COOKIE_NAME)
      router.replace({ query: Object.fromEntries(params) })
    }
  }

  function redirectIfLoggedIn() {
    if (authenticated.value) {
      router.push({ name: "LoggedIn", query: router.currentRoute.value.query })
    }
  }

  /**
   * This needs to be called inside a \<RouterView /\>
   */
  async function initialize() {
    handleSessionExpired()
    setCookieFromQuery()
    const user = await authStore.updateSettings().catch(() => null)
    if (user) {
      login(user)
      const auth0Profile = await axios.get("/auth/profile?force=true")
      authStore.setAuth0Profile(auth0Profile.data)
    }
    if (router.currentRoute.value.meta.requireAuth && !user) {
      clearUserAndRedirectToLogin()
    } else {
      authStore.setLoaded(true)
    }
  }

  // @ts-expect-error this function is not defined on window
  window.toggleSession = () => {
    Cookies.set(options.authCookieName, "invalid")
  }

  return {
    authenticated,
    auth0Profile,
    initialize,
    isAdmin,
    isSubscriber,
    isLifter,
    loaded,
    options,
    user,
    ssoCheck,
    retrieveUserByEmail,
    login,
    logout,
    updateSettings: authStore.updateSettings,
    redirectIfLoggedIn,
    useGithubLoginStrategy,
    useGoogleLoginStrategy,
    useSsoLoginStrategy,
  }
}

export default {
  install: function (app: any) {
    app.config.globalProperties.$auth = useAuth()
    // app.config.globalProperties.$auth.initialize()
  },
}
