import urls from "@/configs/urls"
import type { CatalogParams } from "./types/catalog"

function constructBaseUrl(): string {
  return window.location.protocol + "//" + window.location.host
}

type Feature = string

let forceEnableFeatures: Feature[] = []
if (import.meta.env.VITE_APP_FORCE_ENABLE_FEATURES) {
  forceEnableFeatures = import.meta.env.VITE_APP_FORCE_ENABLE_FEATURES
}

let forceDisableFeatures: Feature[] = []
if (import.meta.env.VITE_APP_FORCE_DISABLE_FEATURES) {
  forceDisableFeatures = import.meta.env.VITE_APP_FORCE_DISABLE_FEATURES
}

interface Config {
  auth: {
    providers: {
      github: { clientId: string | undefined }
    }
    auth0Errors: {
      noAccountFoundOnLogin: string
      accountFoundOnSignup: string
    }
    authPath: string
    passwordPath: string
    logoutPath: string
    linkAccountPath: string
    authCookieName: string
  }
  forceEnableFeatures: Feature[]
  forceDisableFeatures: Feature[]
  appUrlBase: string
  apiBaseUrl: string
  amplitudeToken?: string
  datadogAppId?: string
  datadogToken?: string
  repoToPathMap: { [key: string]: string }
  bugsnag: {
    apiKey: string
    filterKeys: string[]
  }
  slack: {
    clientId: string | undefined
  }
  default_debounce_ms: number
  tideliftAvatarUrl: string
  tideliftOrganizationId: string
  tideliftCatalogId: string
  tideliftSupportAccountId: string
  issueCategories: string[]
  dashboardContext: {
    storage: string
    lifter: string
    subscriber: string
  }
  monocatalog: CatalogParams
  urls: { [key: string]: string }
  dateFormat: string
  dateTimeFormat: string
  dateFullTimeFormat: string
  dayDateFormat: string
  dayDateTimeFormat: string
  spdxLicenseExpressionsLink: string
  pollInterval: number
}

const config: Config = {
  auth: {
    providers: {
      github: {
        clientId: import.meta.env.VITE_APP_GITHUB_CLIENT_ID,
      },
    },
    auth0Errors: {
      noAccountFoundOnLogin: "no_account_found_on_login",
      accountFoundOnSignup: "account_found_on_signup",
    },
    authPath: import.meta.env.VITE_APP_AUTH_PATH ?? "/auth/login",
    passwordPath: import.meta.env.VITE_APP_PASSWORD_PATH ?? "/auth/password",
    logoutPath: import.meta.env.VITE_APP_LOGOUT_PATH ?? "/auth/logout",
    linkAccountPath: import.meta.env.VITE_APP_LINK_ACCOUNT_PATH ?? "/auth/account/link",
    authCookieName: import.meta.env.VITE_APP_AUTH_COOKIE_NAME ?? "tl-main",
  },
  forceEnableFeatures,
  forceDisableFeatures,
  appUrlBase: constructBaseUrl(),
  apiBaseUrl: import.meta.env.VITE_APP_PUBLIC_API_BASE_URL,
  amplitudeToken: import.meta.env.VITE_APP_AMPLITUDE_API_KEY,
  datadogAppId: import.meta.env.VITE_APP_DATADOG_APP_ID,
  datadogToken: import.meta.env.VITE_APP_DATADOG_TOKEN,
  repoToPathMap: { GithubRepository: "github", GcsRepository: "team" },
  bugsnag: {
    apiKey: "2ffb42c3012ba138dc45bac18054d10b",
    filterKeys: ["password"],
  },
  slack: {
    clientId: import.meta.env.VITE_APP_SLACK_CLIENT_ID,
  },
  default_debounce_ms: 500,
  tideliftAvatarUrl:
    "https://avatars0.githubusercontent.com/u/30204434?s=400&u=c243dc1014ff017ed2e46e6adeb171b552e7c6f8&v=4",
  // the tidelift organization that contains the monocatalog:
  tideliftOrganizationId: "294043bb-d6ab-460d-b683-cf67745e7dbd",
  tideliftCatalogId: "6902e0b2-7208-4982-a8c0-521a874c6639",
  tideliftSupportAccountId: "18bf8b19-240b-43ec-9cef-8ed92a25e53f",
  issueCategories: ["security", "licensing", "maintenance"],
  dashboardContext: {
    storage: "dashboardContext",
    lifter: "lifter",
    subscriber: "subscriber",
  },
  monocatalog: {
    repoType: "team",
    organization: "tidelift",
    catalogName: "tidelift",
  },
  urls: urls,
  dateFormat: "D MMM YYYY",
  dateTimeFormat: "D MMM YYYY [at] h:mm a",
  dateFullTimeFormat: "D MMM YYYY [at] h:mm:ss a",
  dayDateFormat: "dddd, D MMM YYYY",
  dayDateTimeFormat: "dddd, D MMM YYYY [at] h:mm a",
  spdxLicenseExpressionsLink: "https://spdx.github.io/spdx-spec/v2.3/SPDX-license-expressions/",
  pollInterval: 3000,
}

export default config
