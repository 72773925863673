import { defineStore } from "pinia"
import type { AxiosError } from "axios"

import depci from "@/services/depci"
import api from "@/services/api"
import type {
  DepciDependencyResponse,
  DepciPackage,
  PackageFragment,
  PackageOverride,
  PackageVersionIssues,
  ReleaseStream,
} from "@/types/package"
import { CatalogParams } from "@/types/catalog"

interface State {
  loadPackagesCount: number
  depciData: Record<string, DepciPackage>
  loadDepciDataCount: number
  loadDepciDataError: AxiosError | null
  dependencies: Record<string, DepciDependencyResponse>
  loadDependenciesCount: number
  loadPackageVersionNumbersCount: number
  dependenciesNotSynced: boolean
  packageVersionNumbers: string[]
  versionIssues: Record<string, PackageVersionIssues>
  loadVersionIssuesCount: number
  releaseStreams: Record<string, ReleaseStream[]>
  loadReleaseStreamsCount: number
  overrides: Record<string, PackageOverride>
  loadOverridesCount: number
}

export function packageUniqueKey(pkg: PackageFragment | { platform: string; name: string }) {
  /** As long as platform string is not the empty string, this is guaranteed unique per package */
  if (pkg.platform === "") throw new Error("platform must not be empty")
  return `${pkg.platform}/${pkg.name}`
}

export function comparePackages(a: PackageFragment, b: PackageFragment, isAsc: boolean) {
  return packageUniqueKey(isAsc ? a : b).localeCompare(packageUniqueKey(isAsc ? b : a))
}

export const usePackageStore = defineStore("packages", {
  state: (): State => ({
    // map from "platform/packageName" to package info
    // how many loadPackages are in flight
    loadPackagesCount: 0,
    depciData: {},
    loadDepciDataCount: 0,
    loadDepciDataError: null,
    dependencies: {},
    loadDependenciesCount: 0,
    loadPackageVersionNumbersCount: 0,
    dependenciesNotSynced: false,
    packageVersionNumbers: [],
    versionIssues: {},
    loadVersionIssuesCount: 0,
    releaseStreams: {},
    loadReleaseStreamsCount: 0,
    overrides: {},
    loadOverridesCount: 0,
  }),
  getters: {
    isLoadingPackages(state: State) {
      return state.loadPackagesCount > 0
    },
    /** @deprecated use store.depciData */
    packageDepciData(state: State) {
      return state.depciData
    },
    isLoadingDepciData(state: State) {
      return state.loadDepciDataCount > 0
    },
    depciDataNotFound(state: State) {
      return state.loadDepciDataCount === 0 && state.loadDepciDataError?.response?.status === 404
    },
    isLoadingDependencies(state: State) {
      return state.loadDependenciesCount > 0
    },
    /** @deprecated use store.versionIssues */
    packageVersionIssues(state: State) {
      return state.versionIssues
    },
    /** @deprecated use store.releaseStreams */
    packageReleaseStreams(state: State) {
      return state.releaseStreams
    },
    isLoadingReleaseStreams(state: State) {
      return state.loadReleaseStreamsCount > 0
    },
    isLoadingVersionIssues(state: State) {
      return state.loadVersionIssuesCount > 0
    },
    allPackageState(state: State) {
      return (pkg: PackageFragment) => {
        const key = packageUniqueKey(pkg)
        return {
          depciData: state.depciData[key],
          dependencies: state.dependencies[key],
          versionIssues: state.versionIssues[key],
          releaseStreams: state.releaseStreams[key],
          overrides: state.overrides[key],
        }
      }
    },
    isLoadingPackageData(state: State) {
      return (
        // Libraries.io is slow, don't block page on it.
        // state.loadPackagesCount > 0 ||
        state.loadDepciDataCount > 0 ||
        state.loadDependenciesCount > 0 ||
        state.loadVersionIssuesCount > 0 ||
        state.loadReleaseStreamsCount > 0 ||
        state.loadOverridesCount > 0
      )
    },
  },
  actions: {
    async loadPackageDepciData({
      platform,
      name,
      catalogName,
      organization,
      repoType,
    }: PackageFragment & Partial<CatalogParams>) {
      this.loadDepciDataCount += 1
      try {
        const depciPackage: DepciPackage = await depci.fetchPackage(platform, name, catalogName, organization, repoType)
        this.depciData[packageUniqueKey({ platform, name })] = depciPackage
      } catch (e) {
        this.loadDepciDataError = e as AxiosError
        /** Allow 404 errors to be ignored in the normal course of operation. */
        if ((e as AxiosError).response?.status !== 404) {
          throw e
        }
      } finally {
        this.loadDepciDataCount -= 1
      }
    },
    async loadPackageDependencies(pkg: PackageFragment, version?: string | null) {
      this.loadDependenciesCount += 1
      this.dependenciesNotSynced = false
      try {
        const { data, status } = await api.fetchPackageDependencies(pkg, {
          params: { version },
        })
        if (status === 202) {
          this.dependenciesNotSynced = true
        }
        this.dependencies[packageUniqueKey(pkg)] = data
      } catch (e) {
        /** Allow 404 errors to be ignored in the normal course of operation. */
        if ((e as AxiosError).response?.status !== 404) {
          throw e
        }
      } finally {
        this.loadDependenciesCount -= 1
      }
    },
    async loadPackageVersionIssues(pkg: PackageFragment) {
      this.loadVersionIssuesCount += 1
      try {
        const packageVersionIssues: PackageVersionIssues = await depci.fetchPackageVersionIssues(pkg.platform, pkg.name)
        this.versionIssues[packageUniqueKey(pkg)] = packageVersionIssues
      } finally {
        this.loadVersionIssuesCount -= 1
      }
    },
    async loadPackageVersionNumbers(pkg: PackageFragment) {
      this.loadPackageVersionNumbersCount += 1
      try {
        const packageVersionNumbers = (await api.fetchPackageVersionNumbers(pkg)).data
        this.packageVersionNumbers = packageVersionNumbers
      } finally {
        this.loadPackageVersionNumbersCount -= 1
      }
    },
    async loadPackageReleaseStreams(pkg: PackageFragment) {
      this.loadReleaseStreamsCount += 1
      try {
        const packageReleaseStreams: ReleaseStream[] = await depci.fetchPackageReleaseStreams(pkg.platform, pkg.name)
        this.releaseStreams[packageUniqueKey(pkg)] = packageReleaseStreams
      } finally {
        this.loadReleaseStreamsCount -= 1
      }
    },
    setPackageOverride(pkg: PackageFragment, override: PackageOverride) {
      this.overrides[packageUniqueKey(pkg)] = override
    },
    // async loadPackageOverride({
    //   platform,
    //   name,
    //   catalogName,
    //   organization,
    //   repoType,
    // }: PackageFragment & CatalogParams) {
    //   this.loadOverridesCount += 1
    //   try {
    //     const override = await depci.fetchPackageOverride({
    //       platform,
    //       name,
    //       catalogName,
    //       organization,
    //       repoType,
    //     })
    //     this.setPackageOverride({ platform, name }, override)
    //   } finally {
    //     this.loadOverridesCount -= 1
    //   }
    // },
    /**
     * loadPackageData is an aggregate function that pulls in data from all other apis for a given package
     */
    async loadPackageData({
      platform,
      name,
      catalogName,
      organization,
      repoType,
    }: PackageFragment & Partial<CatalogParams>) {
      const pkgKey = packageUniqueKey({ platform, name })
      const requestsForAllPackages = [
        this.loadPackageDepciData({
          platform,
          name,
          catalogName,
          organization,
          repoType,
        }),
      ]

      // run these promises asynchronously, but catch a load error if any of
      // them fail.
      await Promise.all(requestsForAllPackages)
      const depciData = this.depciData[pkgKey]
      const isKnownPackage = depciData?.is_known

      if (isKnownPackage) {
        /** Do await requests to depci */
        await Promise.all([
          this.loadPackageVersionIssues({ platform, name }),
          this.loadPackageReleaseStreams({ platform, name }),
        ])
      }
    },
  },
})
