<template>
  <Component
    :is="type"
    class="not-found"
  >
    <NavBar
      active="NotFound"
      :navItems="[
        { name: 'Template', component: 'Index', href: '/#/' },
        { name: 'Living Docs', href: 'http://localhost:6060/' },
      ]"
    />
    <Wrapper>
      <Heading level="h1">404 Error</Heading>
      <TextStyle>
        Couldn’t find a component that would match the URL you entered. Double check configuration in
        router/index.js.</TextStyle
      >
    </Wrapper>
  </Component>
</template>

<script>
/**
 * Shows how to layout and structure an error page.
 */
export default {
  name: "NotFound",
  status: "prototype",
  metaInfo: {
    title: "Page Not Found | Vue Design System",
    htmlAttrs: {
      lang: "en",
    },
  },
  props: {
    /**
     * The html element name used for the component.
     */
    type: {
      type: String,
      default: "div",
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
// Design Tokens with local scope
$color-template-background: $color-dark-text;
$color-template-background-top: tint($color-template-background, 5%);
$color-template-background-bottom: shade($color-template-background, 5%);
$color-template-text: $color-white;
$color-template-link: $color-positive-green;

.not-found {
  @include reset;

  /* @include inset-space($space-base); */
  min-height: $space-xxx-large * 4;
  background: $color-template-background;
  background: linear-gradient(0deg, $color-template-background-bottom, $color-template-background-top 100%);
  text-align: center;
  position: relative;
  float: left;
  height: 100%;
  width: 100%;

  .heading {
    color: $color-template-text;
  }

  .text-style {
    color: $color-template-text;
  }

  .text-link {
    color: $color-template-link;
  }

  .wrapper {
    max-width: $space-xxx-large * 4.5;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
</style>
