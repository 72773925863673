<template>
  <Modal
    class="tl-dialog"
    :title="title"
    :closeModal="() => {}"
    :cancelOptions="[]"
    :isActive="isDialogOpen"
    :displayCloseButton="false"
    :opaque="false"
  >
    <template #content>
      <section>
        <p class="is-flex is-align-items-center has-themed-text">
          <OIcon
            pack="fas"
            :icon="icon"
            :variant="variant"
            class="dialog-icon mr-5"
          />
          {{ message }}
        </p>
      </section>
    </template>
    <template #footer>
      <footer class="is-justify-content-flex-end">
        <button
          @click="resolveDialog(false)"
          class="button"
        >
          {{ denyText }}
        </button>
        <button
          @click="resolveDialog(true)"
          :class="`button is-${buttonType}`"
        >
          {{ confirmText }}
        </button>
      </footer>
    </template>
  </Modal>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"
import { useDialogStore } from "@/store/pinia/dialog"
import { storeToRefs } from "pinia"

const ICON_MAP: Record<string, string> = {
  primary: "info-circle",
  warning: "exclamation-triangle",
  danger: "exclamation-circle",
}

export default defineComponent({
  setup() {
    const dialogStore = useDialogStore()
    const { isDialogOpen, title, message, confirmText, denyText, variant } = storeToRefs(dialogStore)

    const icon = computed(() => ICON_MAP[variant.value])
    const buttonType = computed(() => {
      return variant.value === "danger" ? "danger" : "primary"
    })

    return {
      icon,
      buttonType,

      resolveDialog: dialogStore.resolveDialog,
      isDialogOpen,
      title,
      message,
      confirmText,
      denyText,
      variant,
    }
  },
})
</script>

<style
  lang="scss"
  scoped
>
.dialog-icon {
  font-size: 3rem;
  height: 3rem;
  width: 3rem;
  @include themify {
    &.is-primary {
      color: themed("color-primary");
    }

    &.is-warning {
      color: themed("color-warning");
    }

    &.is-danger {
      color: themed("color-danger");
    }
  }
}

.tl-dialog :deep(.modal.is-active::before) {
  background-image: none !important;
  opacity: 0.55;
}
</style>
