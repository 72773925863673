import App from "@/App.vue"
import NotFound from "@/components/pages/GlobalErrorPage.vue"
import CmsPage from "@/components/pages/CmsPage.vue"

import lifterRoutes from "@/components/pages/lifter/routes"
import subscriberRoutes from "@/components/pages/subscriber/routes"
import docsRoutes from "@/components/pages/docs/routes"
import urls from "@/configs/urls"
import infoRoutes from "@/components/pages/info/routes"

import RouterViewWrapper from "@/components/scaffold/RouterViewWrapper.vue"
import { RouteRecordRaw } from "vue-router"

const SignUp = () => import("@/components/pages/SignUp.vue")
const Login = () => import("@/components/pages/Login.vue")
const GitHubConnect = () => import("@/components/pages/GitHubConnect.vue")
const LoggedIn = () => import("@/components/pages/LoggedIn.vue")
const LogOut = () => import("@/components/pages/LogOut")
const ForgotPassword = () => import("@/components/pages/ForgotPassword.vue")
const InvalidInviteToken = () => import("@/components/pages/account/InvalidInviteToken.vue")
const LegacyBuildRedirect = () => import("@/components/pages/LegacyBuildRedirect.vue")

const redirects: RouteRecordRaw[] = [
  {
    path: "security",
    redirect: "/docs/security",
  },
  {
    path: "/docs/config",
    redirect: to => {
      window.location.assign(urls.policyDocs)
      return ""
    },
  },
  {
    path: "/docs/supported-manifests",
    redirect: to => {
      window.location.assign(urls.supportedManifests)
      return ""
    },
  },
  {
    path: "/docs/subscriber/bitbucket-integration",
    redirect: to => {
      window.location.assign(urls.bitbucketIntegration)
      return ""
    },
  },
  {
    path: "/docs/subscriber/api",
    redirect: to => {
      window.location.assign(urls.subscriberApi)
      return ""
    },
  },
]

const rootRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: App,
    props: true,
    children: redirects.concat([
      {
        path: "",
        component: CmsPage,
        alias: ["/subscription", "/product", "/pages", "/about", "/about/resources", "/about/lifter"],
      },
      {
        path: "lifter",
        component: RouterViewWrapper,
        children: lifterRoutes,
        meta: {
          userRole: "Lifter",
          requireLifter: true,
          requireAuth: true,
        },
      },
      {
        path: "subscriber",
        component: RouterViewWrapper,
        children: subscriberRoutes,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "scans/:buildId",
        component: LegacyBuildRedirect,
        props: true,
      },
      {
        path: "docs",
        component: RouterViewWrapper,
        children: docsRoutes,
      },
      {
        path: "signup",
        component: SignUp,
        name: "SignUp",
        props: true,
      },
      {
        path: "login",
        component: Login,
        name: "Login",
        props: true,
      },
      {
        // TODO: move these loose files into the account directory in the filesystem
        path: "account",
        component: RouterViewWrapper,
        redirect: { name: "Login" },
        children: [
          {
            path: "connect",
            component: GitHubConnect,
          },
          {
            path: "logged_in",
            name: "LoggedIn",
            component: LoggedIn,
          },
          {
            path: "invalid_invite_token",
            name: "InvalidInviteToken",
            component: InvalidInviteToken,
          },
          {
            path: "logout",
            component: LogOut,
            name: "LogOut",
          },
          {
            path: "forgotpassword",
            component: ForgotPassword,
            name: "ForgotPassword",
          },
        ],
      },
      {
        path: "404",
        component: NotFound,
        meta: {
          notFound: true,
        },
      },
      {
        path: "/:pathMatch(.*)*",
        component: NotFound,
        meta: {
          notFound: true,
        },
      },
    ]),
  },
]

if (import.meta.env.DEV) {
  rootRoutes.push({
    path: "/info",
    component: RouterViewWrapper,
    children: infoRoutes,
  })
}

export default rootRoutes
