/**
 * Depci V2 Api Service is a typeful interface to depci.  An API function should always accept these parameters...
 * 1) For most methods, a URL template parameter object (EXCLUDING query parameters!)
 * 2) A (possibly optional) typeful axios configuration object, possibly including data body and query params
 * https://github.com/tidelift/frontend/wiki/Making-API-Requests
 */
import { client } from "./utils"
import * as catalog from "./catalog"
import * as lifter from "./lifter"
import * as pkg from "./package"
import * as project from "./project"
import * as system from "./system"

export default {
  client,
  ...catalog,
  ...lifter,
  ...pkg,
  ...project,
  ...system,
}
