import _ from "lodash"
import depci from "@/services/depci"
import { REPO_TYPE } from "@/components/scaffold/copy"
import { defineStore } from "pinia"
import { Organization, Subscription } from "@/types/organization"
import nsLocalStorage from "@/services/nsLocalStorage"
import { usePolicyStore } from "./policy"

interface StoreOrganization extends Organization {
  display_name: string
  unique_display_name: string
}

interface State {
  teams: null | StoreOrganization[]
  currentTeam?: StoreOrganization
}

const SELECTED_TEAM_STORAGE_KEY = "selectedTeam"

async function storeSelectedTeam(repoType: string, organization: string) {
  await nsLocalStorage.setItem(SELECTED_TEAM_STORAGE_KEY, `${repoType}/${organization}`)
}

async function getSelectedTeam() {
  const lastSelected = await nsLocalStorage.getItem(SELECTED_TEAM_STORAGE_KEY)

  if (!lastSelected || lastSelected.indexOf("/") === -1) {
    return
  }

  const [repoType, organization] = lastSelected.split("/")

  return { repoType, organization }
}

async function removeSelectedTeam() {
  await nsLocalStorage.removeItem(SELECTED_TEAM_STORAGE_KEY)
}

function findTeam(teams: State["teams"], repoType: string, organization: string) {
  if (teams === null) return

  return _.find(teams, team => team.name === organization && team.org_type === repoType)
}

export const useTeamStore = defineStore("teams", {
  state: (): State => ({
    teams: null,
    currentTeam: undefined,
  }),
  getters: {
    loadingTeams(state) {
      return state.teams === null
    },
    gcsTeams(state) {
      return (state.teams || []).filter(team => team.type === "GcsRepository")
    },
    githubTeams(state) {
      return (state.teams || []).filter(team => team.type === "GithubRepository")
    },
    currentDefaultCatalogName(): string | undefined {
      return this.currentTeam?.default_catalog || undefined
    },
    currentSubscription(): Subscription | null | undefined {
      return this.currentTeam?.subscription
    },
  },
  actions: {
    async fetchTeams(invalidate = false) {
      this.teams = null
      const data: StoreOrganization[] = await depci.fetchTeams(invalidate)
      if (Array.isArray(data)) {
        // add the org type if needed to clarify duplicate names
        const groupedByName = _.groupBy(data, org => (org.display_name || org.name).toLowerCase())

        for (const team of data) {
          // avoid having to check for missing display_name anywhere else
          if (!team.display_name) {
            team.display_name = team.name
          }
          if (groupedByName[team.display_name.toLowerCase()].length > 1) {
            // we don't just overwrite `display_name` because we only want this
            // weird uniquified name if we're in a list; if we're just showing
            // a page title or something, plain display_name is still used
            team.unique_display_name = `${team.display_name} (${REPO_TYPE[team.type]})`
          } else {
            team.unique_display_name = team.display_name
          }
        }
        this.teams = data

        const selectedTeam = await getSelectedTeam()
        if (selectedTeam) {
          this.currentTeam = findTeam(this.teams, selectedTeam.repoType, selectedTeam.organization)
        }
      }
    },
    async setCurrentTeam(repoType: string, organization: string) {
      await storeSelectedTeam(repoType, organization)
      this.currentTeam = findTeam(this.teams, repoType, organization)

      if (this.currentTeam?.policies) {
        const policyStore = usePolicyStore()
        policyStore.setPolicy("team", this.currentTeam.policies)
      }
    },
    async removeCurrentTeam() {
      await removeSelectedTeam()
      this.currentTeam = undefined

      const policyStore = usePolicyStore()
      policyStore.setPolicy("team", undefined)
    },
  },
})
