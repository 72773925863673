import { merge } from "lodash"
import Oruga from "@oruga-ui/oruga-next"
import { bulmaConfig } from "@oruga-ui/theme-bulma"
import { DragonforcePlugin } from "@/dragonforce"
import RequirePolicy from "@/components/scaffold/RequirePolicy.vue"
import VueApexCharts from "vue3-apexcharts"

const orugaConfig = merge(bulmaConfig, {
  iconPack: "fa",
  modal: {
    // The default, scroll=keep, is not supported in theme bulma
    // scroll=clip will prevent background scrolling behind a modal,
    // but may cause the viewport to shift as an existing scrollbar disappears
    scroll: "clip",
  },
})

export default function setupVue(app, enabledFeatures) {
  app.use(Oruga, orugaConfig)

  app.use(DragonforcePlugin)

  app.component("RequirePolicy", RequirePolicy)

  app.use(VueApexCharts)
  app.component("Apexchart", VueApexCharts)

  // mock feature plugin
  app.config.globalProperties.$features = {
    isEnabled(featureName) {
      if (enabledFeatures && enabledFeatures.includes(featureName)) {
        return true
      }
      return false
    },
  }
}
