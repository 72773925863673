<template>
  <span class="flex-container">
    <OPagination
      v-bind="$attrs"
      class="tl-pagination"
      iconPack="none"
      iconPrev="fas fa-chevron-left"
      iconNext="fas fa-chevron-right"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :rangeAfter="2"
      :rangeBefore="2"
      v-model:current="internalCurrent"
      order="centered"
    />
    <TlInput
      v-if="showGoTo"
      class="pagination-list goto"
      placeholder="Go to..."
      type="number"
      min="1"
      :max="totalPages"
      v-model="goTo"
      @keyup.enter="handleInput"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import TlInput from "./TlInput.vue"

export default defineComponent({
  name: "Pagination",
  status: "ready",
  inheritAttrs: false,
  components: { TlInput },
  props: {
    current: { type: Number },
  },
  data() {
    return {
      internalCurrent: this.current,
      goTo: null,
    }
  },
  methods: {
    handleInput() {
      const input = Number(this.goTo)
      if (Number.isInteger(input) && input >= 1 && input <= this.totalPages) {
        this.internalCurrent = input
      }
      this.goTo = null
    },
  },
  computed: {
    totalPages(): number {
      return Math.ceil(Number(this.$attrs.total) / Number(this.$attrs.perPage))
    },
    showGoTo() {
      return (this.totalPages as unknown as number) > 3
    },
  },
  watch: {
    internalCurrent(value) {
      this.$emit("update:current", value)
    },
    current(value) {
      this.internalCurrent = value
    },
  },
})
</script>

<style
  scoped
  lang="scss"
>
:deep(.tl-input) {
  margin-left: $space-x-small;
  width: 6rem;
  height: 30px;
}

.tl-pagination {
  &:deep(.pagination) {
    justify-content: flex-start;
    margin: 0.5rem 0;
  }

  :deep(a) {
    font-weight: bold;
    @include themify {
      color: themed("color-heading-primary");
    }

    height: 30px;
  }

  :deep(.pagination-link) {
    background: transparent;
    border-radius: $border-radius-default;
    @include themify {
      border: 1px solid themed("color-nav-primary");
    }

    &.is-current {
      border-radius: $border-radius-default;
      @include themify {
        background: themed("color-nav-primary");
        color: themed("color-text-nav-focused");
      }
    }
  }

  :deep(.pagination-previous),
  :deep(.pagination-next) {
    padding-left: 0.5em;
    padding-right: 0.5em;
    width: 2.5rem;

    &.is-disabled {
      border: 0;
      @include themify {
        background: themed("color-graph-gray");
      }
    }
  }
}

.flex-container {
  display: flex;
}
</style>
