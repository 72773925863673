<template>
  <OTabs
    v-bind="$attrs"
    class="tl-tabs"
  >
    <slot />
  </OTabs>
</template>

<script lang="ts">
export default {
  name: "TlTabs",
}
</script>

<style
  scoped
  lang="scss"
>
.tl-tabs {
  :deep(.tab-content) {
    padding: $space-large 0;
  }

  :deep(.tabs) {
    font-family: $font-family-uppercase;
    text-transform: uppercase;
    justify-content: flex-start;
    border-bottom: none;
    border-bottom-style: none;
    margin-top: $space-base;
    margin-bottom: $space-base;

    div a {
      padding: $space-x-small $space-base;
      font-size: $font-size-small;
      font-weight: $font-weight-bold;

      @include themify {
        color: themed("color-text-primary");
        border-bottom: 3px solid themed("color-border");
      }
    }

    div a.is-active,
    div a:hover {
      @include themify {
        border-bottom-color: themed("color-heading-primary");
      }
    }

    ul {
      border-bottom: none;
    }

    &.is-default {
      border-bottom-style: none;
    }
  }
}
</style>
