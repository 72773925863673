import RouterViewWrapper from "@/components/scaffold/RouterViewWrapper.vue"
import { METRICS_EVENT } from "@/services/metrics"
import { Policies } from "@/store/pinia/policy"

const Users = () => import("@/components/pages/subscriber/settings/Users.vue")
const ArtifactorySettings = () => import("@/components/pages/subscriber/settings/ArtifactorySettings.vue")
const ApiKeys = () => import("@/components/pages/subscriber/settings/api_keys/ApiKeys.vue")
const SlackSettings = () => import("@/components/pages/subscriber/settings/SlackSettings.vue")
const ContinuousIntegrationSettings = () =>
  import("@/components/pages/subscriber/settings/ContinuousIntegrationSettings.vue")
const Integrations = () => import("@/components/pages/subscriber/settings/Integrations.vue")
const Groups = () => import("@/components/pages/subscriber/settings/Groups.vue")
const Group = () => import("@/components/pages/subscriber/settings/GroupPage.vue")
const SubscriberExtensionSetup = () => import("@/components/pages/WebExt.vue")

export default [
  {
    path: "",
    redirect: { name: "Users" },
  },
  {
    path: "users",
    name: "Users",
    component: Users,
    meta: {
      breadcrumb: { title: "Users" },
      requirePolicy: {
        scope: "team",
        policy: Policies.view_users,
      },
      metrics: {
        event: METRICS_EVENT.USER_SETTINGS_PAGE_VIEWED,
      },
    },
    props: true,
  },
  {
    path: "integrations",
    component: RouterViewWrapper,
    meta: {
      breadcrumb: { title: "Integrations" },
    },
    props: true,
    children: [
      {
        path: "",
        name: "Integrations",
        component: Integrations,
        props: true,
        meta: {
          metrics: { event: METRICS_EVENT.INTEGRATIONS_PAGE_VIEWED },
        },
      },
      {
        path: "artifactory",
        name: "ArtifactorySettings",
        component: ArtifactorySettings,
        meta: {
          breadcrumb: { title: "Artifactory Settings" },
          requirePolicy: {
            scope: "team",
            policy: Policies.manage_teams,
          },
        },
        props: true,
      },
      {
        path: "slack",
        name: "SlackSettings",
        component: SlackSettings,
        meta: {
          breadcrumb: { title: "Slack Settings" },
          metrics: { event: METRICS_EVENT.SLACK_INTEGRATION_PAGE_VIEWED },
          requirePolicy: {
            scope: "team",
            policy: Policies.manage_teams,
          },
        },
        props: true,
      },
      {
        path: "extension-setup",
        name: "ExtensionSetup",
        props: true,
        component: SubscriberExtensionSetup,
        meta: {
          breadcrumb: { title: "Web Extension Setup" },
        },
      },
      {
        path: "ci",
        name: "ContinuousIntegrationSettings",
        component: ContinuousIntegrationSettings,
        meta: {
          breadcrumb: { title: "Continuous Integration Settings" },
          metrics: { event: METRICS_EVENT.CONTINUOUS_INTEGRATION_PAGE_VIEWED },
          requirePolicy: {
            scope: "team",
            policy: Policies.manage_teams,
          },
        },
        props: true,
      },
    ],
  },
  {
    path: "groups",
    meta: {
      breadcrumb: { title: "Groups" },
    },
    children: [
      {
        path: ":slug",
        name: "Group",
        component: Group,
        meta: {
          breadcrumb: { title: (params: { slug: string }) => params.slug },
          metrics: {
            event: METRICS_EVENT.GROUP_PAGE_VIEWED,
            data: (params: { slug: string }) => ({ group: params.slug }),
          },
        },
        props: true,
      },
      {
        path: "",
        name: "Groups",
        component: Groups,
        props: true,
        meta: {
          metrics: {
            event: METRICS_EVENT.GROUP_LIST_PAGE_VIEWED,
          },
        },
      },
    ],
  },
  {
    path: "teams",
    redirect: { name: "Users" },
  },
  {
    path: "api-keys",
    component: RouterViewWrapper,
    children: [
      {
        path: "",
        name: "ApiKeys",
        component: ApiKeys,
        props: true,
        meta: {
          breadcrumb: { title: "API keys" },
          metrics: { event: METRICS_EVENT.API_KEYS_PAGE_VIEWED },
        },
      },
    ],
  },
]
