<script>
import { h } from "vue"

export default {
  name: "TlTable",
  status: "ready",
  render() {
    const headers = this.$slots
      .default()
      ?.find(child => child.type === "thead")
      ?.children.find(child => child.type === "tr")
      ?.children.filter(header => header.type === "th")

    const rows = this.$slots
      .default()
      .find(child => child.type === "tbody")
      ?.children.filter(row => row.type === "tr")

    if (!headers || !rows) {
      console.warn("[TlTable]: Table is not semantic HTML; cannot transform to cards for mobile.")
    }

    if (!this.isMobile || !headers || !rows) {
      return h("table", { class: "tl-table" }, this.$slots.default())
    }
    const content = []
    for (let row of rows) {
      const cardItems = row.children
        .filter(td => td.type === "td")
        .map((item, j) => {
          return h("div", { class: "tl-table-card-item" }, [
            h("h3", { class: "tl-table-card-header" }, headers[j].children),
            h("div", { class: "tl-table-card-body" }, item.children),
          ])
        })
      content.push(h("div", { class: "tl-table-card", key: row.key }, cardItems))
    }
    return h("div", { class: "tl-table-cards" }, content)
  },
  mounted() {
    const media = window.matchMedia("only screen and (max-width: 769px)")
    this.isMobile = media.matches
    media.addEventListener("change", media => {
      this.isMobile = media.matches
    })
  },
  data() {
    return {
      isMobile: false,
      mediaListener: null,
    }
  },
}
</script>

<style lang="scss">
.tl-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  th {
    @include themify {
      color: themed("color-heading-primary");
      border-bottom: 2px solid themed("color-hr");
    }

    font-weight: 600;
    font-size: 1rem;
    padding-left: 0;
    padding-bottom: $space-x-small;

    &:last-child {
      padding-right: 0;
    }
  }

  tr {
    @include themify {
      border-bottom: 1px solid themed("color-hr");
    }
  }

  td {
    padding: 0.75rem 0.75rem 0.75rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    @include themify {
      color: themed("color-text-primary");
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .secondary-text {
    font-size: $font-size-x-small;
  }
}

.tl-table-cards {
  .tl-table-card {
    padding: $space-base;

    &:nth-child(even) {
      @include themify {
        background-color: themed("color-table-card-even-child");
        border-top: 1px solid themed("color-border");
        border-bottom: 1px solid themed("color-border");
      }
    }
  }

  .tl-table-card-header,
  .tl-table-card-body {
    font-family: "IBM Plex Sans", sans-serif;
  }

  .tl-table-card-header {
    @include themify {
      color: themed("color-table-card-header");
    }
  }

  .tl-table-card-body {
    font-weight: 300;
    @include themify {
      color: themed("color-table-card-body");
    }
  }

  .tl-table-card-item:not(:last-child) {
    margin-bottom: $space-small;
  }
}
</style>
