<template>
  <div
    v-if="['paragraph', 'p'].includes(type)"
    class="tl-skeleton-paragraph"
  >
    <div class="tl-skeleton tl-skeleton-span" />
    <div class="tl-skeleton tl-skeleton-span" />
    <div class="tl-skeleton tl-skeleton-span" />
    <div class="tl-skeleton tl-skeleton-span" />
  </div>

  <div
    v-else-if="type === 'list'"
    class="tl-skeleton-list"
  >
    <div class="tl-skeleton tl-skeleton-h2" />
    <hr class="tl-skeleton-hr" />
    <div class="tl-skeleton tl-skeleton-li" />
    <div class="tl-skeleton tl-skeleton-li" />
    <div class="tl-skeleton tl-skeleton-li" />
  </div>

  <template v-else-if="type === 'cards'">
    <LabeledBox
      label="&nbsp;"
      v-for="i in [1, 2, 3, 4]"
      :key="i"
    >
      <div class="tl-skeleton tl-skeleton-li" />
      <div class="tl-skeleton tl-skeleton-li" />
      <div class="tl-skeleton tl-skeleton-li" />
    </LabeledBox>
  </template>

  <TlTable
    v-else-if="type === 'table'"
    class="tl-skeleton-table"
  >
    <slot />
    <tbody>
      <tr
        v-for="i in 8"
        :key="i"
      >
        <td
          v-for="j in numTableHeaders"
          :key="j"
        >
          <div class="tl-skeleton tl-skeleton-span" />
        </td>
      </tr>
    </tbody>
  </TlTable>

  <div
    v-else
    :class="`tl-skeleton tl-skeleton-${type}`"
  />
</template>

<script>
import TlTable from "./TlTable.vue"

export const TYPES = ["list", "paragraph", "p", "h1", "h2", "h3", "span", "div", "hr", "cards", "table", "badge"]
export default {
  name: "Skeleton",
  status: "ready",
  components: { TlTable },
  props: {
    /**
     * What type of skeleton component you want to use
     */
    type: {
      type: String,
      default: "paragraph",
      validator: type => TYPES.includes(type),
    },
  },
  computed: {
    numTableHeaders() {
      if (this.type !== "table") return

      const numHeaders = this.$slots
        .default()
        ?.find(child => child.type === "thead")
        ?.children.find(child => child.type === "tr")
        ?.children.filter(header => header.type === "th")?.length

      if (!numHeaders) {
        console.warn(
          '[Skeleton] A semantic table header must be provided as a child of <Skeleton type="table"> (and none was found). Use structure `thead > tr > th`.'
        )
      }
      return numHeaders
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.tl-skeleton {
  animation: loading 2s infinite;
  /* stylelint-disable */
  @include themify {
    background-color: themed("color-skeleton");
    background-image: linear-gradient(
      90deg,
      transparent 0,
      rgb(themed("color-skeleton-gradient"), 0.5) 50%,
      transparent 100%
    );
  }
  /* stylelint-enable */

  background-position: -300% 0, 0 0;
  background-size: 75%;
  background-repeat: no-repeat;

  &-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  &-h1,
  &-h2,
  &-h3,
  &-span,
  &-li,
  &-div {
    border-radius: 0.75rem;
    margin-bottom: $space-x-small;
  }

  &-h1,
  &-h2,
  &-h3 {
    width: 50%;
    margin-bottom: $space-small;
  }

  &-h1 {
    height: 2.25rem;
  }

  &-h2 {
    height: 2rem;
  }

  &-h3 {
    height: 1.5rem;
  }

  &-span {
    height: $font-size-small;
    width: 100%;
  }

  &-li {
    height: 0.75rem;
    width: 100%;
  }

  &-div {
    height: 2rem;
  }

  &-hr {
    height: 2px;
    margin: 1rem 0;
  }

  &-badge {
    height: 1.5rem;
    width: 4rem;
    border-radius: $border-radius-default;
  }
}

.tl-skeleton-list {
  margin-bottom: $space-base;

  .tl-skeleton-h2 {
    width: 20%;
    margin-top: $space-base;
  }

  .tl-skeleton-li {
    width: 40%;
  }
}

@keyframes loading {
  to {
    background-position: 450% 0, 0 0;
  }
}
</style>
