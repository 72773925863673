<template>
  <Transition name="fade">
    <section
      v-show="open"
      class="fullscreen-overlay"
    >
      <div class="overlay-content">
        <slot />
      </div>
    </section>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "Interstitial",
  props: {
    open: { type: Boolean, required: true },
  },
  computed: {
    visibility(): { invisible: boolean } {
      return { invisible: !this.open }
    },
  },
})
</script>

<style
  lang="scss"
  scoped
>
.fullscreen-overlay {
  @include themify {
    background-color: themed("color-background");
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

// Only transition on leave - should "snap" open
.fade-leave-active {
  transition-property: opacity, height;

  // Let opacity fully transition before changing height
  transition-delay: 0s, $duration-slowly;

  // 0s duration on height transition to immediately change height
  transition-duration: $duration-slowly, 0s;
  transition-timing-function: ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
