<template>
  <div>
    <CardListItem
      v-for="item in items"
      :key="item.name"
      v-bind="item"
      :bold="bold"
      @action="actionHandler($event)"
    >
      <template #action>
        <slot name="action" />
        <span v-if="hasSlot('actionText')">
          <span class="is-heavy-text right-padding">
            <slot
              name="actionText"
              v-bind="item"
            />
          </span>
          <i class="fas fa-chevron-right" />
        </span>
      </template>
      <template #success>
        <slot name="success" />
      </template>
    </CardListItem>
  </div>
</template>

<script lang="ts">
import { ref, PropType, useSlots, defineComponent } from "vue"
import CardListItem from "./CardListItem.vue"

interface Action {
  name: string
  platform: string | undefined
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
    actionHandler: {
      type: Function as PropType<(action: Action | undefined) => any>,
      default: () => {},
    },
    // style modifiers
    bold: { type: Boolean, required: false },
  },
  setup() {
    const slots = useSlots()
    const hasSlot = ref((name: string) => !!slots[name])
    return { hasSlot }
  },
})
</script>

<style
  lang="scss"
  scoped
>
.right-padding {
  padding-right: 9px;
}

.is-heavy-text {
  font-weight: $font-weight-semi-bold;
  letter-spacing: 0;
  @include themify {
    color: themed("color-heading-primary");
  }
}
</style>
