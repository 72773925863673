/**

Use a meta tag on a route to show either a hardcoded string or a dynamic value
for a given route.

## Hardcoded string

Sometimes all you need is a string, which can be defined with basic JSON.
In your route:

{
  path: "home",
  component: AppHome,
  meta: {
    breadcrumb: { title: "Homepage" },
  },
}

## Query parameter

Route parameter data is often used, which is accessed by defining title
value as a function. The defined function will receive the route parameters
as an argument. In your route:

{
  path: "packages/:name",
  component: PackageStuff,
  meta: {
    breadcrumb: {
      title(routeParams) {
        return `${ routeParams.name } Stuff`
      }
    }
  }
}

This is commonly used with more JS sugar, like:

{ breadcrumb: { title: ({ name }) => `${ name } Stuff` } }

## Global state store data

You can also use more complicated title functions to get data that isn't
available as a route parameter, like global state store data. In your route:

{
  path: "overview/:scanId",
  meta: {
    breadcrumb: {
      title: ({ scanId }) => {
        const scansStore = useScansStore()
        const scan = scansStore.getScan(scanId)
        return scan ? `Alignment #${scan.number}` : "loading..."
      },
    },
  }
}

*/

import escapeStringRegexp from "escape-string-regexp"
import { RouteParams, useRoute } from "vue-router"

import { computed } from "vue"

/**
 * UIBreadcrumb is used in the rendering of the UI.
 */
interface UIBreadcrumb {
  path: string
  title: string
}

/**
 * RouteBreadcrumb is our own data type that exists in the meta
 * field of a route.
 */
interface RouteBreadcrumb {
  /**
   * Text to render.
   */
  title: string | ((params: RouteParams, ...getters: string[]) => string)

  /**
   * Access global store getters
   */
  getters?: string[]

  /**
   * If this function returns true, render the breadcrumb.
   */
  if?: (params: RouteParams, ...getters: string[]) => boolean

  /**
   * Execute some code on all existing UIBreadcrumbs finalizing the current breadcrumb
   */
  beforeRender?: (breadcrumbs: UIBreadcrumb[], params: RouteParams, ...getters: string[]) => void
}

/**
 * Parse current route's meta entries for breadcrumb info.
 */
export function useBreadcrumbs() {
  const currentRoute = useRoute()
  const breadcrumbs = computed(() => {
    const _crumbs: UIBreadcrumb[] = []
    for (const route of currentRoute.matched) {
      const breadcrumb: RouteBreadcrumb | undefined = route.meta.breadcrumb as RouteBreadcrumb | undefined
      if (!breadcrumb) continue

      let path = route.path
      for (const [param, value] of Object.entries(currentRoute.params)) {
        // ignore parameters that have more than one value
        if (Array.isArray(value)) continue

        // Substitute in the param values for the placeholders
        const escapedParam = escapeStringRegexp(param)
        const regex = new RegExp(`:\\b${escapedParam}\\b`)
        path = path.replace(regex, encodeURIComponent(value))
      }

      /*
       * "title" can be defined as a string or as function which allows a breadcrumb to use route params
       * In addition to receiving route params, title functions can include arbitrary `setup` code, commonly using global state stores
       */
      const title = typeof breadcrumb.title === "function" ? breadcrumb.title(currentRoute.params) : breadcrumb.title

      if (breadcrumb.beforeRender) breadcrumb.beforeRender(_crumbs, currentRoute.params)

      _crumbs.push({ path, title })
    }
    return _crumbs
  })

  return breadcrumbs
}
