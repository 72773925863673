<template>
  <div
    class="multi-select-wrapper"
    :class="{ 'is-disabled': disabled }"
    ref="tlMultiselectRef"
  >
    <div
      class="multi-select"
      :class="{ filterable, 'is-open': isDropdownOpen, 'is-closed': !isDropdownOpen }"
      role="button"
      aria-controls="options-wrapper"
      @click="toggle"
    >
      <div>
        <!-- If a selection override slot is provided, it takes priority -->
        <slot
          v-if="$slots['selection']"
          name="selection"
        />
        <div
          class="ml-2 is-inline-block"
          v-else-if="showSelections"
        >
          <TransitionGroup name="tag">
            <Tag
              class="mt-2 pl-3"
              square
              v-for="{ text, value } of selectedOptions"
              :key="value"
              :value="value"
              @dismissed="() => removeSelection(value)"
              @click.stop
            >
              {{ text }}
            </Tag>
          </TransitionGroup>
        </div>
        <input
          v-if="filterable"
          :placeholder="placeholder"
          class="filter-input"
          v-model="filter"
          @focus="isDropdownOpen = true"
          @click.stop
        />
        <template v-else>
          <span :title="placeholder">{{ placeholder }}</span>
        </template>
      </div>
    </div>
    <div
      id="options-wrapper"
      class="options-wrapper"
      role="region"
      aria-live="polite"
    >
      <Card
        condensed
        class="options"
        v-if="isDropdownOpen"
      >
        <slot name="groupSelections" />
        <label
          v-if="label"
          class="subheading mt-2 ml-1"
        >
          {{ label }}
        </label>
        <OCheckbox
          v-for="{ text, value } of filteredOptions"
          :key="value"
          :nativeValue="value"
          :modelValue="modelValue"
          :disabled="disabledOptions"
          @update:modelValue="updateSelections"
        >
          {{ text }}
        </OCheckbox>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { onClickOutside } from "@vueuse/core"
import { computed, defineComponent, PropType, ref, watch } from "vue"
import Card from "./Card.vue"
import Tag from "./Tag.vue"
import type { Tag as TagWithOptional } from "./TlStandardTable.vue"

type TagType = Required<TagWithOptional>

export default defineComponent({
  components: { Card, Tag },
  props: {
    placeholder: { type: String, required: false },
    options: { type: Array as PropType<TagType[]>, required: true },
    modelValue: { type: Array as PropType<string[]>, default: () => [] },
    disabled: { type: Boolean, default: false },
    disabledOptions: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
    showSelections: { type: Boolean, default: false },
    label: { type: String, required: false },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isDropdownOpen = ref(false)
    const toggle = () => {
      if (props.disabled) return
      isDropdownOpen.value = !isDropdownOpen.value
    }
    const tlMultiselectRef = ref(null)
    onClickOutside(tlMultiselectRef, () => (isDropdownOpen.value = false))
    watch(
      () => props.disabled,
      isDisabled => {
        if (isDisabled) isDropdownOpen.value = false
      }
    )

    const updateSelections = (selections: string[]) => {
      emit("update:modelValue", selections)
    }
    const removeSelection = (removedSelection: string) => {
      updateSelections(props.modelValue.filter(selection => selection !== removedSelection))
    }

    const filter = ref("")
    const filteredOptions = computed(() => {
      if (!props.filterable) return props.options
      return props.options.filter(option => {
        return option.text.toLowerCase().includes(filter.value.toLowerCase())
      })
    })
    const selectedOptions = computed(() => {
      return props.options.filter(option => props.modelValue.includes(option.value))
    })

    return {
      filter,
      filteredOptions,
      selectedOptions,
      updateSelections,
      removeSelection,
      isDropdownOpen,
      toggle,

      tlMultiselectRef,
    }
  },
})
</script>

<style
  scoped
  lang="scss"
>
.multi-select-wrapper {
  display: inline-block;
  position: relative;
  width: 20rem;
}

.multi-select {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 2rem;
  max-width: 100%;
  width: 100%;
  padding: 0.2rem 2.5em calc(0.375em - 1px) calc(0.625em - 1px);
  cursor: pointer;
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: $border-radius-default;
  color: #363636;
  line-height: 1.5;
  outline: none;
  box-shadow: none;
  appearance: none;
  user-select: none;

  &.filterable {
    padding: 0 2.5em 0 0;
  }

  &:hover {
    border-color: #b5b5b5;
  }

  > span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.multi-select::after {
  position: absolute;
  content: " ";
  height: 0.6em;
  width: 0.6em;
  right: 1.125em;
  z-index: 4;
  border: 3px solid $color-tidelift-blue;
  border-right: 0;
  border-top: 0;
  border-radius: 2.2px;
  pointer-events: none;
  transform-origin: center;
  transition: all $duration-semi-quick ease-in-out;
}

.is-closed.multi-select::after {
  transform: rotate(-45deg);
  bottom: 0.8em;
}

.is-open.multi-select::after {
  transform: rotate(135deg);
  bottom: 0.6em;
}

.options-wrapper {
  position: absolute;
  width: 100%;
  left: -1px;
  border: 1px solid transparent;
  box-sizing: border-box;
  z-index: 10;
}

.card.options {
  padding: $space-x-small $space-xx-small;
  font-size: 1rem;
  max-height: 250px;
  overflow-y: scroll;

  :deep(.checkbox) {
    padding: $space-x-small $space-x-small;

    &:hover {
      background-color: $color-tidelift-blue-100;
    }
  }
}

.tag-enter-active,
.tag-leave-active {
  transition: all $duration-quickly ease-in-out;
}

.tag-enter-from,
.tag-leave-to {
  opacity: 0;
}

.is-disabled {
  .multi-select {
    color: #7a7a7a;
    background-color: whitesmoke;
    border-color: whitesmoke;
    cursor: not-allowed;
  }
}

.filter-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0 0 0 calc(0.625em - 1px);
  height: calc(2rem - 2px);
  font-size: 1rem;
}

.subheading {
  color: $color-placeholder-gray;
  font-size: $font-size-small;
  font-weight: $font-weight-semi-bold;
}
</style>
