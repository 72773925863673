import type { PaginatedResponse, PaginationParams } from "@/types/general"
import type { ProjectDeveloperAction, ProjectDeveloperActionMetadata, Repository } from "@/types/repository"
import type { AxiosRequestConfig } from "axios"

import { RequiredParams, RequiredParamsAndData, t, client } from "./utils"
import { ProjectMetrics } from "@/types/projectMetrics"

export function createGcsRepo(
  urlParams: { owner: string; repo: string },
  config: RequiredParamsAndData<
    { repoType: string; catalogId: string; catalogName: string },
    { groups: string[]; default_branch: string; external_identifier: string | null }
  >
) {
  const url = t("projects/{owner}/{repo}", urlParams)
  return client.post<void>(url, config.data, config)
}

export function fetchRepositoriesPage(
  org: { name: string; type: string },
  config: RequiredParams<
    PaginationParams & {
      sort_by?: string
      sort_dir?: string
      filter?: Record<string, unknown>
    }
  >
) {
  const url = t("projects/by_org/{type}/{name}", org)
  return client.get<PaginatedResponse<Repository>>(url, config)
}

export function fetchRepository(
  urlParams: { org: string; repoType: string; repoName: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("projects/by_org/{repoType}/{org}/{repoName}", urlParams)
  return client.get<Repository>(url, config)
}

export function fetchProjectFilters(
  urlParams: { org: string; repoType: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("project_filters/{repoType}/{org}", urlParams)
  return client.get<{
    platforms: string[]
    groups: string[]
    catalogs: [string, string][] // [catalog_id, catalog_name][]
  }>(url, config)
}

type AutocompleteSortBy = "latest_default_scan_summary.committed_time" | "name" | null

export function fetchRepositoriesAutocomplete(
  urlParams: { repoType: string; organization: string },
  config: RequiredParams<{
    sort_by: AutocompleteSortBy
    filter: { name: string | null }
  }>
) {
  const url = t("projects/autocomplete/{repoType}/{organization}", urlParams)
  return client.get<PaginatedResponse<{ name: string }>>(url, config)
}

type PaginationWithMetadata<T> = PaginatedResponse<T> & { metadata: ProjectDeveloperActionMetadata }
export function fetchProjectDeveloperActions(
  urlParams: { scan_id: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("catalog_alignment/{scan_id}/developer_actions", urlParams)
  return client.get<PaginationWithMetadata<ProjectDeveloperAction>>(url, config)
}

export function fetchProjectDeveloperActionsCount(
  urlParams: { scan_id: string },
  config: RequiredParams<{
    count_only: string
  }>
) {
  const url = t("catalog_alignment/{scan_id}/developer_actions", urlParams)
  return client.get<{
    total_count: number
  }>(url, config)
}

export function fetchProjectMetrics(
  urlParams: { repoType: string; organization: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("{repoType}/{organization}/project_metrics", urlParams)
  return client.get<ProjectMetrics>(url, config)
}
