import { METRICS_EVENT } from "@/services/metrics"
import { Policies } from "@/store/pinia/policy"
import { useScansStore } from "@/store/pinia/scans"
import type { RouteRecordRaw } from "vue-router"

const RepositoryWrapper = () => import("./RepositoryWrapper.vue")
const ScanOverview = () => import("@/components/pages/subscriber/scans/scan_overview/ScanOverview.vue")
const RepositoryOverview = () => import("./RepositoryOverview.vue")
const Dependencies = () => import("@/components/pages/subscriber/scans/Dependencies.vue")
const NewBuild = () => import("./NewBuild.vue")
const Settings = () => import("./RepositorySettings.vue")
const ProjectApiKeys = () => import("./ProjectApiKeys.vue")
const Repositories = () => import("./Repositories.vue")

const routes: RouteRecordRaw[] = [
  {
    path: "",
    name: "Repositories",
    component: Repositories,
    props: true,
    meta: {
      metrics: {
        event: METRICS_EVENT.CATALOG_PROJECT_LIST_PAGE_VIEWED,
      },
    },
  },
  {
    path: ":repo",
    component: RepositoryWrapper,
    props: true,
    meta: {
      breadcrumb: { title: "Overview" },
    },
    children: [
      {
        path: "",
        alias: "overview",
        name: "RepositoryOverview",
        component: RepositoryOverview,
        props: true,
        meta: {
          metrics: {
            event: METRICS_EVENT.PROJECT_OVERVIEW_PAGE_VIEWED,
            data: (params: { repo: string }) => ({ project_name: params.repo }),
          },
        },
      },
      {
        path: "issues",
        name: "RepositoryIssues",
        redirect: { name: "RepositoryOverview" },
      },
      {
        path: "history",
        redirect: { name: "RepositoryOverview" },
      },
      {
        path: "dependencies",
        redirect: { name: "RepositoryOverview" },
      },
      {
        path: "export",
        redirect: { name: "RepositoryOverview" },
      },
      {
        path: "settings",
        name: "RepositorySettings",
        component: Settings,
        props: true,
        meta: {
          breadcrumb: { title: "Actions and Settings" },
          requirePolicy: {
            scope: "team",
            policy: Policies.update_projects,
          },
          metrics: {
            event: METRICS_EVENT.PROJECT_SETTINGS_PAGE_VIEWED,
            data: (params: { repo: string }) => ({ project_name: params.repo }),
          },
        },
      },
      {
        path: "keys",
        name: "ProjectApiKeys",
        component: ProjectApiKeys,
        props: true,
        meta: {
          breadcrumb: { title: "Project keys" },
          requirePolicy: {
            scope: "team",
            policy: Policies.view_api_keys,
          },
          metrics: {
            event: METRICS_EVENT.PROJECT_KEYS_PAGE_VIEWED,
            data: (params: { repo: string }) => ({ project_name: params.repo }),
          },
        },
      },
      {
        path: "new-build",
        name: "NewBuild",
        component: NewBuild,
        props: true,
        meta: {
          breadcrumb: { title: "Upload manifests" },
          requirePolicy: {
            scope: "team",
            policy: Policies.update_projects,
          },
        },
      },
      {
        path: "overview/:scanId",
        name: "ScanOverview",
        component: ScanOverview,
        props: true,
        meta: {
          breadcrumb: {
            title: (params: { scanId: string }) => {
              const scansStore = useScansStore()
              const scan = scansStore.getScan(params.scanId)
              return scan ? `Alignment #${scan.number}` : "loading..."
            },
          },
          metrics: {
            event: METRICS_EVENT.PROJECT_ALIGNMENT_PAGE_VIEWED,
            data: (params: { repo: string; scanId: string }) => ({ project_name: params.repo, scan_id: params.scanId }),
          },
        },
      },
      {
        path: "dependencies/:scanId",
        component: Dependencies,
        name: "SubscriberDependencies",
        props: true,
        meta: {
          breadcrumb: {
            title: "Bill of materials",
            beforeRender(breadcrumbs: unknown[], params: { scanId: string }) {
              const scansStore = useScansStore()
              const scan = scansStore.getScan(params.scanId)
              const copy = scan ? `Alignment #${scan.number}` : "loading..."
              breadcrumbs.push({ title: copy, path: `../overview/${params.scanId}` })
            },
          },
          metrics: {
            event: METRICS_EVENT.PROJECT_DEPENDENCIES_PAGE_VIEWED,
            data: (params: { repo: string; scanId: string }) => ({ project_name: params.repo, scan_id: params.scanId }),
          },
        },
      },
    ],
  },
]

export default routes
