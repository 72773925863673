<template>
  <Interstitial :open="isOpen">
    <img
      class="has-large-padding"
      src="@/assets/mobile-notice.svg"
      alt="Outline of Tidelift on desktop and an outline of Tidelift on mobile."
    />
    <Heading :level="2">Looks like you’re on mobile</Heading>
    <p class="description">For the best Tidelift experience, we recommend using a laptop or desktop computer.</p>
    <OButton
      class="is-primary"
      @click="noticeAccepted = true"
    >
      Continue anyway<OIcon
        class="arrow-icon"
        pack="fas"
        icon="arrow-right"
      />
    </OButton>
  </Interstitial>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import { useMediaQuery } from "@vueuse/core"

export default defineComponent({
  name: "MobileNotice",
  setup() {
    const noticeAccepted = ref(false)
    const isMobile = useMediaQuery("(max-device-width: 812px)")
    const isOpen = computed(() => {
      return isMobile.value && !noticeAccepted.value
    })
    return { isOpen, noticeAccepted }
  },
})
</script>

<style
  lang="scss"
  scoped
>
.arrow-icon {
  padding-left: 1.25rem;
  padding-right: $space-x-small;
}

.has-large-padding {
  padding-bottom: $space-x-large;
}

.description {
  padding-bottom: $space-base;
  text-align: center;
  line-height: $line-height-base;
}
</style>
