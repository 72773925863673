import Bugsnag from "@bugsnag/js"
import BugsnagPluginVue from "@bugsnag/plugin-vue"
import config from "./config"
import beforeSendReportScrubber from "@/beforeSendReportScrubber"

if (import.meta.env.MODE === "production" && window.location.hostname === "tidelift.com") {
  Bugsnag.start({
    apiKey: config.bugsnag.apiKey,
    // @ts-expect-error GITVERSION comes from vite-plugin-git-revision
    appVersion: GITVERSION,
    enabledReleaseStages: ["production"],
    onError: event => {
      return beforeSendReportScrubber(event)
    },
    plugins: [new BugsnagPluginVue()],
    releaseStage: import.meta.env.MODE,
  })
}

export default Bugsnag.getPlugin("vue")
