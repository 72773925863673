<template>
  <div class="tl-standard-table">
    <div
      class="is-flex is-flex-direction-row is-align-items-center is-flex-wrap-wrap mb-4"
      :class="extraHeaderClasses"
    >
      <slot name="title">
        <Heading
          :level="level"
          class="my-0"
          >{{ title }}</Heading
        >
        <Badge
          v-if="!disableCount"
          class="task-count-badge p-2"
          square
          >{{ loading ? "-" : numeral(total || data.length || 0).format() }}
        </Badge>
      </slot>

      <div class="is-flex-grow-1" />
      <div class="mr-2">
        <slot
          name="search"
          v-bind="{ search: lazySearch, updateSearch: (newVal: string) => lazySearch = newVal }"
        >
          <TlInput
            v-if="searchable"
            v-model="lazySearch"
            class="search-input"
            :placeholder="searchFieldTitle"
            icon="search"
            iconPack="fas"
            clearable
          />
        </slot>
      </div>
      <slot
        v-if="$slots['filter-menu-content']"
        name="filter-menu"
      >
        <!-- Use a dropdown as the default filter menu -->
        <ODropdown
          position="bottom-left"
          menuActiveClass="dropdown-menu-active"
          canClose
        >
          <template #trigger>
            <button class="button filter is-tall">
              <span class="filter-text">Filter</span>
              <OIcon
                pack="fas"
                icon="filter"
              />
            </button>
          </template>
          <!-- Consumers must provide their own filter UI inside the dropdown -->
          <slot name="filter-menu-content" />
        </ODropdown>
      </slot>
      <slot name="primary-action" />
      <ODropdown
        v-if="$slots['context-menu-content']"
        position="bottom-left"
        menuActiveClass="dropdown-menu-active"
        canClose
      >
        <template #trigger>
          <OIcon
            pack="fas"
            icon="ellipsis-v"
            size="medium"
            class="ml-2"
            style="cursor: pointer"
          />
        </template>
        <slot name="context-menu-content" />
      </ODropdown>
    </div>
    <div
      v-if="$slots['table-subheading']"
      :class="extraTableClass"
    >
      <slot name="table-subheading" />
    </div>
    <div
      v-if="internalFilterTags.length"
      class="is-flex is-flex-direction-row is-align-content-center is-flex-wrap-wrap my-1"
      :class="extraTableClass"
    >
      <p class="mr-2">Filtering by</p>
      <Tag
        v-for="tag in internalFilterTags"
        :key="tag.value"
        :value="tag.value"
        class="my-1 mr-2"
        @dismissed="() => removeFilter(tag)"
      >
        <span class="pr-1">{{ tag.type }}:</span>
        <span>{{ tag.text || tag.value }}</span>
      </Tag>
      <a
        v-if="internalFilterTags.length"
        @click="clearFilters"
      >
        Clear all
      </a>
    </div>
    <!-- <div v-else class="my-6"></div> -->

    <OTable
      sortIcon="arrow-up"
      iconPack="fas"
      :rootClass="extraTableClass"
      :rowClass="rowClass"
      :loading="loading"
      :detailed="detailed"
      :detailKey="detailKey"
      :customDetailRow="customDetailRow"
      tdDetailedChevronClass="detailed-chevron"
      v-bind="{
        ...$attrs,
        checkable,
        checkedRows,
        data: filteredData,
        selected,
        backendPagination: backend,
        backendFiltering: backend,
        backendSorting: backend,
        paginated: (total || filteredData.length) > perPage,
        currentPage: lazyCurrentPage,
        perPage,
        total,
      }"
      @sort="(field: string, order: SortDirection) => backend && $emit('sort', field, order)"
      @update:selected="(value: any) => $emit('update:selected', value)"
      @update:currentPage="(newPage: number) => lazyCurrentPage = newPage"
      @update:checkedRows="(value: any) => $emit('update:checkedRows', value)"
    >
      <template #preheader>
        <slot name="preheader" />
      </template>
      <OTableColumn
        v-bind="{ ...column, sortable: column.sortable && filteredData.length > 1 }"
        :key="column.customKey || column.field"
        v-for="column in columns"
      >
        <template #default="{ row }">
          <template v-if="!skeleton">
            <slot
              :name="`item_${column.field}`"
              v-bind="{ row: (row as T), column }"
            >
              {{ row[column.field] }}
            </slot>
          </template>
          <p v-else>
            <Skeleton
              type="div"
              style="max-width: 25rem; height: 1.5em"
            />
          </p>
        </template>
      </OTableColumn>
      <template #detail="{ row }">
        <slot
          name="detail"
          v-bind="{ row }"
        />
      </template>
      <template #loading>
        <OLoading
          :active="loading"
          :fullPage="false"
        >
          <OIcon
            pack="fas"
            icon="circle-notch"
            class="pending fa-spin fa-lg"
            size="large"
          />
          <Badge
            square
            class="longer-badge"
            v-if="loadingTooLong"
          >
            Loading is taking longer than usual...
          </Badge>
        </OLoading>
      </template>

      <template #empty>
        <slot
          name="empty"
          v-bind="{ isFiltered }"
        >
          <EmptyStateView :type="isFiltered ? 'search' : 'default'">
            <template #headline>
              <span v-if="isFiltered"> No {{ title.toLocaleLowerCase() }} found for your search </span>
              <span v-else> This table is empty </span>
            </template>
            <template #cta>
              <button
                v-if="isFiltered"
                class="button is-primary"
                @click="clearFilters"
              >
                Clear Filters
              </button>
            </template>
          </EmptyStateView>
        </slot>
      </template>

      <template #pagination>
        <Pagination
          v-model:current="lazyCurrentPage"
          :total="total || filteredData.length"
          :perPage="perPage"
        />
      </template>
    </OTable>
  </div>
</template>

<script lang="ts">
/** Interface copied from Oruga OTableColumn Props Docs */
export interface IOTableColumn<T = any> {
  customKey?: string | number
  customSort?: (a: any, b: any, isAsc: boolean) => number
  field: string
  headerSelectable?: boolean
  label: string
  numeric?: boolean
  position?: "left" | "centered" | "right"
  sortable?: boolean
  sticky?: boolean
  subheading?: string
  visible?: boolean
  width?: number | string
  tdAttrs?: (row: T) => Record<string, any>
}

export interface Tag {
  type: string
  value: string
  text?: string
}
</script>

<script
  setup
  lang="ts"
  generic="T extends {
    searchValue?: string;
    [key: string]: any;
  }"
>
import { debounce } from "lodash"
import numeral from "numeral"
import { computed, onBeforeUnmount, ref, watch } from "vue"

import Badge from "./Badge.vue"
import Heading from "./Heading.vue"
import Pagination from "./Pagination.vue"
import TlInput from "./TlInput.vue"
import Skeleton from "./Skeleton.vue"
import EmptyStateView from "./EmptyStateView.vue"
import { SortDirection } from "@/types/general"

const props = withDefaults(
  defineProps<{
    /**
     * Novel props that this component adds to the underlying `<o-table>` component.
     * Some of these are named the same as OTable's props, but they are not given to OTable.
     */
    title: string
    level?: number
    searchable?: boolean
    search?: string
    columns: IOTableColumn<T>[]
    backend?: boolean
    loading?: boolean
    filterTags?: Tag[]
    searchFieldTitle?: string
    extraHeaderClasses?: string[]
    extraTableClass?: string
    disableCount?: boolean
    /**
     * Props that are passed through to the underlying component
     */
    data: T[]
    selected?: T | null
    perPage?: number
    loadingRows?: number
    total?: number
    currentPage?: number
    checkable?: boolean
    checkedRows?: T[]
    detailed?: boolean
    detailKey?: string
    customDetailRow?: boolean
  }>(),
  {
    level: 1,
    searchable: false,
    search: "",
    backend: false,
    loading: false,
    data: () => [],
    filterTags: () => [],
    searchFieldTitle: "Search",
    extraHeaderClasses: () => [],
    extraTableClass: "",
    disableCount: false,
    selected: undefined,
    perPage: 20,
    loadingRows: 20,
    detailed: false,
    detailKey: undefined,
    customDetailRow: false,
  }
)

const emit = defineEmits<{
  /** Events copied from OTable. Using commented events is discouraged */
  (e: "sort", field: string, order: SortDirection): void
  (e: "page-change", page: number): void
  (e: "dblclick"): void
  (e: "contextmenu"): void
  (e: "cell-click"): void
  (e: "click"): void
  (e: "check"): void
  (e: "check-all"): void
  (e: "update:checkedRows", value: Array<T>): void
  // (e: "select"): void
  (e: "update:selected", value: T): void
  (e: "filters-change"): void
  (e: "details-open"): void
  (e: "details-close"): void
  (e: "update:openedDetailed"): void
  // (e: "mouseenter"): void
  // (e: "mouseleave"): void
  (e: "sorting-priority-removed"): void
  // (e: "dragstart"): void
  // (e: "dragend"): void
  // (e: "drop"): void
  // (e: "dragleave"): void
  // (e: "dragover"): void
  // (e: "columndragstart"): void
  // (e: "columndragend"): void
  // (e: "columndrop"): void
  // (e: "columndragleave"): void
  // (e: "columndragover"): void

  /** Custom events */
  (e: "update:search", value: string): void
  (e: "update:currentPage", num: number): void
  (e: "remove-filter", value: Tag): void
  (e: "clear-filters"): void
}>()

/** Only show skeleton tables when there isn't real data that can be rendered behind a spinner */
const skeleton = computed(() => props.loading && props.data.length === 0)

/* Internal lazy client side search and pagination */
const debouncedEmitSearchUpdate = debounce((value: string) => emit("update:search", value), 400)
const internalSearch = ref(props.search || "")
const lazySearch = computed({
  get: () => internalSearch.value,
  set: (value: string) => {
    if (!props.backend) internalCurrentPage.value = 1
    internalSearch.value = value
    debouncedEmitSearchUpdate(value)
  },
})
watch(
  () => props.search,
  value => {
    internalSearch.value = value
  }
)
const internalCurrentPage = ref(props.currentPage || 1)
const lazyCurrentPage = computed({
  get: () => internalCurrentPage.value,
  set: (value: number) => {
    internalCurrentPage.value = value
    emit("update:currentPage", value)
  },
})
watch(
  () => props.currentPage,
  value => {
    internalCurrentPage.value = value || 1
  }
)
const filteredData = computed(() => {
  if (skeleton.value) {
    const fakeRow: Record<string, string> = {}
    props.columns.forEach(c => (fakeRow[c.field] = ""))
    return new Array(props.loadingRows).fill(fakeRow)
  }
  if (!props.backend) {
    return props.data.filter(row => {
      const searchContent = row.searchValue?.toLowerCase() || Object.values(row).join("").toLowerCase()
      return searchContent.indexOf(internalSearch.value.toLowerCase()) >= 0
    })
  }
  return props.data
})

/** Append search query to filter tags so it's obvious when someone arrives at a page that it's being filtered by search terms */
const internalFilterTags = computed(() => {
  /** Only show if the parent element does v-model on search */
  if (props.search) {
    return props.filterTags.concat([
      {
        type: props.searchFieldTitle,
        value: props.search,
        text: props.search,
      },
    ])
  }
  return props.filterTags
})
function removeFilter(tag: Tag) {
  if (tag.type === props.searchFieldTitle) {
    lazySearch.value = ""
  } else {
    emit("remove-filter", tag)
  }
}
function clearFilters() {
  lazySearch.value = ""
  emit("clear-filters")
}
const isFiltered = computed(() => {
  return (props.data.length > 0 && filteredData.value.length === 0) || internalFilterTags.value.length > 0
})

/** Enforce backend properties */
if (props.backend) {
  if (props.perPage === undefined || props.total === undefined || props.currentPage === undefined) {
    throw new Error("When using backend pagination, you must provide `perPage`, `total` and `currentPage` props.")
  }
}

/** Keep track of loading state, and show more empathetic UX when it takes a long time */
const tooLongSeconds = 10
const wayTooLongSeconds = 30
let tooLongTimerId = null as null | NodeJS.Timeout
let wayTooLongTimerId = null as null | NodeJS.Timeout
const loadingTooLong = ref(false)
function clearTimers() {
  loadingTooLong.value = false
  if (tooLongTimerId) clearTimeout(tooLongTimerId)
  if (wayTooLongTimerId) clearTimeout(wayTooLongTimerId)
}
onBeforeUnmount(clearTimers)
watch(
  () => props.loading,
  (loading, oldLoading) => {
    if (!oldLoading && loading) {
      /** Trigger empathetic UX after tooLongSeconds */
      tooLongTimerId = setTimeout(() => {
        loadingTooLong.value = true
      }, tooLongSeconds * 1000)
      /** Elevate the loading bug to bug tracking after wayTooLongSeconds */
      wayTooLongTimerId = setTimeout(() => {
        throw new Error(`Table has been loading for way too long (> ${wayTooLongSeconds} seconds)`)
      }, wayTooLongSeconds * 1000)
    } else {
      clearTimers()
    }
  },
  { immediate: true }
)

const rowClass = () => "tl-standard-table-row"
</script>

<style
  lang="scss"
  scoped
>
@import "./TlPageHeader";

.longer-badge {
  margin-top: 80px;
}

.tl-standard-table {
  :deep(.dropdown-menu-active) {
    margin-top: $space-x-small;
    border: 1px solid $color-tidelift-blue-300;
    max-height: 70vh;
    overflow-y: scroll;
    min-width: 280px;

    /* z-index allows droppdown menu to sit above disabled/loading dimming pane (z29) */
    z-index: 30;
  }

  @include themify {
    :deep(.b-table) {
      .tl-standard-table-row {
        &.is-selected,
        &:hover {
          color: inherit;
          background-color: themed("color-background-secondary-highlight");
        }

        td {
          vertical-align: middle;
        }
      }

      table th {
        font-weight: 800;
        font-size: $font-size-small;
      }
    }
  }

  :deep(.b-table) {
    .detailed-chevron > .icon {
      color: $color-blue-500 !important;
    }
  }
}
</style>
