<template>
  <span
    class="dropdown-container"
    :class="$attrs.class"
  >
    <template v-if="label">
      <label
        class="dropdown-label"
        :for="id"
        >{{ label }}</label
      >
    </template>
    <OSelect
      v-bind="$attrs"
      :id="id"
      class="dropdown"
    >
      <slot />
    </OSelect>
  </span>
</template>

<script>
/**
 * A simple wrapper around the Oruga Select dropdown.
 *
 * If the `label` prop is used, a label will display above the dropdown.
 *
 * The `id` prop is a unique id and should be used whenever `label` is used for proper a11y.
 */
export default {
  name: "Dropdown",
  status: "ready",
  inheritAttrs: false,
  props: {
    label: { type: String, optional: true },
    id: { type: String, optional: true },
  },
}
</script>
