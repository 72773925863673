import type {
  LiftablePlatform,
  SupportedSubscriberPlatform,
  PackageQualityChecks,
  PackageSummary,
  BlockedItem,
  PackageAttestation,
  PackageVulnerability,
  DepciDependencyResponse,
} from "@/types/package"
import type { PaginatedResponse, PaginationParams, SortDirection } from "@/types/general"
import type { PackageFragment } from "@/types/package"
import type { DependencyIssue, PackageUsage } from "@/types/dependency"
import type { CatalogPlatformsVariant } from "@/types/release"
import type { AxiosRequestConfig } from "axios"
import type { CatalogParams } from "@/types/catalog"

import { RequiredParams, t, client, RequiredData } from "./utils"
import { PackageVulnerabilities } from "@/types/package"

export function fetchPackageQualityChecks(packageId: number) {
  const url = t("package/{packageId}/quality_checks", { packageId })
  return client.get<PackageQualityChecks>(url)
}

export function fetchPackageAttestations(
  urlParams: PackageFragment,
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("package/{platform}/{name}/attestations", urlParams)
  return client.get<PackageAttestation[]>(url, config)
}

export function fetchPackageDependencies(
  urlParams: PackageFragment,
  config?: RequiredParams<{ version?: string | null }>
) {
  const url = t("package/{platform}/{name}/dependencies", urlParams)
  return client.get<DepciDependencyResponse>(url, config)
}

export function fetchDependencyIssues(urlParams: PackageFragment, config: AxiosRequestConfig | undefined = undefined) {
  const url = t("package/{platform}/{name}/dependency_issues", urlParams)
  return client.get<DependencyIssue[]>(url, config)
}

export function fetchPackageVersionNumbers(
  urlParams: PackageFragment,
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("package/{platform}/{name}/version_numbers", urlParams)
  return client.get<string[]>(url, config)
}

/**
 * Retrieve packages in a given organization.
 * https://github.com/tidelift/dependencyci/pull/8663
 */
export function fetchPackages(
  urlParams: { repoType: string; organization: string },
  config: RequiredParams<
    PaginationParams & {
      sort_by?: string
      sort_dir?: SortDirection
      filter: {
        platforms?: string
        catalogs?: string
        name?: string
      }
    }
  >
) {
  const url = t("{repoType}/{organization}/packages", urlParams)
  return client.get<PaginatedResponse<PackageSummary> & CatalogPlatformsVariant>(url, config)
}

export function fetchPackageVulnerabilities(
  urlParams: { platform: string; name: string },
  config: RequiredParams<
    PaginationParams & {
      org_type?: string
      org_name?: string
      catalog_name?: string
      sort_by?: string
      sort_dir?: string
      filter: {
        statuses?: string[]
        version?: string
        release_types?: string[]
      }
    }
  >
) {
  const url = t("package/{platform}/{name}/vulnerabilities", urlParams)
  return client.get<PaginatedResponse<PackageVulnerabilities>>(url, config)
}

export function fetchPackageVulnerability(
  urlParams: { platform: string; name: string },
  config: RequiredParams<{
    cve_id: string
    org_name: string
    org_type: string
  }>
) {
  const url = t("package/{platform}/{name}/vulnerability", urlParams)
  return client.get<PackageVulnerability>(url, config)
}

export function fetchPackageUsage(
  urlParams: { repoType: string; organization: string } & PackageFragment,
  config: RequiredParams<
    PaginationParams & {
      filter?: {
        statuses?: string[]
        version?: string
        release_types?: string[]
      }
      sort_by?: string
      sort_dir?: string
    }
  >
) {
  const url = t("{repoType}/{organization}/packages/{platform}/{name}/project_usage", urlParams)
  return client.get<PaginatedResponse<PackageUsage>>(url, config)
}

export function fetchFundedPackages(config: AxiosRequestConfig | undefined = undefined) {
  return client.get<{ platform: SupportedSubscriberPlatform; name: string }[]>("package/funded", config)
}

interface BlockedItemParams {
  platform: string
  package_name: string
  version: string
  reason: string
}

export function getBlockedItems(
  urlParams: CatalogParams,
  config: RequiredParams<
    PaginationParams & {
      name_search?: string
      platforms: LiftablePlatform[]
    }
  >
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/blocked_items", urlParams)
  return client.get<PaginatedResponse<BlockedItem>>(url, config)
}

export function refreshReleaseManagers(urlParams: PackageFragment) {
  const url = t("package/{platform}/{name}/refresh_release_managers", urlParams)
  return client.put(url)
}

export function addBlockedItemBulk(
  urlParams: CatalogParams,
  config: RequiredData<{
    blocked_items: BlockedItemParams[]
  }>
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/blocked_items/bulk", urlParams)
  return client.post<BlockedItem[]>(url, config.data, config)
}

export function addBlockedItemsRange(
  urlParams: CatalogParams,
  config: RequiredData<{
    platform: string
    package_name: string
    version_high: string | null
    version_low: string | null
    reason: string
  }>
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/blocked_items/by_range", urlParams)
  return client.post(url, config.data, config)
}

export function updateBlockedItem(
  urlParams: CatalogParams & { blockedItemId: string },
  config: RequiredData<{
    reason: string
  }>
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/blocked_items/{blockedItemId}", urlParams)
  return client.put(url, config.data, config)
}

export function deleteBlockedItem(
  urlParams: CatalogParams & { blockedItemId: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/blocked_items/{blockedItemId}", urlParams)
  return client.delete<BlockedItem>(url, config)
}

export function deleteBlockedItemsBulk(
  urlParams: CatalogParams,
  config: RequiredData<{
    ids: string[]
  }>
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/blocked_items/bulk_delete", urlParams)
  return client.post<BlockedItem[]>(url, config.data, config)
}
