<template>
  <div
    :aria-label="label"
    :title="title"
    class="bar"
    :class="color"
    :style="{ width: widthStyle }"
  />
</template>

<script>
/**
 * This is a simple horizontal bar used to display values as a percent of a whole.
 */

const VALID_COLORS = ["green", "orange", "purple", "gray", "red", "blue"]
export default {
  name: "TlBar",
  props: {
    width: { type: Number, default: 100 },
    label: { type: String, required: true },
    title: { type: String, required: true },
    color: {
      type: String,
      default: "blue",
      validator: function (value) {
        // The value must match one of these strings
        return VALID_COLORS.indexOf(value) !== -1
      },
    },
  },
  computed: {
    widthStyle() {
      return `${this.width}%`
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.bar {
  display: inline-block;
  height: 1rem;
  vertical-align: middle;
  border-radius: 1rem;
  margin-bottom: $space-x-small;
  min-width: 1rem;

  @include themify {
    &.green {
      background-color: themed("color-graph-green");
    }

    &.orange {
      background-color: themed("color-graph-orange");
    }

    &.purple {
      background-color: themed("color-graph-purple");
    }

    &.gray {
      background-color: themed("color-graph-gray");
    }

    &.red {
      background-color: themed("color-graph-red");
    }

    &.blue {
      background-color: themed("color-graph-blue");
    }
  }
}
</style>
