import axios from "axios"
import { defineStore } from "pinia"
import { cloneDeep, pick } from "lodash"
import type { Catalog, CatalogBase, CatalogParams, CatalogListItem, LicenseRules, Standard } from "@/types/catalog"
import type { PaginatedResponse } from "@/types/general"
import type { Subscription } from "@/types/organization"
import type { CatalogTaskQueryParams, Task } from "@/types/task"

import depci from "@/services/depci"
import apiClient from "@/services/api"
import { markRaw } from "vue"
import nsLocalStorage from "@/services/nsLocalStorage"
import api from "@/services/api"
import { toast } from "@/services/notification"
import { METRICS_EVENT, useMetrics } from "@/services/metrics"
import { useFeatureStore } from "./features"

export const CATALOG_RELEASE_WITH_TASK_CACHE_TIME = 10000 // 10 seconds

// Key is the new standard_slug, value is the old standard_slug
// values can be removed once the old standard_slug is no longer sent by BE
// Empty now, but please keep this in place until we've got the slugs renamed
export const STANDARD_RENAMES: Record<string, string> = {}

interface State {
  loadingCount: number
  catalogsForOrg: CatalogListItem[]
  catalogsForOrgAbortController: AbortController
  currentOrg: {
    organization: string
    repoType: string
  } | null
  lastActiveCatalogMap: Record<string, string>
  catalog: Catalog | null
  catalogUpdatedAt: number | null
  allCatalogStandards: Standard[] /** All optional standards for a catalog */
  catalogTasks: PaginatedResponse<Task> | null
  catalogTasksAbortController: AbortController
  catalogReleaseWithTask: Task | null
  catalogReleaseWithTaskUpdatedAt: number | null
  subscriptions: Subscription[]
  catalogDetailsUpdating: boolean
  licensesByOrganizationCatalog: Record<string, undefined | LicenseRules>
}

function catalogParamsToKey(catalogParams: CatalogParams) {
  return `${catalogParams.repoType}/${catalogParams.organization}/${catalogParams.catalogName}`
}

export const useCatalogsStore = defineStore("catalogs", {
  state: (): State => ({
    loadingCount: 0,
    catalogsForOrg: [],
    catalogsForOrgAbortController: markRaw(new AbortController()),
    currentOrg: null,
    lastActiveCatalogMap: {},
    catalog: null,
    catalogUpdatedAt: null,
    allCatalogStandards: [],
    catalogTasks: null,
    catalogReleaseWithTask: null,
    catalogReleaseWithTaskUpdatedAt: null,
    subscriptions: [],
    catalogDetailsUpdating: false,
    licensesByOrganizationCatalog: {},
    // Unreactive abortController cancels previous requests
    // And makes sure that the data displayed is for the latest request only
    // if the user changes the page or filters before the previous request completes
    catalogTasksAbortController: markRaw(new AbortController()),
  }),
  getters: {
    isLoadingCatalogData(state) {
      return state.loadingCount > 0
    },
    defaultCatalog(state) {
      return state.catalogsForOrg.find(c => c.name === "default") || state.catalogsForOrg[0]
    },
    catalogHasPackages(state) {
      return state.catalog?.has_catalog_releases || false
    },
    catalogs(state) {
      return state.catalogsForOrg
    },
    catalogName(state) {
      return state.catalog?.name
    },
    catalogDisplayName(state) {
      return state.catalog?.display_name
    },
    // TODO: delete isCatalogAdministrator after all uses are removed
    isCatalogAdministrator(state) {
      return !!state.catalog?.administrator
    },
    repoRoute(_): { organization?: string; repoType?: string; catalogName?: string } {
      return pick(this.$router.currentRoute.value.params, ["organization", "repoType", "catalogName"])
    },
    repoTypeAndOrg(_): { organization?: string; repoType?: string } {
      return pick(this.$router.currentRoute.value.params, ["organization", "repoType"])
    },
    catalogsNeedReload(state): boolean {
      const { organization, repoType } = this.repoTypeAndOrg
      return state.currentOrg?.organization !== organization || state.currentOrg?.repoType !== repoType
    },
    catalogInOrg(_): (catalog: CatalogBase | null) => boolean {
      const { organization, repoType } = this.repoRoute
      return catalog => {
        return !!catalog && catalog.org_name === organization && catalog.org_type === repoType && !!catalog.id
      }
    },
    catalogId(state): string | null {
      const { catalogName } = this.repoRoute
      if (this.catalogInOrg(state.catalog) && state.catalog?.name === catalogName) {
        return state.catalog?.id || null
      } else if (this.catalogInOrg(this.defaultCatalog) && catalogName === "default") {
        return this.defaultCatalog.id
      } else {
        return null
      }
    },
    standardForSlug(state) {
      return (standardSlug: string) => {
        const oldStandardSlug = STANDARD_RENAMES[standardSlug]
        return state.allCatalogStandards.find(s => s.slug === standardSlug || s.slug === oldStandardSlug)
      }
    },
    standardEnabled(state) {
      return (standardSlug: string) => {
        const standards = state.catalog?.catalog_standards || []
        const oldStandardSlug = STANDARD_RENAMES[standardSlug]
        const found = standards.find(s => s.slug === standardSlug || s.slug === oldStandardSlug)
        return !!found
      }
    },
    canUseReleaseWithTasksCache(state) {
      return ({ platform, packageName, version }: { platform: string; packageName: string; version: string }) => {
        let useCache = true

        if (!useFeatureStore().isFeatureEnabled("subscriber:cached_catalog_release_tasks")) {
          useCache = false
        }

        if (!state.catalogReleaseWithTaskUpdatedAt) {
          useCache = false
        } else if (state.catalogReleaseWithTaskUpdatedAt + CATALOG_RELEASE_WITH_TASK_CACHE_TIME < +new Date()) {
          useCache = false
        } else if (!state.catalogReleaseWithTask) {
          useCache = false
        } else {
          const { catalogReleaseWithTask } = state

          if (catalogReleaseWithTask.platform !== platform || catalogReleaseWithTask.name !== packageName) {
            useCache = false
          } else if (catalogReleaseWithTask.version && catalogReleaseWithTask.version !== version) {
            useCache = false
          } else if (!catalogReleaseWithTask.version && version !== "all") {
            useCache = false
          }
        }

        return useCache
      }
    },
    lastActiveCatalogName(state): string | undefined {
      const { organization } = this.repoRoute
      if (!organization) return undefined
      return state.lastActiveCatalogMap[organization]
    },
    organizationCatalogLicenses(state) {
      return (catalogParams: CatalogParams) => state.licensesByOrganizationCatalog[catalogParamsToKey(catalogParams)]
    },
    orgAndCatalog(): string {
      return `${this.repoRoute.organization}/${this.catalogName}`
    },
  },
  actions: {
    async createCatalog(params: { organization: string; catalogName: string; description: string }) {
      await api.createCatalog(
        { organization: params.organization, repoType: "team" },
        {
          data: {
            catalog_name: params.catalogName,
            description: params.description,
          },
        }
      )
      useMetrics().event(METRICS_EVENT.NEW_CATALOG_CREATED, {
        catalog_name: params.catalogName,
      })
      // Refresh the list of catalogs
      this.loadCatalogs()
    },
    async loadRecentCatalog(catalogParams: CatalogParams) {
      const key = `${catalogParams.organization}/lastRecentCatalog`
      const lastRecentCatalog = await nsLocalStorage.getItem(key)
      if (lastRecentCatalog && this.catalogsForOrg.find(c => c.name === lastRecentCatalog)) {
        this.lastActiveCatalogMap[catalogParams.organization] = lastRecentCatalog
      } else {
        this.lastActiveCatalogMap[catalogParams.organization] = catalogParams.catalogName
      }
    },
    async setLastRecentCatalog(catalogParams: CatalogParams) {
      const key = `${catalogParams.organization}/lastRecentCatalog`
      const lastRecentCatalog = await nsLocalStorage.getItem(key)
      if (lastRecentCatalog !== catalogParams.catalogName) {
        await nsLocalStorage.setItem(key, catalogParams.catalogName)
        this.lastActiveCatalogMap[catalogParams.organization] = catalogParams.catalogName
      }
    },
    async fetchCatalogsForOrg(params: { organization: string; repoType: string }) {
      try {
        if (this.catalogsForOrgAbortController) this.catalogsForOrgAbortController.abort()
        // Every request requires a fresh abortController
        this.catalogsForOrgAbortController = new AbortController()
        this.loadingCount += 1
        this.catalogsForOrg = []
        this.currentOrg = params
        useMetrics().setUserProperties({
          organization: params.organization,
        })
        const catalogs = await api.fetchCatalogs(params, {
          signal: this.catalogsForOrgAbortController.signal,
        })
        this.catalogsForOrg = catalogs.data
        if (catalogs.data.length > 0) {
          this.loadRecentCatalog({
            ...params,
            catalogName: catalogs.data[0].name,
          })
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          // Suppress errors thrown by manual cancelation
          return null
        }
        throw error
      } finally {
        this.loadingCount -= 1
      }
    },
    async fetchCatalog(params: { organization: string; repoType: string; catalogName: string }) {
      try {
        this.loadingCount += 1
        this.catalog = null
        const catalog = await depci.fetchCatalog(params)
        this.catalog = catalog
        this.catalogUpdatedAt = +new Date()
        this.setLastRecentCatalog(params)
        useMetrics().setUserProperties({
          catalogName: catalog.name,
          catalogId: catalog.id,
          organization: catalog.org_name,
          isCatalogAdministrator: !!catalog.administrator,
        })
      } finally {
        this.loadingCount -= 1
      }
    },
    async fetchPaginatedCatalogTasks(catalogParams: CatalogParams, queryParams: CatalogTaskQueryParams) {
      try {
        if (this.catalogTasksAbortController) this.catalogTasksAbortController.abort()
        // Every request requires a fresh abortController
        this.catalogTasksAbortController = new AbortController()
        this.loadingCount += 1
        const tasks = await apiClient.fetchPaginatedCatalogTasks(catalogParams, {
          params: queryParams,
          signal: this.catalogTasksAbortController.signal,
        })
        this.catalogTasks = tasks.data
        return tasks.data
      } catch (error) {
        if (axios.isCancel(error)) {
          // Suppress errors thrown by manual cancelation
          return null
        }
        throw error
      } finally {
        this.loadingCount -= 1
      }
    },
    clearCatalogTasks() {
      this.catalogTasks = null
    },
    async fetchSingleCatalogTaskAsTasks(params: {
      organization: string
      repoType: string
      catalogName: string
      taskId: string
    }) {
      try {
        // this is used to fetch a single catalog task like fetchPaginatedCatalogTasks
        this.loadingCount += 1
        const response = await depci.fetchCatalogTaskById(params)
        this.catalogReleaseWithTask = response
        this.catalogReleaseWithTaskUpdatedAt = +new Date()
      } finally {
        this.loadingCount -= 1
      }
    },
    async fetchAllCatalogStandards(params: { organization: string; repoType: string }) {
      try {
        this.loadingCount += 1
        const standards = (await api.fetchAllCatalogStandards(params)).data
        this.allCatalogStandards = standards
      } finally {
        this.loadingCount -= 1
      }
    },
    async enableOrDisableStandard(
      isEnabling: boolean,
      standardName: string,
      params: CatalogParams & { standardSlug: string }
    ) {
      useMetrics().event(METRICS_EVENT.STANDARD_CONFIG_TOGGLED, {
        standard: params.standardSlug,
        enabled: isEnabling,
      })
      try {
        if (isEnabling) {
          await apiClient.subscribeCatalogStandard(params)
        } else {
          await apiClient.unsubscribeCatalogStandard(params)
        }
        toast({
          message: `Successfully ${isEnabling ? "enabled" : "disabled"} the "${standardName}" standard.`,
          variant: "success",
          duration: 5000,
        })
      } catch (e) {
        toast({
          message: `Failed to ${isEnabling ? "enable" : "disable"} the "${standardName}" standard.`,
          variant: "danger",
          duration: 5000,
        })
      }
    },
    async updateCatalogDetails(updates: Record<string, any>) {
      try {
        this.catalogDetailsUpdating = true
        const updatedCatalog = await depci.updateCatalog(this.repoRoute, updates)
        // catalog is a sparse model, so 'state.catalog' is some combination of [index, details, overview]
        // we don't want to override with index (returned from updateCatalog), only update the parts that changed
        const catalogMutation: Partial<Catalog> = cloneDeep(this.catalog || {})
        catalogMutation.display_name = updatedCatalog?.display_name
        catalogMutation.description = updatedCatalog?.description
        this.catalog = updatedCatalog
        this.catalogUpdatedAt = +new Date()
      } finally {
        this.catalogDetailsUpdating = false
      }
    },
    loadCatalogs() {
      const { organization, repoType } = this.repoRoute
      if (organization && repoType) {
        return this.fetchCatalogsForOrg({ organization, repoType })
      } else {
        throw new Error("loadCatalogs requires organization and repoType")
      }
    },
    fetchCurrentCatalog() {
      const { organization, repoType, catalogName } = this.repoRoute
      if (organization && repoType && catalogName) {
        return this.fetchCatalog({ organization, repoType, catalogName })
      } else {
        throw new Error("fetchCurrentCatalog requires organization, repoType, and catalogName")
      }
    },
    async fetchOrganizationCatalogLicenses(catalogParams: CatalogParams) {
      const result = await depci.fetchCatalogRules({
        repoType: catalogParams.repoType,
        organization: catalogParams.organization,
        catalogName: catalogParams.catalogName,
        catalogStandard: "allowed_licenses",
      })

      this.licensesByOrganizationCatalog[catalogParamsToKey(catalogParams)] = result
    },
  },
})
