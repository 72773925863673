<template>
  <div
    class="banner"
    :class="type"
  >
    <i
      v-if="icon"
      :class="`fas fa-${icon} banner-icon`"
    />
    <span><slot /></span>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed } from "vue"

const ICON_MAP: any = {
  success: "check-circle",
  info: "info-circle",
  warning: "exclamation-triangle",
  danger: "exclamation-circle",
}

const props = defineProps({
  type: {
    type: String,
    default: "default",
    validator: (type: string) => ["default", "success", "info", "warning", "danger", "orange"].includes(type),
  },
})

const icon = computed(() => ICON_MAP[props.type])
</script>

<style
  lang="scss"
  scoped
>
.banner {
  margin: 1.5rem 0;
  padding: $space-base;
  display: flex;
  line-height: 1.5rem;
  font-size: $font-size-base;
  font-family: $font-family-heading;
  border-radius: 0.25rem;

  > span {
    margin-top: -1px;
  }

  &.default {
    color: $color-tidelift-blue-700;
    background-color: $color-tidelift-blue-200;
  }

  &.inverted {
    color: $color-white;
    background-color: $color-tidelift-blue-500;
  }

  &.orange {
    color: $color-lifter-orange-700;
    background-color: $color-lifter-orange-200;
  }

  &.success {
    color: $color-green-700;
    background-color: $color-green-300;
  }

  &.info {
    color: $color-blue-700;
    background-color: $color-blue-300;
  }

  &.warning {
    color: $color-yellow-700;
    background-color: $color-yellow-300;
  }

  &.danger {
    color: $color-red-700;
    background-color: $color-red-300;
  }

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: $font-weight-semi-bold;
  }
}

.banner-icon {
  position: relative;
  top: 2px;
  margin-right: $space-x-small;
  font-size: 1.25rem;
}
</style>
