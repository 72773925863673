<template>
  <span class="search-select-container has-themed-text">
    <div class="search-label">
      <Heading :level="2">{{ searchLabel }}</Heading>
      <a
        @click="clearSelections"
        :class="{ disabled }"
        >Reset</a
      >
    </div>
    <slot />
    <div class="column">
      <TlInput
        v-model="searchTerm"
        :placeholder="searchPlaceholder"
        iconPack="fas"
        icon="search"
        type="search"
        @update:modelValue="handleInput"
        :disabled="disabled"
      />
      <Skeleton v-if="isLoadingSearchResults" />
      <div
        v-else-if="searchedOptionsToShow.options.length > 0 && !disabled"
        class="searched-options"
      >
        <OCheckbox
          v-for="item of searchedOptionsToShow.options"
          :key="item.value"
          v-model="selectedItemsLocal"
          class="checkbox-option"
          :nativeValue="item.value"
        >
          {{ item.text }}
        </OCheckbox>
      </div>
      <p class="select-label">{{ selectLabel }}</p>
      <Skeleton v-if="isLoadingOptions" />
      <div v-else>
        <OCheckbox
          v-for="item of optionsToShow"
          :key="item.value"
          v-model="selectedItemsLocal"
          class="checkbox-option"
          :nativeValue="item.value"
          :disabled="disabled"
        >
          {{ item.text }}
        </OCheckbox>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import _ from "lodash"

import TlInput from "./TlInput.vue"
import Skeleton from "./Skeleton.vue"
import Heading from "./Heading.vue"

export interface SearchSelectOption {
  text: string
  value: string
}

type SearchOptions = (search: string) => Promise<SearchSelectOption[]> | SearchSelectOption[]

export default defineComponent({
  name: "SearchSelect",
  status: "ready",
  components: { TlInput, Skeleton, Heading },
  props: {
    searchLabel: { type: String, default: "Search" },
    selectLabel: { type: String, default: "Select" },
    searchPlaceholder: { type: String, default: "search" },
    /**
     * The `optionsToShow` checkboxes show a pre-determined selection of options (projects with recent updates, etc.).
     */
    optionsToShow: { type: Array as PropType<SearchSelectOption[]>, required: true },
    disabled: { type: Boolean, default: false },
    isLoadingSearchResults: { type: Boolean, default: false },
    isLoadingOptions: { type: Boolean, default: false },
    /**
     * value is anything that gets checked/selected.
     */
    modelValue: { type: Array as PropType<SearchSelectOption[]>, default: () => [] },
    /**
     * Plug in an API call or other search functionality in searchOptions. This function should return an array.
     */
    searchOptions: { type: Function as PropType<SearchOptions>, required: true },
  },
  data() {
    return {
      searchTerm: "",
      searchedOptionsToShow: { options: [] as SearchSelectOption[] },
      debouncedSubmitSearch: null as (() => void) | null,
      displayedOptionsCache: [...this.optionsToShow],
    }
  },
  methods: {
    clearSelections() {
      this.searchedOptionsToShow.options = []
      this.searchTerm = ""
      this.$emit("update:modelValue", [])
    },
    async submitSearch() {
      const optionsToShowSet = new Set(this.optionsToShow.map(option => option.value))
      const selectedSearchOptionsSet = new Set(this.modelValue.map(option => option.value))
      if (this.searchTerm.length > 0) {
        this.searchedOptionsToShow.options = (await this.searchOptions(this.searchTerm))
          .filter(result => !optionsToShowSet.has(result.value) && !selectedSearchOptionsSet.has(result.value))
          .concat(this.modelValue)
        this.searchedOptionsToShow.options.forEach(option => {
          if (!this.displayedOptionsCache.find(o => _.isEqual(o, option))) {
            this.displayedOptionsCache.push(option)
          }
        })
      } else {
        this.searchedOptionsToShow.options = this.modelValue
      }
    },
    handleInput() {
      this.debouncedSubmitSearch?.apply(this)
    },
  },
  computed: {
    selectedItemsLocal: {
      get: function () {
        return this.modelValue.map(option => option.value)
      },
      set: function (arrayOfCheckboxIds: number[]) {
        const arrayOfOptions = _.compact(
          arrayOfCheckboxIds.map(id => _.find(this.displayedOptionsCache, { value: id }))
        )
        this.$emit("update:modelValue", arrayOfOptions)
      },
    },
  },
  mounted() {
    this.debouncedSubmitSearch = _.debounce(this.submitSearch, 250)

    // Show selected options that otherwise do not appear without a performed search
    const selectedOptionsFromPreviousSearch = this.modelValue.filter(
      result => !this.optionsToShow.find(o => _.isEqual(o, result))
    )
    this.searchedOptionsToShow.options = selectedOptionsFromPreviousSearch
    this.displayedOptionsCache = this.displayedOptionsCache.concat(selectedOptionsFromPreviousSearch)
  },
})
</script>

<style
  lang="scss"
  scoped
>
.search-select-container {
  display: flex;
  flex-direction: column;
}

.control {
  margin-bottom: $space-base;
}

.search-label {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  a {
    margin-left: auto;
  }
}

.select-label {
  margin-bottom: $space-x-small;
}

.checkbox-option {
  width: 48%;
  align-items: center;
  padding-bottom: 4px;
  word-wrap: break-word;

  :deep(.control-label) {
    max-width: 98%;
  }
}

:deep(.b-checkbox.checkbox) {
  input[type="checkbox"] {
    opacity: inherit;
    z-index: inherit;
  }

  .control-label {
    padding-left: calc(1.25em - 1px);
  }
}

.searched-options {
  border-bottom: solid $color-border 1px;
  margin-bottom: $space-base;
  padding-bottom: $space-base;
}

.column {
  padding: 0;
}
</style>
