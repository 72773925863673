const GetStarted = () => import("@/components/pages/getstarted/GetStarted")

export default [
  {
    path: "",
    meta: {
      requireAuth: true,
    },
    name: "GetStarted",
    component: GetStarted,
  },
]
