<template>
  <OInput
    class="tl-input"
    v-bind="$attrs"
    ref="input"
  />
</template>

<script>
export default {
  name: "TlInput",
  status: "ready",
  props: {
    focusOnMount: { type: Boolean, default: false },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
  mounted() {
    if (this.focusOnMount) {
      this.focus()
    }
  },
}
</script>

<style
  lang="scss"
  scoped
>
.tl-input {
  :deep(input),
  :deep(.input) {
    height: 2.5rem;

    &:not(.is-multiple)::after {
      border-color: $color-tidelift-blue;
    }
  }

  :deep(.icon) {
    width: 2rem !important;
    height: 2rem !important;

    i {
      font-size: 1rem;
      margin-top: 1.5px;
    }
  }

  &.has-icons-left :deep(.input) {
    padding-left: $space-x-large;
  }
}
</style>
