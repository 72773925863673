<template>
  <div
    class="tl-badge type-colors"
    :class="badgeType"
  >
    <slot />
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed } from "vue"

const props = defineProps({
  type: {
    type: String,
    validator: (type: string) =>
      ["tidelift", "info", "success", "warning", "danger", "purple", "default"].includes(type),
  },
  square: {
    type: Boolean,
    default: false,
  },
})

const badgeType = computed(() => [props.type, { square: props.square }])
</script>

<style
  scoped
  lang="scss"
>
@import "../styles/type-colors";

.tl-badge {
  display: inline-flex;
  align-items: center;
  border-radius: 1.25rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: $font-size-small;
  font-weight: 600;
  white-space: nowrap;
  margin-left: $space-x-small;
  padding: 0 0.75rem;
  height: 1.5rem;
  letter-spacing: 0.3px;
  position: relative;
  top: -3px;
  cursor: default;
}

.square {
  border-radius: $border-radius-default;
  font-weight: $font-weight-semi-bold;
  justify-content: center;
  min-width: 1.5rem;
  padding: 0 $space-xx-small;
  top: 0;
}
</style>
