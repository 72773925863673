import type { CatalogReport, TeamReport } from "@/types/reports"
import { useCatalogsStore } from "@/store/pinia/catalogs"
import { computed } from "vue"

export interface CatalogReportForDisplay {
  ready_for_rerun: boolean
  updated_at: string
  reportType: string
  catalogName: string
  downloadAvailable: boolean
  downloadTsvUrl: string
  downloadJsonUrl: string
}

export interface TeamReportForDisplay {
  reportType: string
  downloadTsvUrl: string
  downloadJsonUrl: string
}

export const useReports = (props: { repoType: string; organization: string; catalogName?: string }) => {
  const prepareTeamReportForDisplay = ({
    report,
    reportType,
  }: {
    report: TeamReport
    reportType: string
  }): TeamReportForDisplay => {
    return Object.assign({}, report, {
      reportType,
      downloadTsvUrl: downloadUrl("team", reportType, true, "tsv"),
      downloadJsonUrl: downloadUrl("team", reportType, true, "json"),
    })
  }
  const prepareCatalogReportForDisplay = ({
    report,
    reportType,
    catalogName,
  }: {
    report: CatalogReport
    reportType: string
    catalogName: string
  }): CatalogReportForDisplay => {
    const downloadAvailable = report.updated_at != null

    return Object.assign({}, report, {
      catalogName,
      reportType,
      downloadAvailable,
      downloadTsvUrl: downloadUrl("catalog", reportType, downloadAvailable, "tsv"),
      downloadJsonUrl: downloadUrl("catalog", reportType, downloadAvailable, "json"),
    })
  }

  const downloadUrl = (scope: "team" | "catalog", reportType: string, downloadAvailable: boolean, fileType: string) => {
    if (scope === "team") {
      return `/api/depci/${props.repoType}/${props.organization}/reports/${reportType}/${fileType}`
    } else {
      return downloadAvailable
        ? `/api/depci/${props.repoType}/${props.organization}/catalogs/${props.catalogName}/reports/${reportType}/${fileType}`
        : ""
    }
  }

  // returns an array of catalogs for which the user has download_report permission
  const catalogReportsForUser = computed(() => {
    const catalogs = useCatalogsStore().catalogs
    return catalogs.filter(catalog => catalog.policies.reports.download_report === true)
  })

  return {
    prepareCatalogReportForDisplay,
    prepareTeamReportForDisplay,
    catalogReportsForUser,
  }
}
