<template>
  <Badge class="tl-tag">
    <span><slot /></span
    ><span
      role="button"
      @click="$emit('dismissed', value)"
      ><OIcon
        pack="fas"
        icon="times"
        class="tl-tag-icon"
    /></span>
  </Badge>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import Badge from "./Badge.vue"

export default defineComponent({
  name: "Tag",
  components: { Badge },
  props: {
    value: { type: String, required: true },
  },
})
</script>

<style
  scoped
  lang="scss"
>
.tl-tag {
  display: inline-flex;
  font-size: $font-size-small;
  margin-bottom: $space-x-small;
  margin-left: 0;

  &:not(:last-of-type) {
    margin-right: $space-x-small;
  }
}

.tl-tag-icon {
  font-size: 0.75rem;
  cursor: pointer;
}

.tl-badge {
  margin-left: 0;
  padding-right: 0;
}
</style>
