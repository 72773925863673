import { merge } from "lodash"
import type { UserSettings } from "@/types/authentication"
import type { AxiosRequestConfig } from "axios"
import type { APIKey } from "@/types/api_keys"
import { Group, GroupBase } from "@/types/group"

import { RequiredData, client, t } from "./utils"

export function fetchSettings(config?: AxiosRequestConfig) {
  return client.get<UserSettings>(
    "user/settings",
    merge(
      {
        params: {
          organization_feature_flags: "v2",
        },
      },
      config || {}
    )
  )
}

export function fetchGroups(urlParams: { organization: string }, config?: AxiosRequestConfig) {
  const url = t(`team/{organization}/groups`, urlParams)
  return client.get<Group[]>(url, config)
}

export function createGroup(urlParams: { organization: string }, config: RequiredData<{ group_name: string }>) {
  const url = t(`team/{organization}/groups`, urlParams)
  return client.post<GroupBase>(url, config.data, config)
}

export function validateSpdxExpression(config: RequiredData<{ license: string }>) {
  return client.post<{ valid: boolean }>("spdx_list/validate", config.data, config)
}

export function fetchOrgScopedApiKeys(
  { repoType, organization }: { repoType: string; organization: string },
  config?: AxiosRequestConfig
) {
  const url = `api-keys/${repoType}/${organization}`
  return client.get<APIKey[]>(url, config)
}

export function fetchLatestVersion() {
  return client.get<string>("/VERSION", {
    responseType: "text",
    baseURL: "/",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  })
}
