<template>
  <nav
    class="sidebar-container"
    :class="`${highlight}`"
  >
    <div class="sidebar-contents py-4">
      <slot />
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

export default defineComponent({
  props: {
    highlight: { type: String as PropType<"catalog" | "project" | "package">, required: true },
  },
})
</script>

<style
  lang="scss"
  scoped
>
.sidebar-container {
  height: 100%;
  width: 14rem;
  min-height: calc(100vh - 65px - 108px);
  background-color: $color-tidelift-blue-100;
  position: relative;
  z-index: 1;

  @include mobile {
    width: 100%;
    min-height: 0;
    height: auto;
  }

  .sidebar-contents {
    position: sticky;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
</style>

<!-- unscoped link styles -->
<style lang="scss">
.theme-light .sidebar-container {
  a {
    font-family: $font-family-heading;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    color: $color-tidelift-blue-700;
    padding-left: $space-x-large;
    padding-top: $space-base;
    padding-bottom: $space-base;
    width: 100%;
    display: block;

    &:hover {
      background-color: $color-tidelift-blue-200;
    }

    &.is-active,
    &.router-link-active {
      background-color: $color-tidelift-blue-600;
      color: white;
    }
  }

  /* highlight colors */
  &.catalog {
    a.is-active,
    a.router-link-active {
      box-shadow: inset 4px 0 0 0 $color-concept-catalog;
    }
  }

  &.project {
    a.is-active,
    a.router-link-active {
      box-shadow: inset 4px 0 0 0 $color-concept-project;
    }
  }

  &.package {
    a.is-active,
    a.router-link-active {
      box-shadow: inset 4px 0 0 0 $color-concept-package;
    }
  }
}
</style>
