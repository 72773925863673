import Axios from "axios"
import { defineStore } from "pinia"

function inRange(x: number, min: number, max: number) {
  return x >= min && x <= max
}

export enum ErrorTypes {
  Unauthorized = "unauthorized",
  NotFound = "not_found",
  ServerError = "5xx",
  NetworkError = "network_error",
}

interface State {
  errorType: ErrorTypes | null
}

export const useGlobalErrorStore = defineStore("globalErrors", {
  state: (): State => ({
    errorType: null,
  }),
  actions: {
    /**
     * A crital request error is a type of API error that,
     * if encountered, is unrecoverable and means that the current route
     * cannot be rendered.  Calling this function will set the errorType
     * to show on a global error page.
     * @param error AxiosError or any other error
     */
    processCriticalRequestError(error: any) {
      if (Axios.isCancel(error)) {
        // Ignore cancel errors that might slip through the cracks.
        return
      }
      if (Axios.isAxiosError(error)) {
        if (!error.response) {
          this.errorType = ErrorTypes.NetworkError
        } else if (error.response.status === 404) {
          this.errorType = ErrorTypes.NotFound
        } else if (error.response.status === 403) {
          this.errorType = ErrorTypes.Unauthorized
        } else if (inRange(error.response.status, 500, 599)) {
          this.errorType = ErrorTypes.ServerError
        } else if (error.response.status === 401) {
          // Ignore unauthorized errors
          // They're handled by axios interceptors
          return
        }
      }
      // TODO: change implementation to https://github.com/tc39/proposal-error-cause once that standard is widely available
      // The point of this is to differentiate between a critical error and a non-critical error in bugsnag
      const criticalError = new Error("critical request failure caused by  " + String(error))
      throw criticalError
    },
    clearError() {
      this.errorType = null
    },
  },
})
