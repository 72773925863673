import { METRICS_EVENT } from "@/services/metrics"
import { Policies } from "@/store/pinia/policy"

const OnboardingTeamSelection = () => import("@/components/pages/team/OnboardingTeamSelection")
const CreateNewProject = () => import("@/components/pages/team/CreateNewProject")

export default [
  {
    path: "",
    redirect: { name: "OnboardingTeamSelection" },
  },
  {
    path: "onboard",
    name: "OnboardingTeamSelection",
    component: OnboardingTeamSelection,
  },
  {
    path: "onboard/:organization",
    name: "CreateNewProject",
    component: CreateNewProject,
    props: route => ({ repoType: "team", ...route.params }),
    meta: {
      requirePolicy: {
        scope: "team",
        policy: Policies.create_projects,
      },
      metrics: {
        event: METRICS_EVENT.CATALOG_IMPORT_PROJECT_PAGE_VIEWED,
      },
    },
  },
]
