import { useAuthStore } from "@/store/pinia/auth"
import { needsUpdate } from "@/services/checkFrontendVersion"
import { createRouter, createWebHistory, RouteLocationNormalized } from "vue-router"
import rootRoutes from "./root"
import { METRICS_EVENT, RouterMetrics, useMetrics } from "@/services/metrics"

export function setPriorUrl(to: RouteLocationNormalized) {
  if (to.name === "Login") {
    // priorUrl should never be the login page
    return
  }
  // we want to preserve the url we were coming fron in case we want to
  // redirect to it after performing an action on nextLocation.
  window.sessionStorage.setItem(
    "priorUrl",
    JSON.stringify({
      name: to.name,
      params: to.params,
      path: to.path,
      query: to.query,
      hash: to.hash,
      fullPath: to.fullPath,
    })
  )
}

const newRouter = () => {
  const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "is-active",
    routes: rootRoutes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash,
        }
      } else if (savedPosition) {
        return savedPosition
      }
    },
  })

  const metrics = useMetrics()
  router.beforeEach((to, from) => {
    const auth = useAuthStore()

    /** Enforce authentication */
    if (to.meta.requireAuth && !auth.authenticated && auth.loaded) {
      setPriorUrl(to)
      return { path: "/login" }
    }

    /** Enforce lifter role to access lifter pages */
    if (to.meta.requireLifter && !auth.isLifter && auth.loaded) {
      setPriorUrl(to)
      return { path: "/subscriber/getstarted" }
    }

    /** Force reload if new client is available */
    if (needsUpdate.value) {
      console.info("Application needs update, triggering a full reload.")
      console.info("Redirecting to", router.resolve(to).href)
      window.location.assign(router.resolve(to).href)
    }

    /** Track routing events using either a custom named event or the default route event */
    if (to.path !== from.path) {
      if (to.meta.metrics) {
        const routeMetrics = to.meta.metrics as RouterMetrics
        const data = typeof routeMetrics.data === "function" ? routeMetrics.data(to.params) : routeMetrics.data || {}
        metrics.event(
          routeMetrics.event,
          Object.assign(
            {
              domain: window.location.hostname,
              location: to.fullPath,
              path: to.path,
              title: to.meta.title || to.name || to.path,
            },
            data
          ),
          false,
          to
        )
      } else if (to.meta.metrics !== false) {
        metrics.event(
          METRICS_EVENT.PAGE_VIEWED,
          {
            domain: window.location.hostname,
            location: to.fullPath,
            path: to.path,
            title: to.meta.title || to.name || to.path,
          },
          false,
          to
        )
      }
    }
  })

  return router
}

export default newRouter
