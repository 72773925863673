<template>
  <div
    class="wrapper"
    :class="{ 'is-large': isLarge, inactive: isInactive }"
    @click="$emit('click', name)"
  >
    <div
      class="platform-logo"
      :style="{ 'background-image': `url(${logo.default})` }"
    ></div>
    <div class="text">
      <!-- @slot if you want to add content alongside or below the logo -->
      <slot />
    </div>
  </div>
</template>

<script>
const pictograms = import.meta.glob("/src/dragonforce/assets/pictograms/*.png", { eager: true })

export default {
  name: "PlatformLogo",
  status: "ready",
  props: {
    /**
     * the name of the platform logo you want to display
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * make it larger
     */
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * set it as inactive (lower opacity)
     */
    isInactive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      logo: pictograms[`/src/dragonforce/assets/pictograms/${this.name}.png`],
    }
  },
}
</script>

<style
  scoped
  lang="scss"
>
$smol-logo-size: 2.5rem;
$large-logo-size: 8rem;

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.platform-logo {
  flex-basis: $smol-logo-size;
  width: $smol-logo-size;
  height: $smol-logo-size;
  background-color: transparent;
  background-size: 100%;
  background-repeat: no-repeat;
}

.text {
  margin-left: $space-x-small;
  min-width: 75%;
}

.is-large {
  .platform-logo {
    flex-basis: $large-logo-size;
    width: $large-logo-size;
    height: $large-logo-size;
  }

  .text {
    margin-left: 0;
    text-align: center;
  }
}

.inactive {
  opacity: 0.5;
}
</style>
