<template>
  <ORadio
    v-bind="$attrs"
    class="radio-button"
  >
    <slot></slot>
  </ORadio>
</template>

<style
  lang="scss"
  scoped
>
.radio-button {
  :deep(.control-label) {
    font-size: $font-size-base;
  }

  :deep(.check) {
    background-color: $color-white;
  }
}
</style>
