const DocsBrandingGuidelinesRedirect = () => import("@/components/pages/docs/BrandingGuidelinesRedirect.vue")
const DocsSecurity = () => import("@/components/pages/docs/SecurityDoc.vue")

export default [
  {
    path: "branding-guidelines",
    component: DocsBrandingGuidelinesRedirect,
  },
  {
    path: "security",
    component: DocsSecurity,
    meta: {
      breadcrumb: { title: "Security policy" },
    },
  },
]
