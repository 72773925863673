/**
 * Old Depci Api Service
 * https://github.com/tidelift/frontend/wiki/Making-API-Requests#old-way
 */

// eslint-disable-next-line  @typescript-eslint/ban-ts-comment
// @ts-nocheck
import UrlAssembler from "url-assembler"
import _ from "lodash"
import axios, { AxiosError } from "axios"

import config from "@/config"
import URLS from "./depci/urls"

const URL_BASE = "/api/depci"

export const api = axios.create({
  withCredentials: true,
  xsrfCookieName: "CSRF-TOKEN",
  xsrfHeaderName: "X-CSRF-Token",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    // @ts-expect-error GITVERSION comes from vite-plugin-git-revision
    "X-Tidelift-Frontend-Version": GITVERSION,
  },
})

export function buildUrl(url: string, urlBase = URL_BASE) {
  const urlTemplate = urlBase ? `${urlBase}/${url}` : `${url}`

  return (params = {}) => {
    return UrlAssembler()
      .template(urlTemplate)
      .qsConfig({
        encodeValuesOnly: true,
        arrayFormat: "brackets", // use Rails-style brackets w/no indices for arrays, in querystring
        indices: false,
      })
      .param(params)
      .toString()
  }
}

export const url: Record<keyof typeof URLS, (obj?: Record<string, string>) => string> = Object.keys(URLS).reduce(
  (all, key) => ({
    ...all,
    [key]: buildUrl(URLS[key], URL_BASE),
  }),
  {}
)

function buildGetAction({ name, url: urlfunc }) {
  if (!urlfunc) throw new Error(`url for ${name} is undefined!`)

  return async function (...args: any[]) {
    try {
      const response = await api.get(urlfunc(...args))
      return response.data
    } catch (error) {
      const response = (error as AxiosError).response
      if (response) {
        const migrationErr = typeof response.data === "string" && response.data?.match(/Migrations.*|rails db.*/g)
        if (import.meta.env.DEV && response.status === 500 && migrationErr) {
          alert("Migration error: " + migrationErr.join(" "))
        }
      }
      throw error
    }
  }
}

// TODO: Keep grouped and sorted
// TODO: generate from an array into a hash as most of these have the same signature

// catalog package
/** @deprecated use api version instead */
const fetchCatalogPackageUsageV2 = buildGetAction({ name: "catalog package usage", url: url.catalogPackageUsageV2 })
const fetchLifterTaskCountsByStandard = buildGetAction({
  name: "catalog tasks by standard",
  url: url.lifterTaskCountsByStandard,
})
const fetchLifterPackagesForStandard = buildGetAction({
  name: "catalog packages for standard",
  url: url.lifterPackagesForStandard,
})

// all others
const fetchArtifactoryRepositorySyncInfo = buildGetAction({
  name: "artifactory repository sync",
  url: url.syncArtifactoryRepository,
})
const fetchArtifactorySettings = buildGetAction({ name: "artifactory settings", url: url.artifactoryRepository })
const fetchArtifactoryStatus = buildGetAction({ name: "artifactory status", url: url.artifactoryInstanceStatus })
const fetchCatalog = buildGetAction({ name: "catalog", url: url.catalog })
const fetchCatalogOverview = buildGetAction({ name: "catalog overview", url: url.catalogOverview })
const fetchCatalogTasksByGroup = buildGetAction({ name: "catalog tasks by group", url: url.catalogTasksByGroup })
const fetchCatalogAlignment = buildGetAction({ name: "catalog alignment", url: url.catalogAlignment })
const fetchCatalogAlignmentReleases = buildGetAction({
  name: "catalog alignment releases",
  url: url.catalogAlignmentReleases,
})
const fetchCatalogReleasePackage = buildGetAction({ name: "catalog release package", url: url.catalogReleasePackage })
const fetchCatalogReleasesWithLicense = buildGetAction({
  name: "catalog releases",
  url: url.catalogReleasesWithLicense,
})
const fetchCatalogLicenseCounts = buildGetAction({
  name: "catalog releases with licenses",
  url: url.catalogLicenseCounts,
})
const fetchCatalogReports = buildGetAction({ name: "catalog reports", url: url.catalogReports })
const fetchTeamReports = buildGetAction({ name: "team reports", url: url.teamReports })
/**
 * @deprecated Use version in api.ts instead.
 */
const fetchCatalogRules = buildGetAction({ name: "catalog rules", url: url.catalogRules })
const fetchCatalogRulesTemplates = buildGetAction({ name: "catalog rules templates", url: url.catalogRulesTemplates })
const fetchCatalogTaskById = buildGetAction({ name: "catalog task", url: url.catalogTaskById })
const fetchCatalogTaskDecisionPreview = buildGetAction({ name: "catalog task", url: url.catalogTaskDecisionPreview })
const fetchImportableArtifactoryRepos = buildGetAction({
  name: "importable artifactory repositories",
  url: url.importableArtifactoryRepos,
})
const fetchImportableScans = buildGetAction({ name: "importable scans", url: url.importableScans })
const fetchLicenses = buildGetAction({ name: "license category map", url: url.licenses })
const fetchLifterOtherPackagesToLift = buildGetAction({
  name: "other packages to lift",
  url: url.lifterOtherPackagesToLift,
})
const fetchLiftingPackageCount = buildGetAction({ name: "lifting counts", url: url.liftingPackageCount })
const fetchLiftingPackages = buildGetAction({ name: "lifting names", url: url.liftingPackages })
const fetchScanDependencies = buildGetAction({ name: "scan dependencies", url: url.repositoryScanDependencies })
const fetchSlackSettings = buildGetAction({ name: "slack settings", url: url.slackSettings })
const fetchPackageSearchByMaintainer = buildGetAction({
  name: "package search by maintainer",
  url: url.packageSearchByMaintainer,
})
const fetchRepositoryScanExistingReleaseStatus = buildGetAction({
  name: "repository scan existing release status",
  url: url.repositoryScanExistingReleaseStatus,
})
const fetchCatalogStandards = buildGetAction({
  name: "get catalog standards for this catalog",
  url: url.catalogStandards,
})
/**
 * @deprecated Use version in api.ts instead.
 */
const fetchCatalogStandardOverrides = buildGetAction({
  name: "get catalog standard overrides for this catalog",
  url: url.catalogStandardOverrides,
})
const fetchPackageOverride = buildGetAction({
  name: "package override",
  url: url.packageOverride,
})
const updatePackageOverride = buildSimplePostAction(url.packageOverride, "update package override")
const fetchProjectLabels = buildGetAction({ name: "get project labels", url: url.projectLabels })

async function linkSlackUser(nonce) {
  const response = await api.post(url.linkSlackUser(), { nonce })
  return response.data
}

async function liftPackage(platform, name) {
  return api.post(url.liftPackage({ platform, name }), {})
}

async function bulkLiftPackages(packages) {
  return api.post(url.bulkLiftPackages(), { liftings: packages })
}

async function lowerPackage(platform, name) {
  return api.delete(url.liftPackage({ platform, name }))
}

const fetchLifterViolationsForStandard = buildGetAction({
  name: "lifter violations for a given standard",
  url: url.lifterViolationsForStandard,
})
const fetchLifterViolation = buildGetAction({ name: "lifter violation", url: url.lifterViolation })
const submitLifterViolation = buildSimplePutAction(url.submitLifterViolation, "submit lifter violation")

async function fetchProfile() {
  const response = await api.get(url.userProfile())
  return response.data
}

async function updateProfile(data) {
  const response = await api.put(url.userProfile(), data)
  return response.data
}

async function setUserPreference(data) {
  const response = await api.post(url.userPreferences(), data)
  return response.data
}

async function notifyLoginSuccess() {
  const response = await api.post(url.notifyLoginSuccess())
  return response.status
}

async function joinTeam(token) {
  const response = await api.get(url.joinTeam({ token }))
  return response.status
}

async function fetchTeams(invalidate) {
  const response = await api.get(url.teams())
  return response.data
}

async function createTeam(name) {
  const response = await api.post(url.teams(), { display_name: name })
  return response.data
}

async function createTeamInvite(team, body) {
  const response = await api.post(url.teamInvites({ team }), body)
  return response.data
}

async function setupAwsMarketplaceEntitlement(orgType, team, subId) {
  const response = api.post(url.awsMarketplaceSetup({ orgType, team, subId }))
  return response.data
}

async function setupGcpMarketplaceEntitlement(orgType, team, subId) {
  const response = api.post(url.googleMarketplaceSetup({ orgType, team, subId }))
  return response.data
}

async function fetchTeamIntegrations(team, orgType) {
  const response = await api.get(url.teamIntegrations({ team, org_type: orgType }))
  return response.data
}

async function revokeTeamInvite(team, orgType, inviteId) {
  const response = await api.delete(url.teamInvite({ team, inviteId, org_type: orgType }))
  return response.data
}

async function fetchTeamInvites(team, orgType) {
  const response = await api.get(url.teamInvites({ team, org_type: orgType }))
  return response.data
}

async function fetchTeamMembers(team, orgType) {
  const response = await api.get(url.teamMembers({ team, org_type: orgType }))
  return response.data
}

async function deleteTeamMembers(team, orgType, userUuids) {
  const data = {
    user_uuids: userUuids,
  }
  const response = await api.post(url.deleteTeamMembers({ team, org_type: orgType }), data)
  return response.data
}

async function updateTeamMembers(team, orgType, userUuids, role) {
  const data = {
    user_uuids: userUuids,
    role: role,
  }
  const response = await api.post(url.updateTeamMembers({ team, org_type: orgType }), data)
  return response.data
}

async function updateRepository({
  owner,
  repo,
  repoType,
  branchingBehavior,
  defaultBranch,
  projectName,
  externalIdentifier,
}: {
  owner
  repo
  repoType
  branchingBehavior?
  defaultBranch?
  projectName?
  externalIdentifier?
}) {
  // force: true to never cache an update request
  const resp = await api.put(url.repository({ owner, repo, repoType }), {
    branching_behavior: branchingBehavior,
    default_branch: defaultBranch,
    project_name: projectName,
    external_identifier: externalIdentifier,
  })
  return resp.data
}

async function triggerGcsBuild({ owner, repo, repoType, files }) {
  const formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    formData.append("file[" + i + "]", files[i])
  }
  return await api.post(url.triggerGcsBuild({ owner, repo, repoType }), formData)
}

async function catalogImportFromNewProject({ organization, name, repoType, catalogName, files }) {
  const formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    formData.append("file[" + i + "]", files[i])
  }
  return await api.post(url.catalogImportFromNewProject({ organization, name, repoType, catalogName }), formData)
}

async function fetchLegacyBuild(buildId) {
  const build = await api.get(`/api/depci/builds/${buildId}`)
  return build.data
}

function buildDataVerifyingFetch(url) {
  return async (...args) => {
    const response = await api.get(url(...args))

    if (response.data) {
      return response.data
    }
  }
}

async function fetchScanStubs({ owner, repo, repoType, page, perPage, branch }) {
  const result = await api.get(
    url.repositoryScans({ owner, repo, repoType, page, per_page: perPage, filter: { branch } })
  )
  return result.data
}

async function setRepositoryCatalog({ organization, name, repoType, catalogId }) {
  const result = await api.put(url.repositorySetCatalog({ organization, name, repoType, catalogId }))
  return result.data
}

async function activateLifting(platform, name) {
  const result = await api.patch(url.liftingActivate({ platform, name }))
  return result.data
}
const verifyLiftingGithubTwoFactorAuth = buildSimplePostAction(
  url.liftingVerifyGithubTwoFactorAuth,
  "verify lifting github two factor auth"
)

async function fetchSubscriptionsByOrg() {
  const response = await api.get(url.subscriptionsByOrg())
  return response.data
}

async function createUserApiKey(data) {
  const response = await api.post(url.apiKeys() + "/user", data)
  return response.data
}

async function createCiApiKey({ repoType, organization, keyName }) {
  const response = await api.post(url.ciApiKeys({ repoType, organization }), { name: keyName })
  return response.data
}

async function createRepositoryApiKeyByRepoName({ organization, repo, repoType, keyName }) {
  const response = await api.post(url.repositoryApiKeys({ repoType, organization, repo }), { name: keyName })
  return response.data
}

async function deleteApiKey(id) {
  const response = await api.delete(url.apiKeys() + `/${id}`)
  return response.data
}

async function updateProfileEmail(email) {
  return updateProfile({ email: email })
}

async function fetchPackage(platform, name, catalogName, organization, repoType) {
  const catalogParamsList = [catalogName, organization, repoType]
  if (_.some(catalogParamsList) && !_.every(catalogParamsList)) {
    throw new TypeError("Invalid CatalogParams: either include all or none of them.")
  }

  const requestBody = {
    platform,
    name,
    ...(catalogName && { catalog_name: catalogName }),
    ...(organization && { org_name: organization }),
    ...(repoType && { org_type: repoType }),
  }
  const response = await api.get(url.package(requestBody))
  return response.data
}

async function fetchPackageVersionIssues(platform, name) {
  const response = await api.get(url.packageVersionIssues({ platform, name }))
  return response.data
}

async function updatePackageDeprecationSettings({ platform, name, data }) {
  const response = await api.put(url.packageDeprecationSettings({ platform, name }), data)
  return response.data
}

async function fetchPackageReleaseStreams(platform, name) {
  const response = await api.get(url.packageReleaseStreams({ platform, name }))
  return response.data
}

async function updateOrganizationLicense({ repoType, organization, platform, name, license, notes }) {
  const response = await api.put(url.organizationLicense({ repoType, organization, platform, name }), {
    license,
    notes,
  })
  return response.data
}

async function updateCatalog(parameters, data) {
  const response = await api.put(url.catalog(parameters), data)
  return response.data
}

/**
 * @deprecated Use api.ts/setCatalogRules
 */
async function setCatalogRules(parameters, data) {
  const response = await api.put(url.catalogRules(parameters), data)
  return response.data
}

async function createOrUpdateTaskDecision(parameters, data) {
  const response = await api.put(url.catalogTaskById(parameters), data)
  return response.data
}

const validateProjectName = buildGetAction({
  name: "validate project name",
  url: url.validateProjectName,
})

async function validateSpdxExpression({ license }) {
  const response = await api.post(url.licenseValidation(), { license })
  return response.data.valid
}

//
// simple non-get actions
//
function buildSimplePostAction(postUrl, action) {
  if (!postUrl) {
    console.error(`No url defined for ${action}!`)
  }

  return async (parameters, data) => {
    const response = await api.post(postUrl(parameters), data)
    return response.data
  }
}

function buildSimplePostActionWithError(postUrl, action) {
  if (!postUrl) {
    console.error(`No url defined for ${action}!`)
  }

  return async (parameters, data) => {
    const response = await api.post(postUrl(parameters), data)
    return response.data
  }
}

function buildSimplePutAction(putUrl, action) {
  if (!putUrl) {
    console.error(`No url defined for ${action}!`)
  }
  return async (parameters, data) => {
    const response = await api.put(putUrl(parameters), data)
    return response.data
  }
}

function buildSimpleDeleteAction(deleteUrl, action) {
  if (!deleteUrl) {
    console.error(`No url defined for ${action}!`)
  }
  return async parameters => {
    const response = await api.delete(deleteUrl(parameters))
    return response.data
  }
}

const importScansToCatalog = buildSimplePostAction(url.catalogImportScansToCatalog, "import scans to catalog")
const importArtifactoryReposToCatalog = buildSimplePostAction(
  url.importArtifactoryReposToCatalog,
  "import artifactory repositories"
)
const createArtifactoryInstance = buildSimplePostActionWithError(url.artifactoryInstance, "create artifactory instance")
const createArtifactoryRepository = buildSimplePostAction(url.artifactoryRepository, "create artifactory repository")
const syncArtifactoryRepository = buildSimplePostAction(url.syncArtifactoryRepository, "sync artifactory repository")
const refreshArtifactoryInstanceStatus = buildSimplePutAction(
  url.artifactoryInstanceRefreshStatus,
  "refresh artifactory instance status"
)
const bulkCatalogsPackagesInCatalog = buildSimplePostAction(url.catalogsPackages, "bulk packages in catalog")
const deleteArtifactoryInstance = buildSimpleDeleteAction(url.deleteArtifactoryInstance, "delete artifactory instance")
const deleteArtifactoryRepository = buildSimpleDeleteAction(
  url.deleteArtifactoryRepository,
  "delete artifactory instance"
)
const deleteOrganizationLicense = buildSimpleDeleteAction(url.organizationLicense, "delete license research")
const createCatalogReport = buildSimplePostAction(url.catalogReportsGenerate, "generate new catalog report")
const deleteCatalogSubscription = buildSimpleDeleteAction(url.deleteCatalogSubscription, "unsubscribed from a catalog")
const ssoCheck = buildSimplePostAction(url.ssoCheck, "verify sso for domain")
const createCatalogStandardOverride = buildSimplePostAction(
  url.catalogStandardOverrides,
  "create a catalog standard override"
)
const createConditionalOverride = buildSimplePostAction(url.catalogStandardOverrides, "create a conditional override")
const deleteCatalogStandardOverrides = buildSimplePutAction(
  url.deleteCatalogStandardOverrides,
  "delete a batch of catalog standard overrides"
)
const deleteRepository = buildSimpleDeleteAction(url.repository, "delete repository")
const updateCatalogUserPermissions = buildSimplePutAction(url.catalogUserPermissions, "update catalog user permissions")
const deleteGroup = buildSimpleDeleteAction(url.organizationGroup, "delete group")
const updateGroup = buildSimplePutAction(url.organizationGroup, "update group")
const addUserToGroup = buildSimplePostAction(url.organizationGroupUser, "add user to group")
const addRepoToGroup = buildSimplePostAction(url.organizationGroupRepository, "add project to group")
const removeRepoFromGroup = buildSimpleDeleteAction(url.organizationGroupRepository, "remove project from group")
const removeUserFromGroup = buildSimpleDeleteAction(url.organizationGroupUser, "remove user from group")
const rerunScan = buildSimplePostAction(url.scanRerun, "rerun a scan")

export default {
  activateLifting,
  addRepoToGroup,
  addUserToGroup,
  bulkLiftPackages,
  bulkCatalogsPackagesInCatalog,
  catalogImportFromNewProject,
  createArtifactoryInstance,
  createArtifactoryRepository,
  createCatalogReport,
  createCatalogStandardOverride,
  createCiApiKey,
  createConditionalOverride,
  createOrUpdateTaskDecision,
  createRepositoryApiKeyByRepoName,
  createTeam,
  createTeamInvite,
  createUserApiKey,
  deleteApiKey,
  deleteArtifactoryInstance,
  deleteArtifactoryRepository,
  deleteOrganizationLicense,
  deleteCatalogSubscription,
  deleteGroup,
  deleteRepository,
  deleteTeamMembers,
  deleteCatalogStandardOverrides,
  fetchArtifactoryRepositorySyncInfo,
  fetchArtifactorySettings,
  fetchArtifactoryStatus,
  fetchCatalog,
  fetchCatalogOverview,
  fetchCatalogTasksByGroup,
  fetchCatalogAlignment,
  fetchCatalogAlignmentReleases,
  fetchCatalogPackageUsageV2,
  fetchLifterTaskCountsByStandard,
  fetchLifterPackagesForStandard,
  fetchCatalogReleasePackage,
  fetchCatalogReleasesWithLicense,
  fetchCatalogLicenseCounts,
  fetchCatalogReports,
  fetchCatalogRules,
  fetchCatalogRulesTemplates,
  fetchCatalogStandardOverrides,
  fetchCatalogStandards,
  fetchCatalogTaskById,
  fetchCatalogTaskDecisionPreview,
  fetchImportableArtifactoryRepos,
  fetchImportableScans,
  fetchLegacyBuild,
  fetchLicenses,
  fetchLifterViolationsForStandard,
  fetchLifterViolation,
  fetchLifterOtherPackagesToLift,
  fetchLiftingPackageCount,
  fetchLiftingPackages,
  fetchSlackSettings,
  fetchPackage,
  fetchPackageOverride,
  fetchPackageReleaseStreams,
  fetchPackageSearchByMaintainer,
  fetchPackageVersionIssues,
  fetchProfile,
  fetchProjectLabels,
  fetchRepositoryScanExistingReleaseStatus,
  fetchScanDependencies,
  fetchScanStubs,
  fetchSubscriptionsByOrg,
  fetchTeamIntegrations,
  fetchTeamInvites,
  fetchTeamMembers,
  fetchTeamReports,
  fetchTeams,
  importArtifactoryReposToCatalog,
  importScansToCatalog,
  joinTeam,
  liftPackage,
  linkSlackUser,
  lowerPackage,
  notifyLoginSuccess,
  removeRepoFromGroup,
  removeUserFromGroup,
  rerunScan,
  revokeTeamInvite,
  setCatalogRules,
  setRepositoryCatalog,
  setUserPreference,
  setupAwsMarketplaceEntitlement,
  setupGcpMarketplaceEntitlement,
  ssoCheck,
  submitLifterViolation,
  syncArtifactoryRepository,
  refreshArtifactoryInstanceStatus,
  triggerGcsBuild,
  updateCatalog,
  updateOrganizationLicense,
  updateCatalogUserPermissions,
  updateGroup,
  updatePackageOverride,
  updatePackageDeprecationSettings,
  updateProfile,
  updateProfileEmail,
  updateRepository,
  updateTeamMembers,
  url,
  validateProjectName,
  validateSpdxExpression,
  verifyLiftingGithubTwoFactorAuth,
}
