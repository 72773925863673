import _ from "lodash"
import { NotificationProgrammatic as OrugaNotification } from "@oruga-ui/oruga-next"
import NotificationWithAction from "@/components/scaffold/NotificationWithAction.vue"

interface NotificationOptions {
  message: string
  [key: string]: any
}

export function toast(options: NotificationOptions) {
  // default durations
  if (options.duration === undefined) {
    // errors get a bit more time for the user to read the error, since they
    // are assumed to be unexpected, and may contain information for the user
    // to self-correct.
    if (options.variant === "danger") {
      options.duration = 10000
    } else {
      options.duration = 5000
    }
  }

  // default toasts to the top middle of the screen
  OrugaNotification.open({ position: "top", ...options })
}

export function notice(options: NotificationOptions) {
  OrugaNotification.open({
    component: NotificationWithAction,
    props: _.pick(options, "message", "actionText"),
    ..._.omit(options, "message", "actionText"),
  })
}

export const common = {
  clipboard: {
    success() {
      toast({
        message: "Copied to your clipboard",
        variant: "success",
        duration: 2000,
      })
    },
    failure() {
      toast({
        message: "Error copying to your clipboard",
        variant: "danger",
        duration: 2000,
      })
    },
  },
}

export default {
  install(app: any) {
    app.config.globalProperties.$notification = {
      toast(options: NotificationOptions) {
        toast(options)
      },
      notice(options: NotificationOptions) {
        notice(options)
      },
    }
  },
}
