// this embeds the fonts in the resulting JS file, preventing duplication
// of the fonts, but making the JS file a bit bigger.
import "@/dragonforce/styles/fonts.scss"
import { App, DefineComponent } from "vue"

const components = import.meta.glob(
  [
    "./**/*.vue",
    // don't include story elements when building the component library, these should be handled by histoire only
    "!./**/*.story.vue",
    "./patterns/*.vue",
  ],
  { eager: true }
) as Record<string, DefineComponent<Record<string, unknown>, Record<string, unknown>, any>>

export const DragonforcePlugin = {
  // entrypoint for the component library, which registers components with Vue
  // this is what runs on "Vue.use(Dragonforce)"
  install: (app: App) => {
    Object.keys(components).forEach(contextName => {
      const name = contextName.split("/")
      let componentName = name.pop() || ""
      componentName = componentName.replace(".vue", "")

      app.component(componentName, components[contextName].default)
    })
  },
}
