import type { AxiosRequestConfig } from "axios"
import type {
  CatalogParams,
  ImportScanStatus,
  Standard,
  StandardOverride,
  ActivityItem,
  CatalogListItem,
} from "@/types/catalog"
import type { CatalogPackageRelease, CatalogPlatforms, ReleaseViolation } from "@/types/release"
import type { CatalogTaskQueryParams, ResolveViolationOperation, Task, ViolationDetails } from "@/types/task"
import type { PaginatedResponse, PaginationParams } from "@/types/general"
import type { CatalogRulesURLParams, AnyRules, PartialLicenseUpdate } from "@/types/rules"
import type { AllPlatforms, PackageFragment } from "@/types/package"
import type { PackageUsage, ViolationInfoPackageRelease } from "@/types/dependency"
import type { CatalogUser } from "@/types/authentication"

import { RequiredData, RequiredParams, t, client, RequiredParamsAndData } from "./utils"
import { METRICS_EVENT, useMetrics } from "../metrics"
import _ from "lodash"

export function fetchAllCatalogStandards(
  urlParams: { repoType: string; organization: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("{repoType}/{organization}/catalog_standards", urlParams)
  return client.get<Standard[]>(url, config)
}

interface PackageReleaseParams {
  platform: string
  package_name: string
  version: string
}

export function createCatalogRequest(
  urlParams: { repoType: string; organization: string },
  config: RequiredParamsAndData<
    { catalog_name?: string; scan_catalog_id?: string },
    PackageReleaseParams & { repo: string | null }
  >
) {
  const url = t("{repoType}/{organization}/catalog_requests", urlParams)
  return client.post<void>(url, config.data, config)
}

export function createCatalogRequestBulk(
  urlParams: CatalogParams,
  config: RequiredData<{ requests: PackageReleaseParams[]; notes?: string }>
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/catalog_requests/bulk", urlParams)
  return client.post(url, config.data, config)
}

export function fetchPaginatedCatalogTasks(urlParams: CatalogParams, config: RequiredParams<CatalogTaskQueryParams>) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/tasks", urlParams)
  return client.get<PaginatedResponse<Task>>(url, config)
}

export function setCatalogRules(urlParams: CatalogRulesURLParams, config: RequiredData<AnyRules>) {
  useMetrics().event(METRICS_EVENT.STANDARD_CONFIG_APPLIED, { standard: urlParams.catalogStandard })
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/rules/{catalogStandard}", urlParams)
  return client.put(url, config.data, config)
}

export function setAllowedLicensesPartial(urlParams: CatalogParams, config: RequiredData<PartialLicenseUpdate>) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/rules/allowed_licenses_partial", urlParams)
  return client.put(url, config.data, config)
}

export function fetchCatalogs(urlParams: { repoType: string; organization: string }, config?: AxiosRequestConfig) {
  const url = t("{repoType}/{organization}/catalogs", urlParams)
  return client.get<CatalogListItem[]>(url, config)
}

export function createCatalog(
  urlParams: { repoType: string; organization: string },
  config: RequiredData<{
    catalog_name: string
    description: string
  }>
) {
  const url = t("{repoType}/{organization}/catalogs", urlParams)
  return client.post<CatalogListItem[]>(url, config.data, config)
}

export function fetchCatalogReleases(
  urlParams: CatalogParams,
  config: RequiredParams<
    PaginationParams & {
      filter: {
        name: string
        platform: string
      }
      sort: string
      sort_by: string
    }
  >
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/releases", urlParams)
  return client.get<PaginatedResponse<CatalogPackageRelease> & CatalogPlatforms>(url, config)
}

export function updateCatalogRelease(
  urlParams: CatalogParams & PackageFragment & { releaseId: string },
  config: RequiredData<{
    status: string
    deny_at: string
    notes: string
  }>
) {
  const url = t(
    "{repoType}/{organization}/catalogs/{catalogName}/releases/{platform}/{name}/{releaseId}/update",
    urlParams
  )
  return client.post<void>(url, config.data, config)
}

export function fetchCatalogPackageReleases(
  urlParams: PackageFragment,
  config: RequiredParams<
    PaginationParams & {
      org_type?: string
      org_name?: string
      catalog_name?: string
      filter?: {
        statuses?: string[]
        version?: string
        release_types?: string[]
      }
      sort_by?: string
      sort_dir?: string
    }
  >
) {
  const url = t("package/{platform}/{name}/releases", urlParams)
  return client.get<PaginatedResponse<PackageUsage>>(
    url,
    // temporarily append new_blocked_item_mode. Can be deleted after backend removes it
    _.merge(config, { params: { new_blocked_item_mode: true } })
  )
}

/**
 * The shape of the rules is different for each rule type.
 */
export function fetchCatalogRules<T>(
  urlParams: CatalogRulesURLParams,
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/rules/{catalogStandard}", urlParams)
  return client.get<{
    value: T
    created_at: string
    updated_at: string
  }>(url, config)
}

export function fetchCatalogStandardOverrides(
  urlParams: { standardSlug: string } & CatalogParams,
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/standards/{standardSlug}/overrides", urlParams)
  return client.get<StandardOverride[]>(url, config)
}

export function fetchCatalogViolations(
  urlParams: CatalogParams,
  config: RequiredParams<PaginationParams & PackageReleaseParams>
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/violations", urlParams)
  return client.get<
    PaginatedResponse<ReleaseViolation> & {
      denied_count: number
      release: ViolationInfoPackageRelease
      package_stale: boolean
    }
  >(
    url,
    // temporarily append new_blocked_item_mode. Can be deleted after backend removes it
    _.merge(config, { params: { new_blocked_item_mode: true } })
  )
}

export function fetchCatalogViolation(urlParams: CatalogParams & { violationId: string }) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/violations/{violationId}", urlParams)
  return client.get<ViolationDetails>(url)
}

/**
 * This function is documented in shortcut:
 * https://app.shortcut.com/tidelift/story/34189/violation-resolution-for-auto-status
 */
export function resolveCatalogViolation(
  urlParams: CatalogParams,
  config: RequiredData<{
    operations: Array<ResolveViolationOperation>
  }>
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/violations/resolve", urlParams)
  return client.post(url, config.data, config)
}

export function subscribeCatalogStandard(
  urlParams: CatalogParams & { standardSlug: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/standards/{standardSlug}", urlParams)
  return client.post<Standard[]>(url, config)
}

export function unsubscribeCatalogStandard(
  urlParams: CatalogParams & { standardSlug: string },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/standards/{standardSlug}", urlParams)
  return client.delete<Standard[]>(url, config)
}

export function fetchCatalogImportScanStatuses(
  {
    repoType,
    organization,
    catalogName,
  }: {
    repoType: string
    organization: string
    catalogName: string
  },
  config: AxiosRequestConfig | undefined = undefined
) {
  const url = `${repoType}/${organization}/catalogs/${catalogName}/import_scan_statuses`

  return client.get<Array<ImportScanStatus>>(url, config)
}

export function fetchCatalogActivities(
  urlParams: CatalogParams,
  config: RequiredParams<
    PaginationParams & {
      query?: string
      versions?: string
      start_date?: string
      end_date?: string
      user_type?: string
      reviewer_uuids?: string[]
    }
  >
) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/activities", urlParams)
  return client.get<PaginatedResponse<ActivityItem>>(url, config)
}

export function fetchCatalogUsers(urlParams: CatalogParams, config: AxiosRequestConfig | undefined = undefined) {
  const url = t("{repoType}/{organization}/catalogs/{catalogName}/users", urlParams)
  return client.get<CatalogUser[]>(url, config)
}
