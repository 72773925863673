import { Organization } from "@/types/organization"
import { defineStore } from "pinia"
import { useCatalogsStore } from "./catalogs"
import _ from "lodash"

export const Policies = {
  /** Catalog level policies */
  create_violation_status_override: "catalogs.create_violation_status_override",
  delete_violation_status_override: "catalogs.delete_violation_status_override",
  request_catalog_release: "catalogs.request_catalog_release",
  view_catalog_configuration: "catalogs.view_catalog_configuration",
  update_catalog_configuration: "catalogs.update_catalog_configuration",
  update_catalog: "catalogs.manage",
  list_tasks: "catalogs.list_tasks",
  manage_blocked_releases: "catalogs.manage_catalog_blocked_items",

  /** Organization level policies */
  // catalogs
  create_catalogs: "catalogs.create_catalogs",
  // licenses
  create_license_override: "licenses.create_license_override",
  delete_license_override: "licenses.delete_license_override",
  // projects
  create_projects: "projects.create_projects",
  destroy_projects: "projects.destroy_projects",
  update_projects: "projects.update_projects",
  view_all_projects: "projects.view_all_projects",
  // reports
  generate_reports: "reports.generate_report",
  download_reports: "reports.download_report",
  view_report_status: "reports.view_report_status",
  //groups
  create_groups: "groups.create_org_groups",
  update_groups: "groups.update_org_groups",
  destroy_groups: "groups.destroy_org_groups",
  view_groups: "groups.view_org_groups",
  //product areas
  intelligence_mode: "product_areas.intelligence_mode",
  // other
  manage_users: "users.manage",
  view_users: "users.view",
  manage_api_keys: "api_keys.manage",
  view_api_keys: "api_keys.view",
  manage_teams: "teams.manage",
} as const

export type PolicyType = typeof Policies[keyof typeof Policies]

interface State {
  teamPolicies?: Organization["policies"]
}

export const usePolicyStore = defineStore("policies", {
  persist: {
    key: "tl/policies-cache",
  },
  state: (): State => ({
    teamPolicies: undefined,
  }),
  getters: {
    // Convenience so consumers don't have to import the enum and return it in setup()
    all() {
      return Policies
    },
  },
  actions: {
    setPolicy(scope: "team", policies?: Organization["policies"]) {
      if (scope === "team") {
        this.teamPolicies = policies
      }
    },
    check(scope: "catalog" | "team", ...policies: PolicyType[]) {
      const catalogsStore = useCatalogsStore()
      return this.checkForCatalog(catalogsStore.catalog?.policies, scope, ...policies)
    },
    checkForCatalog(
      catalogPolicies: Record<string, Record<string, boolean>> | undefined,
      scope: "catalog" | "team",
      ...policies: PolicyType[]
    ) {
      if (scope === "catalog" && !catalogPolicies) return null
      if (scope === "team" && !this.teamPolicies) return null

      return policies.every(name => {
        if (scope === "catalog") {
          return _.get(catalogPolicies, name, false)
        }
        if (scope === "team") {
          return _.get(this.teamPolicies, name, false)
        }
        return false
      })
    },
  },
})
