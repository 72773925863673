<template>
  <div
    class="circle type-colors"
    :class="type"
  >
    <i
      class="fas"
      :class="icon"
      :style="iconStyle"
    />
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed } from "vue"

const props = defineProps({
  type: {
    type: String,
    validator: (type: string) => ["tidelift", "info", "success", "warning", "danger", "purple"].includes(type),
  },
  icon: { type: String, required: true },
  iconColor: { type: String, required: false },
})

const iconStyle = computed(() => (props.iconColor ? { color: props.iconColor } : {}))
</script>

<style
  lang="scss"
  scoped
>
@import "../styles/type-colors";

.circle {
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: $border-radius-circle;
  margin-left: $space-x-small;
  padding: 0.375rem;
  width: 2rem;
  height: 2rem;
  font-size: $font-size-base;

  i {
    width: 100%;
    text-align: center;
  }
}
</style>
