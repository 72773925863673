import _ from "lodash"
import deepKeys from "deep-keys"
import Axios from "axios"
import config from "@/config"

const FILTER_STRING = "[FILTERED]"

// report is modified in place!
export default function beforeSendReportScrubber(report) {
  // Filter out 401 errors
  if (Axios.isAxiosError(report.originalError)) {
    if ([401].includes(report.originalError.response?.status)) {
      console.warn(`Axios error with status code ${report.originalError.response?.status} ignored by bugsnag.`)
      return false
    }
  }

  // vue components that are password fields (b-input)
  if (_.get(report, "metaData.vue.props.type") === "password") {
    report.metaData.vue.props.value = FILTER_STRING
  }

  // anything with the keys set in config.bugsnag.filterKeys gets filtered
  deepKeys(report)
    .filter(key => _.intersection(key.split("."), config.bugsnag.filterKeys).length)
    .forEach(key => {
      _.set(report, key, FILTER_STRING)
    })
}
