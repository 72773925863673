import { Ref, watch } from "vue"
import { LocationQuery, useRouter } from "vue-router"
import nsLocalStorage from "@/services/nsLocalStorage"

function typeSafeHydrate(unsafe: LocationQuery, defaultValue: LocationQuery) {
  const hydrated = {} as LocationQuery
  const queryKeys = Object.keys(defaultValue)
  queryKeys.forEach(key => {
    const queryVal = unsafe[key]
    if (typeof queryVal === typeof defaultValue[key]) {
      hydrated[key] = queryVal
    } else if (Array.isArray(defaultValue[key]) && typeof queryVal === "string") {
      /**
       * The default query deserializer can't tell the difference between
       * arrays and strings when there's only a single element
       */
      hydrated[key] = [queryVal]
    }
  })
  return hydrated
}

function serializeOmitEmpty(obj: LocationQuery) {
  const serialized = {} as LocationQuery
  Object.entries(obj).forEach(([key, val]) => {
    if (val && (Array.isArray(val) ? val.length > 0 : true)) {
      serialized[key] = val
    }
  })
  return serialized
}

/**
 * useRouterQuerySync is a composition function that takes a reactive object and
 * replicates its value to the url query string
 */
export function useRouterQuerySync(target: LocationQuery) {
  const router = useRouter()

  watch(
    target,
    newData => {
      router.replace({ query: serializeOmitEmpty(newData) })
    },
    { deep: true }
  )

  function hydrate() {
    const { query } = router.currentRoute.value
    return typeSafeHydrate(query, target)
  }

  return { hydrate }
}

/**
 * useLocalStorageQuerySync is a composition function that takes a reactive object and
 * replicates its value to localStorage
 */
export function useLocalStorageQuerySync(target: LocationQuery, key: Ref<string>) {
  function clear() {
    return nsLocalStorage.removeItem(key.value)
  }

  async function hydrate() {
    let fromLocalStorage = {} as LocationQuery
    try {
      const val = await nsLocalStorage.getItem(key.value)
      fromLocalStorage = JSON.parse(val || "{}")
    } catch {
      clear()
    }
    return typeSafeHydrate(fromLocalStorage, target)
  }

  watch(
    target,
    newData => {
      nsLocalStorage.setItem(key.value, JSON.stringify(serializeOmitEmpty(newData)))
    },
    { deep: true }
  )

  return { hydrate }
}
