<template>
  <Component
    :is="headerLevel"
    :class="inverted ? 'inverted' : ''"
    ><slot
  /></Component>
</template>

<script
  setup
  lang="ts"
>
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    level?: number
    inverted?: boolean
  }>(),
  {
    level: 1,
  }
)

const headerLevel = computed(() => {
  switch (props.level) {
    case 1:
      return "h1"
    case 2:
      return "h2"
    case 4:
      return "h4"
    default:
      return "h3"
  }
})
</script>

<style
  lang="scss"
  scoped
>
h1,
h2,
h3,
h4 {
  font-weight: $font-weight-semi-bold;
  margin-bottom: $space-base;
  color: $color-tidelift-blue-700;

  &.inverted {
    color: $color-white;
  }
}

h1,
h2 {
  line-height: $line-height-heading-large;
}

h1 {
  font-size: $font-size-pretty-large;
  font-weight: $font-weight-black;
  letter-spacing: -0.5px;
}

h2 {
  font-size: $font-size-large;
  letter-spacing: -0.8px;
}

h3 {
  font-size: $font-size-medium;
  line-height: $line-height-heading-medium;
  letter-spacing: -0.5px;
}

h4 {
  font-size: $font-size-base;
  line-height: $line-height-heading-small;
  letter-spacing: normal;
}
</style>
