<template>
  <Component
    ref="el"
    :is="type"
    :aria-label="ariaLabel"
    class="svg-icon"
    :style="style"
    v-bind="$attrs"
  >
  </Component>
</template>

<script
  setup
  lang="ts"
>
import { ref, computed, onMounted } from "vue"
import icons from "@/dragonforce/assets/icons"

const el = ref()

const props = withDefaults(
  defineProps<{
    /**
     * The name of the icon to display.
     */
    name: string
    /**
     * The fill color of the SVG icon.
     */
    fill?: string
    /**
     * Descriptive text to be read to screenreaders.
     */
    ariaLabel?: string
    /**
     * The html element name used for the icon.
     */
    type?: string
    /**
     * The size of the icon.
     */
    size: string
  }>(),
  {
    name: "settings",
    string: "#00264c",
    ariaLabel: "icon",
    type: "span",
    size: "16px",
  }
)

const style = computed(() => {
  return {
    height: props.size,
    width: props.size,
  }
})

onMounted(() => {
  el.value.innerHTML = icons[`/src/dragonforce/assets/icons/${props.name}.svg`]
  el.value.children[0].style.fill = props.fill
  el.value.children[0].style.width = props.size
  el.value.children[0].style.height = props.size
})
</script>
