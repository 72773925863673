import bugsnagVue from "./bugsnagClient"

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from "vue"
import createRouter from "./router"
import config from "./config"

import { install as installUndefinedios } from "./services/undefinedios"
import { install as installPendingMigrationsWarning } from "./services/pendingMigrationsWarning"
import AuthPlugin, { configureAuth } from "./services/auth"
import FeaturesPlugin, { useFeatures } from "./services/features"
import NotificationsPlugin from "./services/notification.ts"

import setupVue from "@/services/setupVue"
import { sync as piniaSync } from "@/store/pinia/router-sync"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"

import { metricsSetup, vMetricsLifterTaskEngaged } from "@/services/metrics"
import { setup as datadogSetup } from "@/services/datadog"
import Root from "./Root.vue"

import "./global.scss"

const app = createApp(Root)

const router = createRouter()
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
piniaSync(pinia, router)
app.use(pinia)
app.use(router)

// set up common stuff (oruga, dragonforce, and a $features mock)
setupVue(app)

installUndefinedios()

if (!import.meta.env.PROD) {
  installPendingMigrationsWarning()
}

app.use(bugsnagVue)
app.use(FeaturesPlugin)

app.use(NotificationsPlugin)

metricsSetup()
app.directive("metrics-lifter-task-engaged", vMetricsLifterTaskEngaged)

datadogSetup()

/* the auth profile, which contains the info if you are a lifter or not,
 * is loaded asynchronously after you Vue.use(AuthPlugin).
 * That info is used by vue-router to redirect away from lifter routes,
 * and had to be there before the router is started up.
 * The safest way to know if that async load is complete is to watch the
 * store for the auth.loaded flag to get set to true.
 */

const features = useFeatures()

configureAuth({
  router,
  federatedLogoutEnabled: features.isEnabled("federated_logout"),
})

app.use(AuthPlugin)
app.mount("#app")
