import { titleForTaskName } from "@/components/scaffold/copy"
import { METRICS_EVENT } from "@/services/metrics"
import { packageUniqueKey } from "@/store/pinia/packages"
import { PackageFragment } from "@/types/package"
import { CATALOG_STANDARD } from "@/types/task"

const Package = () => import("@/components/pages/packages/Package.vue")
const LifterTaskPage = () => import("./lifter_dashboard/LifterTaskPage.vue")
const LifterBase = () => import("./lifter_dashboard/LifterBase.vue")
const LifterIncomeDetails = () => import("./IncomeDetails.vue")
const LifterSearch = () => import("./Search.vue")
const LifterSignUp = () => import("./SignUp.vue")
const LifterPackageTasks = () => import("./packages/PackageTasks.vue")
const LifterPackages = () => import("./Packages.vue")
const LifterPackageWrapper = () => import("./LifterPackageWrapper.vue")
const TaskLifterStandardsMultiplexer = () => import("./lifter_dashboard/task_lists/TaskLifterStandardsMultiplexer.vue")
const LifterFundedPackages = () => import("./FundedPackages.vue")
const LifterDependencies = () => import("./Dependencies.vue")
const LifterOtherPackagesToLift = () => import("./OtherPackagesToLift.vue")
const LifterPackage = () => import("./search/Package.vue")
const LifterSlack = () => import("./LifterSlack.vue")
const RouterViewWrapper = () => import("@/components/scaffold/RouterViewWrapper.vue")
const RouterViewWrapperWithBreadcrumb = () => import("@/components/scaffold/RouterViewWrapperWithBreadcrumb.vue")

export default [
  {
    path: "",
    redirect: { name: "LifterTaskPage" },
  },
  {
    path: "tasks",
    component: LifterBase,
    children: [
      {
        path: "",
        component: LifterTaskPage,
        name: "LifterTaskPage",
        meta: {
          metrics: {
            event: METRICS_EVENT.LIFTER_TASK_DASHBOARD_VIEWED,
          },
        },
      },
    ],
  },
  {
    path: "other-packages-to-lift",
    name: "LifterOtherPackagesToLift",
    component: LifterOtherPackagesToLift,
    meta: {
      breadcrumb: { title: "other packagses to lift" },
    },
  },
  {
    path: "packages-with-income",
    component: LifterFundedPackages,
    name: "LifterFundedPackages",
    meta: {
      breadcrumb: { title: "Packages with income" },
      requireLifter: false,
      requireAuth: false,
    },
  },
  {
    path: "search",
    component: RouterViewWrapper,
    meta: {
      breadcrumb: { title: "Find new package" },
      requireLifter: false,
      requireAuth: false,
    },
    children: [
      {
        path: "",
        component: LifterSearch,
        name: "LifterSearch",
      },
      {
        path: ":platform/:name",
        component: LifterPackage,
        name: "LifterPackage",
        props: true,
      },
    ],
  },
  {
    path: "slack",
    name: "LifterSlack",
    component: LifterSlack,
    meta: {
      requireLifter: true,
      requireAuth: true,
    },
  },
  {
    path: "lift-new",
    name: "LiftNewSearch",
    redirect: { name: "LifterSearch" },
  },
  {
    path: "signup/:platform/:name/:status",
    component: LifterSignUp,
    name: "LifterSignUp",
    props: true,
    meta: {
      requireLifter: false,
      requireAuth: false,
    },
  },
  {
    path: "income",
    component: LifterIncomeDetails,
    name: "LifterIncome",
  },
  {
    path: "package",
    component: RouterViewWrapper,
    meta: {
      breadcrumb: { title: "Packages" },
      requireLifter: false,
    },
    children: [
      {
        path: "",
        name: "LifterPackages",
        component: LifterPackages,
      },
      {
        path: ":platform/:name",
        component: LifterPackageWrapper,
        props: true,
        meta: {
          breadcrumb: { title: (params: PackageFragment) => packageUniqueKey(params) },
        },
        children: [
          {
            path: "tasks",
            component: RouterViewWrapperWithBreadcrumb,
            meta: {
              breadcrumb: { title: "Tasks" },
            },
            children: [
              {
                path: "",
                component: LifterPackageTasks,
                props: true,
                name: "LifterPackageTasks",
                metrics: {
                  event: METRICS_EVENT.LIFTER_PACKAGE_TASK_LIST_PAGE_VIEWED,
                },
              },
              {
                name: "LifterPackageTask",
                path: ":catalogStandard",
                props: true,
                component: TaskLifterStandardsMultiplexer,
                meta: {
                  breadcrumb: {
                    title: (params: { catalogStandard: CATALOG_STANDARD }) =>
                      `${titleForTaskName(params.catalogStandard)}`,
                  },
                  metrics: {
                    event: METRICS_EVENT.LIFTER_PACKAGE_TASK_VIEWED,
                  },
                },
              },
            ],
          },
          {
            path: "overview",
            alias: "",
            name: "LifterPackageInfo",
            component: Package,
            props: true,
            meta: {
              metrics: {
                event: METRICS_EVENT.LIFTER_PACKAGE_OVERVIEW_VIEWED,
              },
            },
          },
          {
            path: "dependencies",
            name: "LifterDependencies",
            component: LifterDependencies,
            props: true,
          },
        ],
      },
    ],
  },
  /** Backward compatible redirects for old dashboard routes */
  {
    path: ":slug/packages",
    redirect: (to: any) => ({
      name: "LifterTaskPage",
      query: {
        catalog_standards: to.params.slug,
      },
    }),
  },
  {
    path: ":slug/packages/:platform/:name",
    redirect: (to: any) => ({
      name: "LifterPackageTask",
      params: {
        platform: to.params.platform,
        name: to.params.name,
        catalogStandard: to.params.slug,
      },
    }),
  },
  /** End backward compatible redirects */
]
