import axios, { AxiosResponse } from "axios"

export function success(response: AxiosResponse<any, any>) {
  // on some server responses (seems to be 403 or 404), the response can
  // "succeed" but be undefined. this is rare. if it's happening, examine the
  // endpoint that is involved for odd responses.
  if (!response) {
    return Promise.reject(new Error("response is undefined"))
  }

  return response
}

export function install() {
  axios.interceptors.response.use(success)
}
