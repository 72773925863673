<template>
  <div class="multilevel-checkbox-wrapper">
    <ul>
      <li>
        <input
          type="checkbox"
          name="title"
          :id="`${idPrefix}-title`"
          :checked="allSelected"
          :indeterminate.prop="someSelected"
          @click="toggleAll"
        />
        <label
          data-title-label
          :for="`${idPrefix}-title`"
        >
          <slot name="title"></slot>
        </label>
        <button
          class="collapse-link"
          @click="toggleCollapse"
        >
          <template v-if="collapsed">(show all &#8594;)</template>
          <template v-else>(collapse)</template>
        </button>
        <ul v-if="!collapsed">
          <li
            v-for="option in options"
            :key="option.value"
          >
            <input
              type="checkbox"
              :id="`${idPrefix}-${option.text}`"
              :name="option.text"
              :value="option.value"
              v-model="checkedValues"
            />
            <label :for="`${idPrefix}-${option.text}`">{{ option.text }}</label>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
let componentId = +new Date()

// this approach to creating unique IDs for each use of this component
// works since javascript is single threaded. there'll never be a time when
// two or more components are calling this function at the exact same time and
// modifying componentId non-atomically.
function getComponentId() {
  const result = componentId
  componentId += 1
  return `multilevel-checkbox-${result}`
}

export default {
  name: "MultilevelCheckbox",
  status: "ready",
  props: {
    options: { type: Array, required: true },
    value: { type: Array, default: () => [] },
  },
  computed: {
    allSelected() {
      return this.checkedValues.length == this.options.length
    },
    someSelected() {
      return this.checkedValues.length > 0 && this.checkedValues.length < this.options.length
    },
  },
  data() {
    return {
      checkedValues: [],
      collapsed: false,
      idPrefix: getComponentId(),
    }
  },
  mounted() {
    this.checkedValues = this.value
  },
  methods: {
    toggleAll() {
      if (this.allSelected) {
        this.checkedValues = []
      } else {
        this.checkedValues = this.options.map(option => option.value)
      }
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed
    },
    clear() {
      this.checkedValues = []
    },
  },
  watch: {
    checkedValues: {
      deep: true,
      handler(newVal, oldVal) {
        let added = newVal.filter(x => !oldVal.includes(x))
        let removed = oldVal.filter(x => !newVal.includes(x))
        this.$emit("update:add", added)
        this.$emit("update:remove", removed)
      },
    },
  },
}
</script>

<style lang="scss">
.multilevel-checkbox-wrapper {
  ul {
    list-style: none;
    margin: 5px 20px;
    padding-left: 20px;
  }

  li {
    margin: 10px 0;
  }

  label {
    margin-left: 5px;
    cursor: pointer;
  }

  .collapse-link {
    background: none;
    border: none;
    cursor: pointer;
    @include themify {
      color: themed("color-link");
    }
  }
}
</style>
