import { computed } from "vue"
import _ from "lodash"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import { useCatalogsStore } from "@/store/pinia/catalogs"

const LINK_KEYS = ["platform", "name", "lifter", "subscriber", "catalogOrganization"]

export interface PackageLinkParams {
  platform: string
  name?: string
  filter_by_version?: string
  catalogOrganization?: string
  catalogName?: string
  [key: string]: string | undefined
}

interface InternalPackageLinkParams extends PackageLinkParams {
  lifter: string
  subscriber: string
  [key: string]: string | undefined
}

function packageLinkHandler() {
  const router = useRouter()

  const { defaultCatalog } = storeToRefs(useCatalogsStore())
  const defaultCatalogName = computed(() => defaultCatalog.value?.name)
  const currentCatalogName = computed(() => {
    return router.currentRoute.value.params.catalogName || defaultCatalogName.value
  })

  const getPackageLink = (params: InternalPackageLinkParams) => {
    const otherParams = _.omit(params, LINK_KEYS)
    const { lifter, platform, name, subscriber, catalogOrganization } = params

    if (router.currentRoute.value.meta.userRole === "Lifter") {
      return router.resolve({
        name: lifter,
        params: {
          platform: platform,
          name: name,
        },
        query: otherParams,
      }).href
    } else {
      return router.resolve({
        name: subscriber,
        params: {
          platform: platform,
          name: name,
          repoType: "team",
          organization: catalogOrganization || router.currentRoute.value.params.organization,
        },
        query: otherParams,
      }).href
    }
  }

  const packageLink = (params: PackageLinkParams) => {
    return getPackageLink({ ...params, lifter: "LifterPackageInfo", subscriber: "SubscriberPackage" })
  }

  const dependenciesLink = (params: PackageLinkParams) => {
    return getPackageLink({
      ...params,
      lifter: "LifterDependencies",
      subscriber: "SubscriberPackageDependencies",
    })
  }

  const mode = computed(
    () => (router.currentRoute.value.meta.userRole as "Lifter" | "Subscriber" | undefined) || "Global"
  )

  return {
    defaultCatalog: defaultCatalogName,
    currentCatalogName,
    mode,
    packageLink,
    dependenciesLink,
  }
}

export type PackageLinkHandler = ReturnType<typeof packageLinkHandler>

export default packageLinkHandler
