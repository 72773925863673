<template>
  <div class="main-app">
    <RouterView />
    <GlobalDialog />
  </div>
</template>

<script lang="ts">
import hotkeys from "hotkeys-js"
import { computed, watch, defineComponent, watchEffect } from "vue"
import { useRoute, useRouter } from "vue-router"
import GlobalDialog from "@/components/scaffold/GlobalDialog.vue"
import teamsHandler from "@/mixins/FetchTeamsMixin"
import { useTitle } from "./services/title"
import { useAuth } from "./services/auth"
import { useTeamStore } from "./store/pinia/teams"
import { storeToRefs } from "pinia"
import { PolicyType, usePolicyStore } from "./store/pinia/policy"

export default defineComponent({
  components: {
    GlobalDialog,
  },
  setup() {
    useTitle("Tidelift")

    useAuth().initialize()

    const route = useRoute()
    const router = useRouter()
    const policy = usePolicyStore()
    const { fetchTeamsAndCatchError } = teamsHandler()
    const { currentTeam, currentSubscription, teams } = storeToRefs(useTeamStore())
    const isSubscriberRoute = computed(() => route?.meta.userRole === "Subscriber")
    const trialEnded = computed(() => {
      const { is_trial, active, in_grace_period } = currentSubscription.value || {}

      return is_trial && (!active || in_grace_period)
    })
    const subscriptionEnded = computed(() => {
      const { is_trial, active } = currentSubscription.value || {}

      return currentTeam?.value && !is_trial && !active
    })

    watch(
      isSubscriberRoute,
      async newVal => {
        if (newVal) {
          fetchTeamsAndCatchError()
        }
      },
      { immediate: true }
    )

    watchEffect(() => {
      /** Enforce policy requirements */
      const requirePolicy = route.meta.requirePolicy as { scope: "catalog" | "team"; policy: PolicyType } | undefined
      const routeIsBlocked = !!requirePolicy && policy.check(requirePolicy.scope, requirePolicy.policy) === false
      if (routeIsBlocked) {
        router.replace({ path: "/subscriber" })
      }
    })

    hotkeys("/", { keydown: true }, () => {
      setTimeout(() => router.push({ name: "SubscriberSelectTeam" }), 10)
    })

    router.beforeEach((to, _) => {
      if (to.name !== "TrialEnded" && trialEnded.value) router.push({ name: "TrialEnded" })
      if (to.name !== "SubscriptionEnded" && subscriptionEnded.value) router.push({ name: "SubscriptionEnded" })
    })

    watchEffect(() => {
      if (trialEnded.value) router.push({ name: "TrialEnded" })
      if (subscriptionEnded.value) router.push({ name: "SubscriptionEnded" })
    })

    return {
      currentSubscription,
      currentTeam,
      isSubscriberRoute,
    }
  },
})
</script>

<style lang="scss">
html,
body,
.main-app {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.main-app {
  font-family: "IBM Plex Sans", Arial, sans-serif;
}

.tag.is-normal-case {
  text-transform: none;
  letter-spacing: 0;
}

.notices.has-custom-container {
  .snackbar {
    opacity: 0.9;
    background-color: $color-dark-text;
    color: white;
  }

  .is-bright-blue button {
    color: $color-bright-blue;
  }

  .toast.is-warning {
    color: $color-yellow-700;
    background-color: $color-yellow-300;
  }
}
</style>
