<template>
  <div>
    <Banner
      type="info"
      v-if="dependenciesNotSynced"
      >Fetching dependencies for the first time. Please try again later.
    </Banner>
    <TlStandardTable
      v-model:search="queryFilters.search"
      v-if="!dependenciesNotSynced"
      :data="dependencies"
      :columns="columns"
      :loading="pkgStore.isLoadingDependencies"
      title="Dependencies"
      searchable
      :level="2"
    >
      <template #table-subheading>
        <p class="mb-3 is-flex is-align-items-center">
          Show direct dependencies for release
          <Dropdown
            v-model="queryFilters.version"
            size="small"
            class="ml-1"
          >
            <option
              v-for="release in versionOptions"
              :key="release"
              :value="release"
            >
              {{ release }}
            </option>
          </Dropdown>
        </p>
      </template>

      <template #item_name="props">
        <RouterLink
          :to="
            packageLink({
              platform: props.row.platform,
              name: props.row.name,
            })
          "
        >
          {{ props.row.name }}
        </RouterLink>
      </template>

      <template #item_requirement="{ row }">
        <span class="mono">{{ row.requirement }}</span>
      </template>

      <template #item_vulnerability_count="{ row }">
        <RouterLink
          v-if="row.vulnerability_count > 0 && mode === 'Subscriber'"
          :to="{
            name: 'SubscriberPackageVulnerabilities',
            params: {
              platform: row.platform,
              name: row.name,
            },
          }"
        >
          {{ row.vulnerability_count }}
        </RouterLink>
        <span v-else>0</span>
        <Badge
          v-if="row.vulnerable_release_summary === 'all'"
          type="danger"
          square
        >
          Affected
        </Badge>
        <Badge
          v-else-if="row.vulnerable_release_summary === 'partial'"
          type="warning"
          square
        >
          May be affected
        </Badge>
      </template>

      <template #item_dependency_count="{ row }">
        <RouterLink
          class="mr-2"
          :to="
            dependenciesLink({
              platform: row.platform,
              name: row.name,
              version: row.latest_required_version || '',
            })
          "
        >
          {{ row.dependency_count }}
        </RouterLink>
        (<span class="mono">{{ row.latest_required_version }}</span
        >)
      </template>
    </TlStandardTable>
  </div>
</template>

<script
  lang="ts"
  setup
>
import { computed, reactive, toRef, watch } from "vue"
import { useTitle } from "@/services/title"
import { usePackageStore } from "@/store/pinia/packages"
import type { AllPlatforms } from "@/types/package"
import type { IOTableColumn } from "@/dragonforce/elements/TlStandardTable.vue"
import packageLinkHandler from "@/mixins/PackageLinkMixin"
import { useRouterQuerySync } from "@/mixins/useQuerySync"

const columns: IOTableColumn[] = [
  { field: "name", label: "Name", sortable: true },
  { field: "scope", label: "Type", sortable: true },
  { field: "requirement", label: "Version", sortable: true },
  { field: "license", label: "License", sortable: true },
  { field: "vulnerability_count", label: "Vulnerabilities in range", sortable: true, position: "right" },
  { field: "dependency_count", label: "Dependencies", sortable: true, position: "right" },
]

const props = defineProps<{
  name: string
  platform: AllPlatforms
}>()

useTitle(computed(() => `${props.name} - Packages`))

const queryFilters = reactive({
  version: null as string | null,
  search: "",
})
const { hydrate: routerHydrate } = useRouterQuerySync(queryFilters)
Object.assign(queryFilters, routerHydrate())

const { packageLink, dependenciesLink, mode } = packageLinkHandler()
const pkgStore = usePackageStore()
const loadedVersion = computed(() => pkgStore.allPackageState(props)?.dependencies?.version || null)
const dependencies = computed(() => pkgStore.allPackageState(props)?.dependencies?.dependencies || [])
const versionOptions = computed(() => pkgStore.packageVersionNumbers || [])
const dependenciesNotSynced = computed(() => pkgStore.dependenciesNotSynced)

function loadDependencies() {
  pkgStore.loadPackageDependencies({ platform: props.platform, name: props.name }, queryFilters.version)
  pkgStore.loadPackageVersionNumbers({ platform: props.platform, name: props.name })
}

/** Hydrate from URL on page change */
watch(
  [toRef(props, "name"), toRef(props, "platform")],
  () => {
    Object.assign(queryFilters, routerHydrate())
    loadDependencies()
  },
  { immediate: true }
)

watch(toRef(queryFilters, "version"), newVersion => {
  /** Don't refetch if everything is the same */
  if (newVersion === loadedVersion.value) return
  else loadDependencies()
})

watch(
  loadedVersion,
  newVal => {
    if (newVal) {
      queryFilters.version = newVal
    }
  },
  { immediate: true }
)
</script>

<style
  scoped
  lang="scss"
>
.mono {
  font-family: Courier, monospace;
}
</style>
