<template>
  <OTooltip v-bind="$attrs">
    <div tabindex="0">
      <slot />
    </div>
  </OTooltip>
</template>

<script>
export default {
  name: "TlTooltip",
  status: "ready",
}
</script>
