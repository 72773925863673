<template>
  <slot
    v-if="policyCheck(scope, policy)"
    name="default"
  />
</template>

<script
  setup
  lang="ts"
>
import { usePolicyStore, PolicyType } from "@/store/pinia/policy"
defineProps<{
  policy: PolicyType
  scope: "catalog" | "team"
}>()
const policyCheck = usePolicyStore().check
</script>
