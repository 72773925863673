import { parseTemplate } from "url-template"
import qs from "qs"
import axios, { AxiosRequestConfig } from "axios"

export interface RequiredData<D> extends AxiosRequestConfig {
  data: D
}

export interface RequiredParams<P> extends AxiosRequestConfig {
  params: P
}

export interface RequiredParamsAndData<P, D> extends AxiosRequestConfig {
  params: P
  data: D
}

export function t(template: string, params: any) {
  return parseTemplate(template).expand(params)
}

export const client = axios.create({
  xsrfCookieName: "CSRF-TOKEN",
  xsrfHeaderName: "X-CSRF-Token",
  withCredentials: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    // @ts-expect-error GITVERSION comes from vite-plugin-git-revision
    "X-Tidelift-Frontend-Version": GITVERSION,
  },
  baseURL: "/api/depci",
  paramsSerializer: params =>
    qs.stringify(params, {
      encodeValuesOnly: true,
      arrayFormat: "brackets", // use Rails-style brackets w/no indices for arrays, in querystring
      indices: false,
    }),
})
