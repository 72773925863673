const urls = {
  supportTicketBase: "https://support.tidelift.com/hc/en-us/requests/new",
  createSupportTicketSubscriber: "https://support.tidelift.com/hc/en-us/requests/new?ticket_form_id=4527941850004",
  createSupportTicketLifter: "https://support.tidelift.com/hc/en-us/requests/new?ticket_form_id=4998413117716",
  policyDocs: "https://support.tidelift.com/hc/en-us/articles/4406293775764-tidelift-yml-reference",
  subscriberDocs:
    "https://support.tidelift.com/hc/en-us/articles/23931963391892-Getting-started-with-Tidelift#getting-started-with-tidelift-0-0",
  lifterDocs: "https://support.tidelift.com/hc/en-us/categories/6371339694740-For-maintainers",
  lifterAdvantageDocs:
    "https://support.tidelift.com/hc/en-us/articles/18801100874260-The-raw-data-powering-Tidelift-s-validated-open-source-intelligence#h_01H968W520Y2KR5QPBW8N81VES",
  howWePayLiftersDocs: "https://support.tidelift.com/hc/en-us/articles/4406294816916-How-we-pay-lifters",
  subscriberApi: "https://api.tidelift.com/docs",
  supportedManifests:
    "https://support.tidelift.com/hc/en-us/articles/4406293161492-Compatible-languages-and-package-files",
  bitbucketIntegration: "https://support.tidelift.com/hc/en-us/articles/4407353765652",
  installCLI: "https://support.tidelift.com/hc/en-us/articles/4406293608596-Installing-the-Tidelift-CLI",
  cliReference: "https://support.tidelift.com/hc/en-us/articles/4406293613588-Tidelift-CLI-reference",
  apiDocumentation:
    "https://support.tidelift.com/hc/en-us/articles/4406286623380-API-authentication-and-keys#api-authentication-and-keys-0-0",
  generatingLockfiles:
    "https://support.tidelift.com/hc/en-us/articles/4406286692756-How-to-generate-lockfiles-from-manifests",
  licenseProblems: "https://support.tidelift.com/hc/en-us/articles/4406287851924-Debugging-license-problems",
  spdxLicenseList: "https://spdx.github.io/spdx-spec/v2.3/SPDX-license-list/",
  spdxLicenseExpressionsLink: "https://spdx.github.io/spdx-spec/v2.3/SPDX-license-expressions/",
  statusPage: "https://status.tidelift.com",
  tideliftRecommendationDocs:
    "https://support.tidelift.com/hc/en-us/articles/23366075747860-How-Tidelift-evaluates-packages",
  allProjectsComplianceReportDocs:
    "https://support.tidelift.com/hc/en-us/articles/24885171849236-All-projects-compliance-report",
  allProjectsViolationsReportDocs:
    "https://support.tidelift.com/hc/en-us/articles/24883174701332-All-projects-violations-report",
  tideliftRecommendationsReportDocs: "https://support.tidelift.com/hc/en-us/articles/24971666742932",
  catalogActivityAuditReportDocs:
    "https://support.tidelift.com/hc/en-us/articles/26479161797780-Catalog-activity-audit-report",
  reportingDocs:
    "https://support.tidelift.com/hc/en-us/articles/4406286385428-Reporting-with-the-Tidelift-Subscription",
  updatingTransitiveDepsDocs:
    "https://support.tidelift.com/hc/en-us/articles/26315406262292-Updating-transitive-dependencies",
  knownPackagesDocs: "https://support.tidelift.com/hc/en-us/articles/4406286164500-Known-Packages-standard",
  removedReleaseDocs: "https://support.tidelift.com/hc/en-us/articles/20708211664916-Removed-releases-standard",
  metricsDocs:
    "https://support.tidelift.com/hc/en-us/articles/23722928721044-How-to-reduce-open-source-risk-with-Tidelift-s-web-UI-and-CLI#docs-internal-guid-fbb60f75-7fff-d5f5-d499-923a8a443687",
  developerActionsDocs:
    "https://support.tidelift.com/hc/en-us/articles/25809061226644-Developer-actions-beta#developer-actions-beta--0-0",
  tideliftMaintainerAdvantage: "https://tidelift.com/the-tidelift-maintainer-advantage",
  maintainersCriticalforSecurity:
    "https://support.tidelift.com/hc/en-us/articles/4406286733588-Maintainers-critical-for-software-supply-chain-security#maintainers-critical-for-software-supply-chain-security",
  maintainerTasksOverview: "https://support.tidelift.com/hc/en-us/articles/4406288074260-Lifter-tasks-overview",
  maintainerVulnerabilityRecommendations:
    "https://support.tidelift.com/hc/en-us/articles/23594803916436-Vulnerabilities#h_01HP7AZ3XE9QXMRS33CD97QN8P",
  createFixedRelease: "https://support.tidelift.com/hc/en-us/articles/14120444436116-Create-a-fixed-release",
  browserExtensionDocs: "https://support.tidelift.com/hc/en-us/articles/29159367904148-Tidelift-Browser-Extension",
  vsCodeExtensionDocs: "https://support.tidelift.com/hc/en-us/articles/28732644430740-Tidelift-for-VS-Code",
  cisaKnownExploits: "https://www.cisa.gov/known-exploited-vulnerabilities-catalog",
  epss: "https://www.first.org/epss/",
}

export function launchSupportLifterFeedback(params: { email: string; subject: string; description: string }) {
  const queryParams = new URLSearchParams({
    ticket_form_id: "4998413117716",
    tf_anonymous_requester_email: params.email,
    tf_subject: params.subject,
    tf_description: params.description,
    tf_4998538668820: "feedback",
  })
  // TODO add metrics to track this as a custom event (why, we are already getting email?)
  window.open(`${urls.supportTicketBase}?${queryParams.toString()}`, "_blank")
}

export default urls
