import _ from "lodash"

import { computed, ref, Ref, toRef } from "vue"

import { Organization } from "@/types/organization"
import { useRoute } from "vue-router"
import { useTeamStore } from "@/store/pinia/teams"
import { useAuthStore } from "@/store/pinia/auth"
import { storeToRefs } from "pinia"
import { useCatalogsStore } from "@/store/pinia/catalogs"

const teamsHandler = () => {
  const failedFetchingOrgsOwners: Ref<null | string> = ref(null)
  const { isAdmin } = storeToRefs(useAuthStore())
  const route = useRoute()
  const { catalog } = storeToRefs(useCatalogsStore())
  const teamStore = useTeamStore()
  const teams = computed(() => {
    if (teamStore.teams) {
      const equalTypeAndName = (orgA: Organization, orgB: Organization) =>
        orgA.name === orgB.name && orgA.type === orgB.type
      return _.uniqWith(teamStore.teams, equalTypeAndName)
    }
    return null
  })

  // TODO: get this returning null if no organization is found
  const currentOrgForOrganizationAndRepoType = ({
    organization,
    repoType,
  }: {
    organization: string
    repoType: string
  }): Organization | undefined => {
    const foundOrganization: string = (route.params.organization as string) || organization
    const foundRepoType: string = (route.params.repoType as string) || repoType
    return teams.value?.find(org => org.name === foundOrganization && org.org_type === foundRepoType)
  }

  const isTeamAdministratorForOrg = (organization: Organization | null | undefined = null) => {
    if (!organization) return false
    if ("administrator" in organization) {
      return Boolean(organization.administrator)
    }
    return false
  }

  const fetchTeamsAndCatchError = async (invalidate = false) => {
    failedFetchingOrgsOwners.value = null

    try {
      await teamStore.fetchTeams(invalidate)
    } catch (e: any) {
      if (e.message) {
        failedFetchingOrgsOwners.value = e.message
      }
      throw e
    }
  }

  const currentOrg = computed(() =>
    currentOrgForOrganizationAndRepoType({
      organization: catalog.value?.org_name ?? "",
      repoType: catalog.value?.org_type ?? "",
    })
  )

  const isTeamAdministrator = computed(() => isTeamAdministratorForOrg(currentOrg.value))

  return {
    teams,
    loadingTeams: toRef(teamStore, "loadingTeams"),
    isAdmin,
    failedFetchingOrgsOwners,
    currentOrg,
    isTeamAdministrator,

    currentOrgForOrganizationAndRepoType,
    isTeamAdministratorForOrg,

    fetchTeams: teamStore.fetchTeams,
    fetchTeamsAndCatchError,
  }
}

export default teamsHandler
