<template>
  <OCollapse v-model:open="open">
    <template #trigger>
      <button class="collapse-toggle">
        <i
          v-if="position === 'left'"
          class="mr-3 fa fa-chevron-right"
          :class="{ open }"
        />
        <span><slot name="title" />{{ title }}</span>
        <i
          v-if="position === 'right'"
          class="ml-3 fa fa-chevron-right"
          :class="{ open }"
        />
      </button>
    </template>
    <div :class="contentClass"><slot /></div>
  </OCollapse>
</template>

<script
  setup
  lang="ts"
>
import { ref } from "vue"

const props = withDefaults(
  defineProps<{
    title?: string
    initiallyOpen?: boolean
    position?: "right" | "left"
    contentClass?: string
  }>(),
  {
    position: "right",
    contentClass: "mt-2",
  }
)

const open = ref(props.initiallyOpen)
</script>

<style
  scoped
  lang="scss"
>
.collapse-toggle {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  font-size: $font-size-base;
  font-weight: $font-weight-semi-bold;
  @include themify {
    color: themed("color-heading-primary");
  }
}

.fa {
  transition: transform $duration-quickly;

  &.open {
    transform: rotate(90deg);
  }
}
</style>
