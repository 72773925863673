<template>
  <span class="is-flex is-flex-direction-column">
    <template v-if="label">
      <label
        class="has-themed-text pb-2"
        :for="id"
        >{{ label }}</label
      >
    </template>
    <OField
      v-for="option in options"
      :key="option.value"
    >
      <TlRadio
        v-bind="$attrs"
        :id="id"
        :name="name"
        :nativeValue="option.value"
        :size="size"
        :value="value"
        @input="$emit('input', $event)"
      >
        {{ option.name }}
      </TlRadio>
    </OField>
  </span>
</template>

<script>
import TlRadio from "./TlRadio.vue"

export default {
  inheritAttrs: false,
  components: { TlRadio },
  props: {
    // Capture the "v-model" value.
    // TODO: rename this to `modelValue` when upgrading to Vue3, as the syntax has changed
    value: { required: false },
    /**
     * an option should have name and value, [{ name: string, value: string }, ...]
     */
    options: { type: Array, required: true },
    name: { type: String, required: true },
    label: { type: String, required: false },
    id: { type: String, required: false },
    /**
     * Oruga Radio button sizings, `small`, `medium`, etc.
     */
    size: { type: String, default: "small" },
  },
  data() {
    return {
      selection: "",
    }
  },
}
</script>
