import config from "@/config"
import { UserSettings } from "@/types/authentication"
import { defineStore } from "pinia"

interface State {
  userFeatures: Record<string, true>
  organizationFeatures: Record<string, Record<string, true>>
}

type UserFlags = Pick<UserSettings, "global_feature_flags" | "user_feature_flags" | "organization_feature_flags">

function configureFlags(user: UserFlags | null) {
  if (user === null) return null

  const globalFlags = user.global_feature_flags || []
  const userFlags = user.user_feature_flags || []
  const orgFlags = user.organization_feature_flags || {}

  const userFeatures: Record<string, true> = {}
  const organizationFeatures: Record<string, Record<string, true>> = {}

  globalFlags.concat(userFlags).forEach(f => (userFeatures[f] = true))
  Object.values(orgFlags).forEach(({ flags, team }) => {
    organizationFeatures[team] = {}
    flags.forEach(f => {
      organizationFeatures[team][f] = true
    })
  })

  if (import.meta.env.DEV) {
    console.warn("Force-enabled features ", config.forceEnableFeatures)
    console.warn("Force-disabled features ", config.forceDisableFeatures)

    config.forceEnableFeatures.forEach(f => {
      userFeatures[f] = true
    })
    config.forceDisableFeatures.forEach(f => {
      delete userFeatures[f]
      Object.keys(organizationFeatures).forEach(org_id => {
        delete organizationFeatures[org_id][f]
      })
    })
  }

  return {
    userFeatures,
    organizationFeatures,
  }
}

export const useFeatureStore = defineStore("features", {
  persist: {
    key: "tl/feature-flag-cache",
  },
  state: (): State => ({
    userFeatures: {},
    organizationFeatures: {},
  }),
  actions: {
    setFeatures(user: UserFlags | null) {
      const configured = configureFlags(user)
      if (configured !== null) {
        this.userFeatures = configured.userFeatures
        this.organizationFeatures = configured.organizationFeatures
      } else {
        this.userFeatures = {}
        this.organizationFeatures = {}
      }
    },
  },
  getters: {
    isFeatureEnabled(state) {
      return (...featureNames: string[]) => {
        const { organization } = this.$router.currentRoute.value.params
        return this.isFeatureEnabledForTeam(organization, ...featureNames)
      }
    },
    isFeatureEnabledForTeam:
      state =>
      (organization_name: string, ...featureNames: string[]) => {
        const orgFeatures = state.organizationFeatures[organization_name] || {}
        return featureNames.every(featureName => {
          return featureName in state.userFeatures || featureName in orgFeatures
        })
      },
  },
})
