import type { Catalog } from "./catalog"
import type { PaginationParams } from "./general"
import { AllPlatforms, PackageFragment } from "./package"
import type { IncomeStream, LiftablePlatform } from "./package"
import { UpToDateStrategy } from "./rules"

export type PolicyStatus = "denied" | "approved"

export enum TASK_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  DENIED = "denied",
  REQUESTED = "requested",
  COMPLETE = "complete",
}

export enum CATALOG_STANDARD {
  ACTIVE_RELEASE_STREAM = "active_release_stream",
  ALLOWED_LICENSES = "allowed_licenses",
  BLOCKED_LIST = "blocked_list",
  DEPRECATION = "deprecation",
  EOL_PACKAGES = "eol_packages",
  GITHUB_TWO_FACTOR_AUTH = "github_two_factor_auth",
  HISTORICAL_SECURITY_REVIEW = "historical_security_review",
  IDENTIFIED_LICENSES = "identified_licenses",
  KNOWN_PACKAGES = "known_packages",
  KNOWN_RELEASES = "known_releases",
  OPENSSF_BRANCH_PROTECTION = "openssf_branch_protection",
  OPENSSF_SECURITY_POLICY = "openssf_security_policy",
  PACKAGE_IS_RECOMMENDED_REPLACEMENT = "package_is_recommended_replacement",
  PACKAGE_MANAGER_TWO_FACTOR_AUTH = "package_manager_two_factor_auth",
  PACKAGES_APPEAR_MAINTAINED = "packages_appear_maintained",
  PACKAGES_HAVE_CLEAN_RELEASES = "packages_have_clean_releases",
  PACKAGES_HAVE_CODES_OF_CONDUCT = "packages_have_codes_of_conduct",
  PACKAGES_HAVE_CONTRIBUTING_GUIDELINES = "packages_have_contributing_guidelines",
  PACKAGES_HAVE_INCOME_STREAMS = "packages_have_income_streams",
  PACKAGES_HAVE_LIFECYCLE_EVIDENCE = "packages_have_lifecycle_evidence",
  PACKAGES_HAVE_LIFECYCLE_POLICIES = "packages_have_lifecycle_policies",
  PACKAGES_HAVE_MAINTENANCE_PLANS = "packages_have_maintenance_plans",
  PACKAGES_HAVE_REPOSITORIES = "packages_have_repositories",
  PACKAGES_HAVE_SECURITY_POLICIES = "packages_have_security_policies",
  PACKAGES_HAVE_VERIFIED_LICENSES = "packages_have_verified_licenses",
  PACKAGES_HAVE_VERSIONING_SCHEMES = "packages_have_versioning_schemes",
  PRERELEASES = "prereleases",
  RELEASE_MANAGERS_REVIEWED = "release_managers_reviewed",
  RELEASES_HAVE_CLEAN_DEPENDENCIES = "releases_have_clean_dependencies",
  REMOVED_RELEASES = "removed_releases",
  UP_TO_DATE = "up_to_date",
  VULNERABILITIES = "vulnerabilities",
  VULNERABILITY_RECOMMENDATIONS = "vulnerability_recommendations",
}

export interface AffectedProjectAndVersion {
  branch_count: number
  branches: string[]
  repository: string
  version: string
  is_direct: boolean
  platform: string
  package_name: string
}

export interface RequestedRelease {
  created_at: string
  repository_name: null
  requester_email: null
}

export interface CatalogTaskQueryParams extends PaginationParams {
  repos: string[]
  groups: string[]
  platforms: string[]
  catalog_standards: string[]
  sort: "ASC" | "DESC"
  sort_by: "created_at" | "tag"
  query: string
  dependency_source: string
  dependency_scope: string
  is_lifted: string
  is_affecting_subscribers: string
}

export interface ReleaseBase {
  name: string
  version: string
  platform: AllPlatforms
}

export interface Release extends ReleaseBase {
  other_approved_versions: string[]
  release_statuses: TASK_STATUS[]
  requested_catalog_releases: RequestedRelease[]
  status: TASK_STATUS
  tasks: Task[]
  type: string
  replacement_packages?: {
    versions: string[]
    name: string
    id: string
  }[]
}

export interface AffectedProjectAndVersion {
  repository: string
  version: string
  is_direct: boolean
}

export interface AffectedRequests {
  platform: string
  package_name: string
  repository: {
    name: string | null
    type: string | null
  }
  requested_at: string
  requester_email: string | null
  version: string
}

export interface ViolationGithubTwoFactorAuth {
  github_refresh_date: string
  users: {
    name: string
    github_two_factor_auth: boolean
  }[]
}

export interface ViolationPackageManagerTwoFactorAuth {
  current_user_name: string
  current_user_package_manager_two_factor_auth: boolean
  users: {
    name: string
    package_manager_two_factor_auth: boolean
  }[]
}

export type ReleaseManager = {
  username: string
  is_approved: boolean
  refreshed_at: string
}
export interface ViolationReleaseManagersReviewed {
  release_managers: ReleaseManager[]
}
export interface ViolationVulnerabilityRecommendations {
  package_name: string
  package_platform: string
  vulnerability: ViolationVulnerability
  latest_affected_version: string
  all_versions: string[]
  affected_versions: string[]
  updated_by: string
  updated_at: string
}

export interface ViolationVulnerability {
  id: string
  severity: string
  description: string
  date: string
  url: string
  severity_rating: string
  lifter_recommendations: LifterRecommendation[]
}

export interface ViolationPackageIsRecommendedReplacement {
  no_recommended_replacement_packages: boolean
  recommended_replacement_packages: {
    platform: string
    name: string
    purl: string
    explanation: string
  }[]
}

export interface ViolationPackagesHaveSecurityPolicies {
  chosen_plan: string | null
  email_address: string | null
  last_verified_at: string
  security_policy_url: string | null
  repository_url: string | null
  status: string
}

export interface ViolationPackagesHaveMaintenancePlans {
  package_name: string
  package_platform: string
  versioning_scheme: string
  has_assessed_versioning_scheme: boolean
  plan_type: "recent" | "custom"
  major_version_count: number
  minor_version_count: number
  external_url: string
  notes: string
}

export interface ViolationOpenSsfBranchProtection {
  score: string
  refreshed_at: string
  feedback_exists: boolean
}

interface ViolationPackagesHaveVerifiedLicenses_Expression {
  expression: string | null // Might be null if the license is not recognized
  display_name: string
}

export interface ViolationPackagesHaveRepositories {
  package_name: string
  package_platform: string
  repository_url: string | null
  no_repository: boolean | null
}

export interface ViolationPackagesHaveCleanReleases {
  package_name: string
  package_platform: string
  version: string | null

  violations: NestedViolation[] | null
}

export interface ViolationPackagesHaveCodesOfConduct {
  package_name: string
  package_platform: string
  code_of_conduct_url: string | null
  no_code_of_conduct: boolean | null
}

export interface ViolationPackagesHaveContributingGuidelines {
  package_name: string
  package_platform: string
  contributing_guidelines_url: string | null
  no_contributing_guidelines: boolean | null
}

export interface ViolationPackagesHaveLifecycleEvidence {
  package_name: string
  package_platform: string
  lifecycle_evidence_url: string | null
  no_lifecycle_evidence: boolean | null
}

export interface ViolationPackagesHaveLifecyclePolicies {
  package_name: string
  package_platform: string
  lifecycle_policy_url: string | null
  no_lifecycle_policy: boolean | null
}

export interface ViolationReleasesHaveCleanDependencies {
  package_name: string
  package_platform: string
  version: string | null

  violating_dependencies: {
    platform: string
    name: string
    version: string | null

    violations: NestedViolation[]
    is_direct: boolean

    optional: boolean
    requirement: string
    scope: string

    chain: DependencyChainItemRaw[]
  }[]
}
export type DependencyChainItemRaw = `${string}@${string}`

export interface ViolationUpToDate {
  package_name: string
  package_platform: string
  version: string | null
}

export interface ViolationDeprecation {
  package_name: string
  package_platform: string

  deprecation: {
    deprecated_at: string
    reason: string
    package_to_use_instead: {
      platform: string
      name: string
    }
  }
}

export interface ViolationEOLPackages {
  package_name: string
  package_platform: string
  title: string
  description: string

  eol_package: {
    eol_reference_found: boolean
    reason: string
    effective_on: string
    reference_url: string
    package_renamed_to: Array<PackageFragment>
    no_planned_maintenance: boolean
  }
}

/**
 * TODO this interface is wrong, changed in https://github.com/tidelift/dependencyci/pull/8409/files#diff-561f3d6476b7afbc886778a2b92d30b3be2f2fc2c52d43ce904b944f81e62aac
 * Need to update it
 */
export interface ViolationVulnerabilities {
  package_name: string
  package_platform: string

  vuln_id: string
  severity: string
  vuln_description: string
  vuln_date: string
  vuln_url: string
  vuln_base_score: string
  vuln_github_score: string
  vuln_severity_rating: string
}

export interface NestedViolationBase {
  title: string | null
  catalog_standard: string
  violation_id: string
  package_name: string
  package_platform: string
}

export type NestedViolation = NestedViolationBase &
  (
    | ViolationPackagesHaveCleanReleases
    | ViolationReleasesHaveCleanDependencies
    | ViolationVulnerabilities
    | ViolationDeprecation
    | ViolationUpToDate
  )

export interface ViolationPackagesHaveVerifiedLicenses {
  package_name: string
  package_platform: string
  package_manager_license: ViolationPackagesHaveVerifiedLicenses_Expression | null
  repository_license: ViolationPackagesHaveVerifiedLicenses_Expression | null
  researched_license: ViolationPackagesHaveVerifiedLicenses_Expression | null
}

export type VersioningSchemeCode = "semver" | "pep440" | "calver" | "other"

export interface ViolationPackagesHaveVersioningSchemes {
  versioning_scheme: VersioningSchemeCode | null
  versioning_scheme_url: string | null
}

export interface ViolationPackagesAppearMaintained {
  maintained: boolean | null
  officially_deprecated: boolean | null
  officially_deprecated_note: string | null
  replacement_packages: string[]
}

interface ViolationPackagesHaveIncomeStreams {
  income_streams: IncomeStream[]
}

export interface LifterRecommendation {
  created_at: string
  false_positive_reason: string | null
  id: string
  impact_description: string | null
  impact_score: number | null
  includes_dev: boolean
  other_conditions: boolean
  other_conditions_description: string | null
  real_issue: boolean
  specific_methods_affected: boolean
  specific_methods_description: string | null
  updated_at: string
  workaround_available: boolean
  workaround_description: string | null
  visibility: "external" | "pending"
}
export interface Task {
  /**
   * Required fields
   */
  id: string
  status: TASK_STATUS
  catalog_standard: CATALOG_STANDARD
  affected_projects_and_versions: AffectedProjectAndVersion[]
  affected_projects_and_versions_total_count: number
  affected_requests: AffectedRequests[]
  created_at: string
  known_package: boolean
  violation_id: string
  policy_status: PolicyStatus
  /**
   * Optional fields
   */

  name: string
  version: string
  platform: AllPlatforms
  other_approved_versions: string[]
  requested_catalog_releases: RequestedRelease[]
  replacement_packages?: {
    versions: string[]
    name: string
    id: string
  }[]

  affected_versions?: string[]
  strategy?: UpToDateStrategy
  grace_period?: number
  issue_type?: string
  license?: {
    name: string
    status: string
    reference_url: string
  }
  original_license?: string
  repository_url?: string
  releases: {
    platform: string
    name: string
    fullName: string
    version: string
    status: string
  }[]
  package_to_use_instead?: {
    platform: string
    name: string
  }
  selected_catalogs?: Catalog[]
  package_details?: {
    latest_release_number: string
    latest_release_published_at: string
    current_release_published_at: string
    latest_stable_release_number: string
  }
  security_research_recommendation?: string
  recommendation?: string
  lifter_recommendation?: LifterRecommendation
  reviewer_notes?: string
  deny_at?: string | null
  /** Extra properties for CVEs */
  vuln_id?: string
  base_score?: number
  github_score?: number
  github_security_advisory_id?: string
  epss_probability?: string // decimal number as string
  known_exploited?: boolean
  severity_rating?: string
  /** End extra properties for CVEs */
  reason?: string
  deprecated_at?: string
  date?: string
  description?: string
  url?: string
  release_count?: number
  violation_packages_have_income_streams?: ViolationPackagesHaveIncomeStreams
  violation_github_two_factor_auth?: ViolationGithubTwoFactorAuth
  violation_package_manager_two_factor_auth?: ViolationPackageManagerTwoFactorAuth
  violation_release_managers_reviewed?: ViolationReleaseManagersReviewed
  violation_packages_have_clean_releases?: ViolationPackagesHaveCleanReleases
  violation_packages_have_security_policies?: ViolationPackagesHaveSecurityPolicies
  violation_vulnerability_recommendations?: ViolationVulnerabilityRecommendations
  violation_eol_packages?: ViolationEOLPackages
  unaffected_release_versions?: string
}

export interface Violation {
  violation_id: string
  catalog_standard: CATALOG_STANDARD
  title: string
  has_task: boolean // indicates whether there's an outstanding violation task for this standard.
  violation_packages_have_income_streams?: ViolationPackagesHaveIncomeStreams
  violation_github_two_factor_auth?: ViolationGithubTwoFactorAuth
  violation_package_manager_two_factor_auth?: ViolationPackageManagerTwoFactorAuth
  violation_release_managers_reviewed?: ViolationReleaseManagersReviewed
  violation_vulnerability_recommendations?: ViolationVulnerabilityRecommendations
  violation_packages_have_clean_releases?: ViolationPackagesHaveCleanReleases
  violation_packages_have_codes_of_conduct?: ViolationPackagesHaveCodesOfConduct
  violation_packages_have_contributing_guidelines?: ViolationPackagesHaveContributingGuidelines
  violation_packages_have_lifecycle_evidence?: ViolationPackagesHaveLifecycleEvidence
  violation_packages_have_lifecycle_policies?: ViolationPackagesHaveLifecyclePolicies
  violation_packages_have_repositories?: ViolationPackagesHaveRepositories
  violation_packages_have_security_policies?: ViolationPackagesHaveSecurityPolicies
  violation_packages_have_verified_licenses?: ViolationPackagesHaveVerifiedLicenses
  violation_openssf_branch_protection?: ViolationOpenSsfBranchProtection
  violation_packages_have_maintenance_plans?: ViolationPackagesHaveMaintenancePlans
  packages_have_versioning_schemes?: ViolationPackagesHaveVersioningSchemes
  violation_packages_appear_maintained?: ViolationPackagesAppearMaintained
  violation_package_is_recommended_replacement?: ViolationPackageIsRecommendedReplacement
}

export interface ViolationDetails extends Violation {
  task_ids: string[]
  affected_releases: ReleaseBase[]
}

/**  */
export interface OverrideOperation {
  type: "create_override"
  violation_id: string
  override_status: "approved" | "denied"
  package_platform?: string
  package_name?: string
  version?: string
  note?: string
  deny_at?: string | null
}

export interface UpdateLicensePolicyOperation {
  type: "update_license_policy"
  license: string
  policy_status: "approved" | "denied"
  generate_tasks: boolean
  note?: string
}

export interface AssignLicenseOperation {
  type: "assign_license"
  license: string
  package_platform?: string
  package_name?: string
  version?: string
  note?: string
}

export type ResolveViolationOperation = OverrideOperation | UpdateLicensePolicyOperation | AssignLicenseOperation

/**
 * Response from lifter_catalog_tasks/package_counts_by_standard
 */
export interface TaskCountResponse {
  catalog_standard: string
  package_count: number
  task_name: string
}

export interface LifterTaskSummary {
  platform: LiftablePlatform
  name: string
  created_at: string
  violation_id: string
  catalog_standard_slug: CATALOG_STANDARD
  has_task: boolean
}
