<template>
  <div class="feed-item">
    <i
      class="feed-item-icon fas"
      :class="icon"
    />
    <div class="feed-item-content-wrapper">
      <Heading
        :level="3"
        class="feed-item-title"
      >
        {{ title }} <span class="feed-item-timestamp">• {{ time }}</span>
      </Heading>
      <slot />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import Heading from "./Heading.vue"

export default {
  name: "Feed",
  status: "ready",
  components: { Heading },
  props: {
    title: { type: String, required: true },
    timestamp: { type: String, required: true },
    icon: { type: String, required: true },
  },
  computed: {
    time() {
      return dayjs(this.timestamp).format("h:mm a")
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.feed-item {
  position: relative;
  margin-bottom: $space-x-large;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    display: block;
    margin-left: 15px;
    margin-top: $space-x-large;
    @include themify {
      border-right: 2px solid themed("color-border");
    }
  }

  &:last-of-type::before {
    display: none;
  }
}

.feed-item-content-wrapper {
  margin-left: 3rem;
}

.feed-item-title {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.75rem;
  margin-bottom: $space-x-small;
}

.feed-item-timestamp {
  text-transform: none;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
}

.feed-item-icon {
  position: absolute;
  margin-right: $space-base;
  padding: $space-x-small;
  border-radius: 2rem;
  color: $color-tidelift-blue-500;
  width: 2rem;
  height: 2rem;
  text-align: center;
  @include themify {
    background-color: themed("color-border");
  }
}
</style>
