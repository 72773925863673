<template>
  <section
    v-if="open"
    @keyup.esc="handleEscape"
    tabindex="0"
    class="fullscreen-background"
    :class="{ dark }"
  >
    <img
      v-if="showLogo"
      :src="logo"
      class="logo"
    />
    <Card class="fullscreen-modal">
      <div
        v-if="title"
        class="fullscreen-modal-head"
      >
        <p class="fullscreen-modal-title">{{ title }}</p>
        <button
          v-if="onClose"
          class="delete"
          aria-label="close"
          @click="onClose"
        ></button>
      </div>
      <slot />
      <div
        class="footer-info"
        v-if="$slots.footer"
      >
        <slot name="footer" />
      </div>
    </Card>
  </section>
</template>

<script>
import { defineComponent } from "vue"
import Card from "./Card.vue"

export default defineComponent({
  name: "FullscreenModal",
  status: "deprecated",
  components: { Card },
  props: {
    open: { type: Boolean },
    title: { type: String },
    onClose: { type: Function },
    dark: { type: Boolean, default: false },
    limitWidth: { type: Boolean, default: false },
    showLogo: { type: Boolean, default: false },
  },
  methods: {
    handleEscape() {
      if (this.onClose) {
        this.onClose()
      }
    },
  },
  data() {
    return {
      logo: "/src/dragonforce/assets/logos/Tidelift-logo-on-dark.svg",
    }
  },
})
</script>

<style
  lang="scss"
  scoped
>
.fullscreen-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 40;
  color: $color-white;
  width: 100vw;
  height: 100vh;
}

.fullscreen-background::before {
  content: "";
  @include themify {
    background-color: themed("color-modal-background");
  }

  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  transform: scaleX(-1);
  @include from($tablet) {
    background-image: url("~@/dragonforce/assets/patterns/Tidelift-cardpattern-darkblue.svg");
    background-size: 33%;
    background-position-x: 38%;
    background-position-y: 75%;
    background-repeat: no-repeat;
  }
}

.logo {
  width: 10rem;
  margin-bottom: $space-large;
}

.fullscreen-background.dark::before {
  @include themify {
    background-color: themed("color-modal-background-solid");
  }
}

.card.fullscreen-modal {
  overflow: auto;
  padding: 35px;
  max-height: 100vh;

  &.limit-width {
    width: 700px;
  }
  @include mobile {
    border-radius: 0;
  }
}

.fullscreen-modal-head {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  margin-bottom: 2.5rem;
}

.fullscreen-modal-title {
  font-size: $font-size-x-large;
  font-weight: $font-weight-light;
  cursor: default;
  flex-grow: 1;
  @include themify {
    color: themed("color-heading-primary");
  }
}

.footer-info {
  margin-top: $space-x-large;
  margin-right: -35px;
  margin-bottom: -35px;
  margin-left: -35px;
  height: 7.5rem;
  @include themify {
    background-color: themed("color-modal-footer");
  }
}
</style>
