<template>
  <nav
    v-if="breadcrumbs.length > 1"
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <template
        v-for="{ title, path } of breadcrumbs"
        :key="path"
      >
        <li>
          <RouterLink :to="{ path }">{{ title }}</RouterLink>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script
  setup
  lang="ts"
>
import { useBreadcrumbs } from "@/services/breadcrumbs"
const breadcrumbs = useBreadcrumbs()
</script>

<style
  lang="scss"
  scoped
>
.breadcrumb {
  overflow: auto;
  padding-left: 0;
  padding-bottom: $space-small;

  @include themify {
    border-bottom: 1px solid themed("color-hr");
  }

  li + li::before {
    content: "\f054";
    font-family: "Font Awesome 5 Free", sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
  }

  a {
    font-family: "IBM Plex Sans bold", sans-serif;
  }

  li:last-child {
    a {
      color: $color-tidelift-blue-700;
    }
  }

  ul,
  ol {
    align-items: center;
  }
}
</style>
