<template>
  <div>
    <span class="is-inline-block mr-4">{{ message }}</span>
    <button
      class="button is-secondary"
      @click="$emit('close')"
    >
      {{ actionText }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({
  props: {
    message: String,
    actionText: String,
  },
})
</script>
