<template>
  <SvgIcon
    :name="colorClass ? 'libraries-no-color' : 'libraries-color'"
    :size="size"
    :class="colorClass"
  />
</template>

<script>
import SvgIcon from "./SvgIcon.vue"

export default {
  name: "LibrariesIcon",
  status: "ready",
  components: {
    SvgIcon,
  },
  props: {
    /**
     * Makes the icon render anti-light.
     */
    dark: { type: Boolean },
    /**
     * Makes the icon render anti-dark.
     */
    light: { type: Boolean },
    /**
     * Makes the icon render white.
     */
    white: { type: Boolean },
    /**
     * The size to display the icon.
     */
    size: { default: "16px" },
  },
  computed: {
    colorClass() {
      if (this.dark) return "libraries-icon-dark"
      if (this.light) return "libraries-icon-light"
      if (this.white) return "libraries-icon-white"
      return null
    },
  },
}
</script>

<style lang="scss">
.libraries-icon-white svg {
  @include themify {
    fill: white !important;
  }
}

.libraries-icon-light svg {
  @include themify {
    fill: themed("color-text-inverted") !important;
  }
}

.libraries-icon-dark svg {
  @include themify {
    fill: themed("color-text-primary") !important;
  }
}
</style>
