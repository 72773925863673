import { ShallowRef, shallowRef } from "vue"
import axios, { AxiosError } from "axios"
import { defineStore } from "pinia"
import Cookies from "js-cookie"

import config from "@/config"
import type { Auth0Profile, UserSettings } from "@/types/authentication"
import api from "@/services/api"

import { useGlobalErrorStore } from "./globalErrors"
import { useFeatureStore } from "./features"

interface State {
  user: ShallowRef<null | UserSettings>
  auth0Profile: null | Auth0Profile
  loaded: boolean
}

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    user: shallowRef(null),
    auth0Profile: null,
    loaded: false,
  }),
  getters: {
    authenticated(state) {
      return state.user !== null
    },
    isLifter(state) {
      return state.user?.app_metadata?.is_lifter === "true" || state.user?.app_metadata?.is_lifter === true
    },
    isSubscriber(state) {
      return state.user?.app_metadata?.is_subscriber === "true" || state.user?.app_metadata?.is_subscriber === true
    },
    isAdmin(state) {
      return state.user?.app_metadata?.is_admin === "true" || state.user?.app_metadata?.is_admin === true
    },
    isInternal(state) {
      return state.user?.app_metadata?.is_internal === "true" || state.user?.app_metadata?.is_internal === true
    },
    email(state) {
      // Get user_email from app_metadata first
      let email = state.user?.app_metadata?.user_email
      if (email) {
        return email
      }

      // try falling back to user.email if they haven't completed the email prompt
      email = state.user?.email
      if (email) {
        return email
      }

      // try fall back to user.nickname generated unknownemail if that fails
      const nickname = state.user?.nickname
      if (nickname) {
        return `nickname-${nickname}@unknownemail.tidelift.com`
      }
      // give up and return null, because we can't find or make an email address for this user
      return null
    },
  },
  actions: {
    setUser(user: UserSettings | null) {
      this.user = user
      useFeatureStore().setFeatures(user)
    },
    setAuth0Profile(profile: Auth0Profile) {
      this.auth0Profile = profile
    },
    setLoaded(loaded: boolean) {
      this.loaded = loaded
    },
    logout() {
      this.setLoaded(false)
      this.setUser(null)
    },
    signUpByEmail(email: string, password: string) {
      return axios.put(config.auth.passwordPath + "/register", {
        email,
        password,
      })
    },
    async updateSettings() {
      try {
        const user = await api.fetchSettings()
        this.setUser(user.data)
        return user.data
      } catch (error) {
        // updateSettings is called for unauthenticated users sometimes.
        if ((error as AxiosError)?.response?.status === 401) {
          return null
        }
        useGlobalErrorStore().processCriticalRequestError(error)
      }
    },
    async handlePasswordLogInResponse(response: { data: Record<string, string> }) {
      if (import.meta.env.DEV) Cookies.set(config.auth.authCookieName, response.data[config.auth.authCookieName])
      return await this.updateSettings()
    },
  },
})
