import { defineStore, acceptHMRUpdate } from "pinia"

// commonly used dialog options
export const unsavedChangesDialog = {
  title: "Are you sure you want to leave?",
  message: "You have unsaved changes.",
  confirmText: "Continue",
  denyText: "Stay here",
  variant: "primary",
}

type State = {
  isDialogOpen: boolean
  title: string
  message: string
  variant: string
  confirmText: string
  denyText?: string
  resolve: null | ((val: unknown) => void)
}

export const useDialogStore = defineStore("dialog", {
  state: (): State => ({
    isDialogOpen: false,
    resolve: null,
    title: "",
    message: "",
    variant: "",
    confirmText: "",
    denyText: "",
  }),
  actions: {
    resolveDialog(resolution: boolean) {
      this.isDialogOpen = false
      if (!this.resolve) {
        console.error("attempting to resolve dialog before opening")
        return
      }
      this.resolve(resolution)
    },
    /**
     * Show a confirmation dialog.
     */
    async confirm(options: Omit<State, "isDialogOpen" | "resolve">) {
      this.isDialogOpen = true
      this.title = options.title
      this.message = options.message
      this.variant = options.variant
      this.confirmText = options.confirmText
      this.denyText = options.denyText || "Cancel"
      return new Promise(resolve => {
        this.resolve = resolve
      })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDialogStore, import.meta.hot))
}
