<template>
  <div
    @click="emitAction"
    id="container"
    class="small-padding"
    :class="{ 'clickable-cursor': useAction }"
  >
    <Card
      condensed
      :hover="hover"
      :elevated="elevated"
      :success="success"
    >
      <div class="is-flex is-align-items-center">
        <div :class="{ 'is-heavy-text': bold }">
          <span
            v-if="platform"
            class="is-light"
            >{{ platform }}/</span
          >{{ name }}
        </div>

        <div v-if="loading">
          <i class="fas fa-spinner fa-spin loading-spinner" />
        </div>
        <div v-else-if="success">
          <slot name="success" />
        </div>
        <div v-else>
          <slot name="action" />
        </div>
      </div>
    </Card>
  </div>
</template>

<script
  setup
  lang="ts"
>
import Card from "./Card.vue"

const props = withDefaults(
  defineProps<{
    name: string
    platform?: string
    useAction?: boolean
    // style modifiers
    bold?: boolean
    success?: boolean
    hover?: boolean
    elevated?: boolean
    loading?: boolean
  }>(),
  {
    useAction: true,
  }
)

const emit = defineEmits(["action"])

const emitAction = () => {
  if (!props.useAction) return

  emit("action", { name: props.name, platform: props.platform })
}
</script>

<style
  scoped
  lang="scss"
>
.is-flex {
  display: flex;
  justify-content: space-between;
}

.small-padding {
  padding-bottom: 18px;
}

.is-heavy-text {
  font-weight: $font-weight-semi-bold;
  @include themify {
    color: themed("color-heading-primary");
  }
}

.is-light {
  font-weight: $font-weight-regular;
}

.clickable-cursor {
  cursor: pointer;
}
</style>
