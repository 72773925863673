<template>
  <span class="textarea-container">
    <template v-if="label">
      <label
        class="textarea-label"
        :for="id"
        >{{ label }}</label
      >
    </template>
    <OField>
      <TlInput
        v-bind="$attrs"
        type="textarea"
        :id="id"
      />
    </OField>
  </span>
</template>

<script>
import TlInput from "./TlInput.vue"
/**
 * A text-area component for optionally labeled text areas.
 */
export default {
  name: "TextArea",
  status: "ready",
  inheritAttrs: false,
  components: { TlInput },
  props: {
    label: { type: String },
    id: { type: String },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.textarea-container {
  display: flex;
  flex-direction: column;
}

.textarea-label {
  padding-bottom: $space-x-small;
}
</style>
