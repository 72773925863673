<template>
  <OTabItem v-bind="$attrs">
    <template
      v-if="hasSlot('header')"
      #header
    >
      <slot name="header" />
    </template>
    <slot />
  </OTabItem>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "TlTabItem",

  setup(_, { slots }) {
    return {
      hasSlot: (name: string) => {
        return !!slots[name]
      },
    }
  },
})
</script>
